.languages__item {
  &:hover {
    cursor: pointer;
  }
}

.header__top-bar-inner,
.header__inner {
  justify-content: center;
}

.header__logo {
  margin-right: unset;
}
