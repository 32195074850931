.hero {
  .hero__bg-image {
    &:before {
      padding-top: 0;
    }
  }

  .display-mobile {
    display: none;
  }

  @media (min-width: 1440px) {
    .hero--tall .hero__head:before {
      padding-top: 25vw;
    }
  }

  @media (max-width: 767px) {
    .display-mobile {
      display: block;
    }

    .display-desktop {
      display: none;
    }
  }
  .hero__subtitle p {
    margin-bottom: 0.4rem;
  }
}
