.sub-form-container {
  //margin-top: 50px;
  padding-top: 40px;
  position: relative;
  text-align: center;

  .error {
    color: #d62d2d;
    margin-bottom: 5px;
    margin-left: 5px;
  }
  a {
    text-decoration: underline;
  }

  .success-tick {
    margin-bottom: 10px;
  }

  .mb-5 {
    margin-bottom: 5px !important;
  }

  .success-description {
    display: flex;
    justify-content: center;

    p {
      max-width: 660px;
    }
  }

  .cross-icon-img {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 20px;
    top: 21px;
    z-index: 2;
    fill: #f58220;

    &:hover {
      cursor: pointer;
      fill: #ffa427;
      transition: 0.4s;
      -webkit-transition: 0.4s;
      -moz-transition: 0.4s;

      transform: rotate(-90deg);
    }
  }

  .mt-10p {
    margin-top: 10px;
  }

  .subtitle {
    margin-bottom: 15px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .check__label {
    display: flex;
  }

  .check--large {
    .check__indicator {
      width: 25px;
      height: 25px;
    }
    .check__text {
      margin-left: 10px;
      font-weight: 500;
    }
  }

  .ml-38p {
    margin-left: 38px;
  }

  .tell-link {
    font-weight: 600;
  }

  .btm-text-container {
    .form-text {
      color: #888;
      font-size: 13px;
      line-height: 18px;
      width: 100%;
    }
  }

  .link-style-button {
    text-transform: unset;
    letter-spacing: unset;
  }

  .tooltip-parent {
    position: relative;

    &:disabled {
      &:hover {
        .tooltiptext {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .tooltiptext {
      visibility: hidden;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 18px;
      text-transform: initial;

      background-color: #fff;
      color: #000;
      text-align: center;
      border-radius: 3px;
      padding: 12px;
      position: absolute;
      z-index: 1;
      bottom: 125%;
      left: 15%;
      // margin-left: -60px;
      opacity: 0;
      transition: opacity 0.3s;
      border-radius: 3px;

      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #fff transparent transparent transparent;
      }
    }
  }

  .form-submit-btn {
    display: inline-block;
    margin-bottom: 20px;

    &:disabled {
      background: #f58220;
      opacity: 0.8;

      &:hover {
        .tooltiptext {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

#iframe-payment-container,
iframe {
  min-width: 450px;
  min-height: 670px;
}

.styles_modal__gNwvD {
  border-radius: 3px;
}

.cross-icon-img,
.styles_closeIcon__1QwbI {
  cursor: pointer;
  fill: #f58220;
}
