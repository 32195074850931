.no-border,
.no-border:before {
  border: none !important;
}

.textLeft {
  text-align: left;
}

.plan-section-container {
  .plan-outter-container {
    padding-top: 20px !important;
    padding-bottom: 50px;

    &.is-current {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 50%;
        right: 50%;
        bottom: 0px;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #333;
        transform: translateX(-50%);
      }
    }

    .plans-list-container {
      padding-left: 30px;

      ul li + li {
        margin-top: 5px;
      }

      .slider__package-list {
        align-items: flex-start;
      }
    }

    .gift-ticket__disclaimer {
      padding: 30px 14px 0;
    }
    .gift-ticket__price {
      margin-top: 40px;
    }

    .gift-ticket__image:before {
      padding-top: 63%;
    }

    .gift-ticket__content {
      padding: 25px 10px 13px;
    }

    .gift-ticket__inner {
      position: relative;
      display: flex;
      justify-content: center;
    }

    .badge-container {
      position: absolute;
      top: -15px;
      z-index: 5;
      width: 100%;
    }

    .gift-ticket {
      border: 2px solid #111;
    }

    .gift-ticket.active {
      border: 2px solid #f58220;
    }

    @media (max-width: 400px) {
      .plans-list-container {
        .slider__package-list {
          font-size: 13px;
        }
      }
    }
  }
}

.slider--pricing .is-slideable .slick-list:before,
.slider--giftcards .is-slideable .slick-list:before,
.slider--packages .is-slideable .slick-list:before,
.slider--pricing .is-slideable .slick-list:after,
.slider--giftcards .is-slideable .slick-list:after,
.slider--packages .is-slideable .slick-list:after {
  background: unset;
}
