html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main,
picture {
  display: block;
}
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  font-family: "Graphik LCG", sans-serif;
  background: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 11px;
  line-height: 15px;
  color: #fff;
}
@media only screen and (min-width: 768px) {
  body {
    font-size: 13px;
    line-height: 18px;
  }
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
input,
textarea,
select,
button {
  font: inherit;
}
html:not([data-whatintent="keyboard"]) *:focus {
  outline: none;
}

@font-face {
  font-family: "Graphik LCG";
  src: url(../fonts/Graphik-Semibold-Cy-Web.057f1402f259e57f27365db1acd8d71d.woff2)
      format("woff2"),
    url(../fonts/Graphik-Semibold-Cy-Web.bf1014def1d4d14a57ecafc48857f4ce.woff)
      format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Graphik LCG";
  src: url(../fonts/Graphik-Regular-Cy-Web.fe35d2bd0844ba7d7f97ff29c1460242.woff2)
      format("woff2"),
    url(../fonts/Graphik-Regular-Cy-Web.0a02cc8d8d72b57c39e81055a32809ed.woff)
      format("woff");
  font-weight: 400;
  font-style: normal;
}
.text {
  font-size: 11px;
  line-height: 15px;
}
@media only screen and (min-width: 600px) {
  .text {
    font-size: 13px;
    line-height: 18px;
  }
}
.text > * + * {
  margin-top: 20px;
}
.text.text--d-spacing > * + * {
  margin-top: 20px;
}
@media only screen and (min-width: 768px) {
  .text.text--d-spacing > * + * {
    margin-top: 40px;
  }
}
.text p + .text p {
  margin-top: 32px;
}
.text ul li {
  position: relative;
  padding-left: 14px;
}
.text ul li + li {
  margin-top: 10px;
}
@media only screen and (min-width: 768px) {
  .text ul li + li {
    margin-top: 13px;
  }
}
.text ul li:before {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  top: 4px;
  left: 0;
  border-radius: 50%;
  border: 2px solid #f58220;
  background: transparent;
}
@media only screen and (min-width: 600px) {
  .text ul li:before {
    top: 5px;
  }
}
@media only screen and (min-width: 600px) {
  .text--large ul li:before {
    top: 7px;
  }
}
@media only screen and (min-width: 768px) {
  .text--twocol ul {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 30px;
    column-gap: 30px;
  }
}
@media only screen and (min-width: 1024px) {
  .text--twocol ul {
    -webkit-column-gap: 60px;
    column-gap: 60px;
  }
}
.text--plainlist ul {
  padding-left: 0;
}
.text--plainlist ul li {
  padding-left: 0;
}
.text--plainlist ul li + li {
  margin-top: 0;
}
.text--plainlist ul li:before {
  display: none;
}
.text--uppercase {
  text-transform: uppercase;
}
h1,
.h1 {
  font-size: 26px;
  line-height: 30px;
  font-weight: 600;
  color: #fff;
}
@media only screen and (min-width: 600px) {
  h1,
  .h1 {
    font-size: 36px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 46px;
    line-height: 50px;
  }
}
h2,
.h2 {
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  font-weight: 600;
}
@media only screen and (min-width: 600px) {
  h2,
  .h2 {
    font-size: 23px;
    line-height: 26px;
  }
}
@media only screen and (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 26px;
    line-height: 30px;
  }
}
h3,
.h3 {
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  font-weight: 600;
}
@media only screen and (min-width: 600px) {
  h3,
  .h3 {
    font-size: 16px;
    line-height: 20px;
  }
}
@media only screen and (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 18px;
    line-height: 22px;
  }
}
.text--light h1,
.text--light .h1,
.text--light h2,
.text--light .h2,
.text--light h3,
.text--light .h3,
.text--light h4,
.text--light .h4,
.text--light h5,
.text--light .h5 {
  color: #202020;
}
.heading--regular {
  font-weight: 400;
}
i,
em,
.italic {
  font-style: italic;
}
.text--large {
  font-size: 13px;
  line-height: 19px;
}
@media only screen and (min-width: 600px) {
  .text--large {
    font-size: 15px;
    line-height: 22px;
  }
}
.text--small {
  font-size: 10px;
  line-height: 12px;
}
@media only screen and (min-width: 600px) {
  .text--small {
    font-size: 11px;
    line-height: 14px;
  }
}
.text--right {
  text-align: right;
}
.text--center {
  text-align: center;
}
b,
strong,
.bold {
  font-weight: 600;
}
.text-link {
  border-bottom: 2px solid transparent;
  cursor: pointer;
  -webkit-transition-property: "border-bottom, color";
  transition-property: "border-bottom, color";
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
.text-link:hover {
  border-bottom: 2px solid #ffa427;
  color: #ffa427;
}
a,
.link {
  color: #f58220;
  text-decoration: none;
  cursor: pointer;
}
@media only screen and (min-width: 1024px) {
  a,
  .link {
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    -webkit-transition-delay: 0ms;
    transition-delay: 0ms;
  }
}
.text--light a,
.text--light .link {
  color: #202020;
  font-weight: 600;
  text-decoration: underline;
}
a:hover,
.link:hover {
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
}
@media only screen and (min-width: 1024px) {
  a:hover,
  .link:hover {
    color: #ffa427;
    -webkit-transition-duration: 0ms;
    transition-duration: 0ms;
  }
}
.text--light a:hover,
.text--light .link:hover {
  color: #f58220;
}
a.h1,
a.h2,
a.h3,
.link.h1,
.link.h2,
.link.h3 {
  color: #f58220;
  font-weight: 600;
}
a.h1:hover,
a.h2:hover,
a.h3:hover,
.link.h1:hover,
.link.h2:hover,
.link.h3:hover {
  color: #ffa427;
}
.text-color--orange {
  color: #f58220;
}
.text-color--black {
  color: #000;
}
.text--regular {
  font-weight: 400;
}
.text--title {
  font-weight: 600;
  color: #888;
}
.text--container {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 18px;
  color: #fff;
  border-radius: 3px;
  font-size: 13px;
  line-height: 17px;
  font-weight: 600;
  background: #f58220;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  border: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  min-width: 200px;
}
@media only screen and (min-width: 1024px) {
  .button {
    -webkit-transition-property: background;
    transition-property: background;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    -webkit-transition-delay: 0ms;
    transition-delay: 0ms;
  }
}
.button:hover {
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
  background: #ffa427;
  cursor: pointer;
  color: #fff;
}
.button.is-disabled,
.button:disabled {
  cursor: default;
  background: #333;
  color: #f58220;
}
.button__wrapper-inner {
  color: #fff;
  -webkit-transition-property: color, border;
  transition-property: color, border;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
.button:hover .button__wrapper-inner {
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
}
.is-disabled .button__wrapper-inner {
  color: #f58220;
}
.button--small {
  min-width: 0;
  padding: 13px 16px;
}
@media only screen and (min-width: 768px) {
  .button--small {
    padding: 16px 22px;
  }
}
.button--large {
  min-width: 0;
  font-size: 15px;
  padding: 15px 25px;
  line-height: 17px;
}
.button--product {
  padding: 12px 14px;
  min-width: 80px;
  font-size: 11px;
  line-height: 11px;
}
@media only screen and (min-width: 768px) {
  .button--product {
    min-width: 100px;
    font-size: 13px;
    padding: 12px 25px;
  }
}
.button--light.is-disabled,
.button--light:disabled {
  background: #333;
  color: #ffa427;
}
.button--naked {
  min-width: 0;
  border-radius: 0;
  background: none;
  padding: 0;
  line-height: 20px;
  text-align: left;
  overflow: visible;
}
@media only screen and (min-width: 768px) {
  .button--naked {
    text-align: center;
    font-size: 15px;
  }
}
.button--naked .button__wrapper-inner {
  color: #f58220;
  border-bottom: 2px solid #f58220;
}
.button--naked:hover {
  border: none;
  background: none;
}
.button--naked:hover .button__wrapper-inner {
  color: #ffa427;
  border-color: #ffa427;
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
}
.button--ghost {
  min-width: 0;
  border-radius: 0;
  background: none;
  padding: 0;
  border: none;
  text-transform: none;
  letter-spacing: 0;
}
.button--ghost:hover {
  border: none;
  background: none;
}
.button--ghost:hover .button__wrapper-inner {
  color: #f58220;
}
.button--ghost .button__wrapper-inner {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.button--ghost.button--row.button__icon {
  margin-top: 0;
}
.button--ghost.button--row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 12px;
}
.button--upload,
.button--tiny {
  min-width: 0;
  line-height: 18px;
  font-size: 13px;
}
.button--upload {
  padding: 12px 20px 11px;
  line-height: 1;
  letter-spacing: 1px;
}
.button--link {
  min-width: 0;
  text-transform: none;
  letter-spacing: 0;
  padding: 0;
  background: none;
  border-radius: 0;
  font-weight: 600;
}
.button--link .button__wrapper-inner {
  text-decoration: underline;
}
.button--link:hover {
  background: none;
  border: none;
}
.button--link:hover .button__wrapper-inner {
  color: #f58220;
}
.button--white {
  background: #fff;
}
.button--white .button__wrapper-inner {
  color: #000;
}
.button--white:hover .button__wrapper-inner {
  color: #fff;
}
.button--block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-width: 0;
}
@media only screen and (max-width: 767px) {
  .button--block-xs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    min-width: 0;
  }
}
.button--bank {
  min-width: 0;
  max-width: 280px;
  line-height: 18px;
}
@media only screen and (min-width: 530px) {
  .button--bank {
    min-width: 240px;
  }
}
@media only screen and (min-width: 700px) {
  .button--bank {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.button--bank-swedbank {
  background-color: #ee7024;
  margin-right: 5px;
}
.button--bank-swedbank:hover {
  background-color: #ff863c;
}
.button--bank-seb {
  background-color: #60cd18;
}
.button--bank-seb:hover {
  background-color: #77e330;
}
.button--bank-luminor {
  background-color: #481335;
}
.button--bank-luminor:hover {
  background-color: #6a2551;
}
.button--bank-lhv {
  background-color: #000;
}
.button--bank-lhv:hover {
  background-color: #1a1a1a;
}
.button--bank-wider {
  min-width: 280px;
}
@media only screen and (min-width: 480px) {
  .button--bank-wider {
    min-width: 320px;
  }
}
.button--cancel {
  font-size: 15px;
  text-transform: none;
  letter-spacing: 0;
  min-width: 0;
  border-radius: 0;
  line-height: 20px;
  background: transparent;
  padding: 16px 0;
}
.button--cancel .button__wrapper-inner {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #f58220;
}
.button--cancel:hover {
  border: none;
  background: none;
}
.button--cancel:hover .button__wrapper-inner {
  color: #ffa427;
}
.button--add {
  padding: 8px 14px;
  min-width: 80px;
  font-size: 11px;
  line-height: 11px;
}
@media only screen and (min-width: 768px) {
  .button--add {
    min-width: 100px;
    font-size: 13px;
  }
}
.button--remove {
  font-size: 15px;
}
.button--remove .button__wrapper-inner {
  color: #888;
}
@media only screen and (max-width: 767px) {
  .button--remove .button__wrapper-inner {
    display: none;
  }
}
.button__icon {
  margin: 0 6px;
  font-size: 16px;
  margin-top: -1px;
}
@media only screen and (min-width: 768px) {
  .button__icon {
    margin: 0 8px;
  }
}
.button--icon .button__icon {
  margin: 0;
}
.button--naked .button__icon {
  color: #fff;
  border: none;
  margin-left: 0;
}
.button--link .button__icon {
  color: #f58220;
}
.button--ghost .button__icon {
  color: #f58220;
  font-size: 12px;
}
.button--white .button__icon {
  color: #f58220;
}
.button--white:hover .button__icon {
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
  color: #fff;
}
.button--tiny .button__icon {
  margin: 0 8px 0 0;
  font-size: 11px;
}
.button--apolloclub .button__icon {
  color: #f58220;
}
.button--add .button__icon {
  font-size: 20px;
  margin-top: 0;
}
.button--product .button__icon {
  font-size: 9px;
  margin-top: 3px;
}
@media only screen and (min-width: 768px) {
  .button--product .button__icon {
    font-size: 11px;
  }
}
.button--remove .button__icon {
  color: #888;
  margin: 0;
  font-size: 14px;
}
@media only screen and (min-width: 768px) {
  .button--remove .button__icon {
    display: none;
  }
}
.button__icon--before {
  margin-left: 0;
}
.button__icon--after {
  margin-right: 0;
}

.cardpick {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.cardpick__item {
  margin: 0 10px;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 80px;
  flex: 0 1 80px;
  max-width: 80px;
  text-align: center;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .cardpick__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 140px;
    flex: 0 0 140px;
    max-width: 140px;
  }
}
.cardpick__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.cardpick__illustration {
  background: #202020;
  border: 2px solid #202020;
  border-radius: 3px;
  height: 100px;
  margin-bottom: 10px;
  font-size: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .cardpick__illustration {
    font-size: 80px;
    height: 180px;
  }
}
.cardpick__item:hover .cardpick__illustration,
.cardpick__input:checked ~ .cardpick__label .cardpick__illustration {
  border-color: #f58220;
  cursor: pointer;
}
.cardpick__input[disabled] ~ .cardpick__label .cardpick__illustration {
  border-color: #202020;
  cursor: default;
}
.cardpick__input[disabled] ~ .cardpick__label .cardpick__illustration-icon {
  color: #333;
  cursor: default;
}
.cardpick__modal-trigger {
  display: inline-block;
  pointer-events: all;
  color: #208af5;
}
.cardpick__modal-trigger:hover {
  color: #3b9cff;
}
.cardpick__helper-icon {
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
  opacity: 1;
  pointer-events: none;
}
.cardpick__helper-icon:hover {
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
  color: #3b9cff;
}
.cardpick__label {
  white-space: nowrap;
}
.cardpick__item:hover .cardpick__text,
.cardpick__input:checked ~ .cardpick__label .cardpick__text {
  color: #f58220;
  cursor: pointer;
}
.cardpick__input[disabled] ~ .cardpick__label .cardpick__text {
  color: #888;
  cursor: default;
  text-decoration: line-through;
}
.cardpick__status {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.cardpick__status .tag {
  padding-left: 2px;
  padding-right: 2px;
  font-size: 8px;
}
@media only screen and (min-width: 768px) {
  .cardpick__status .tag {
    font-size: 9px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
.cardpick__comment {
  margin-top: 5px;
}
.cardpick__helper {
  color: #208af5;
  font-size: 16px;
  position: relative;
  top: 2px;
  margin-left: 2px;
}

.cart-item {
  position: relative;
}
.cart-item__main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.cart-item__footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 14px;
}
@media only screen and (min-width: 768px) {
  .cart-item__footer {
    position: absolute;
    left: 140px;
    top: 115px;
  }
}
.cart-item__title-text {
  display: inline-block;
}
.cart-item__poster {
  width: 60px;
}
@media only screen and (min-width: 768px) {
  .cart-item__poster {
    width: 120px;
  }
}
.cart-item__data {
  margin-left: 10px;
}
@media only screen and (min-width: 768px) {
  .cart-item__data {
    margin-left: 24px;
  }
}
.cart-item__time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 7px;
}
@media only screen and (min-width: 768px) {
  .cart-item__time {
    margin-bottom: 21px;
  }
}
@media only screen and (min-width: 1024px) {
  .cart-item__time {
    margin-bottom: 14px;
  }
}
.cart-item__location {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 7px;
}
.cart-item__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cart-item__meta-box {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.cart-item__time-icon {
  -ms-flex-item-align: center;
  align-self: center;
  font-size: 16px;
  color: #888;
}
@media only screen and (min-width: 768px) {
  .cart-item__time-icon {
    font-size: 20px;
  }
}
.cart-item__clock {
  margin-left: 8px;
}
.cart-item__date {
  margin-left: 22px;
  color: #f58220;
}
.cart-item__location-icon {
  -ms-flex-item-align: center;
  align-self: center;
  font-size: 16px;
  color: #888;
}
@media only screen and (min-width: 768px) {
  .cart-item__location-icon {
    font-size: 20px;
  }
}
.cart-item__location-room {
  margin-left: 8px;
}
.cart-item__title-icon {
  -ms-flex-item-align: center;
  align-self: center;
  font-size: 16px;
  color: #888;
}
@media only screen and (min-width: 768px) {
  .cart-item__title-icon {
    font-size: 20px;
  }
}
.cart-item__title-translated {
  margin-left: 8px;
}
.cart-item__meta-box-key {
  text-align: left;
  margin-left: 15px;
  color: #888;
}
@media only screen and (min-width: 768px) {
  .cart-item__meta-box-key {
    margin-left: 20px;
  }
}
.cart-item__meta-box-value {
  text-align: left;
  margin-left: 15px;
}
@media only screen and (min-width: 768px) {
  .cart-item__meta-box-value {
    margin-left: 20px;
  }
}

.cart-table__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 0;
  border-bottom: 2px solid #333;
}
.cart-table__title {
  display: inline-block;
  width: 100%;
}
.cart-table__sub-price {
  color: #888;
  font-weight: 400;
}
.cart-table__description {
  display: block;
  color: #888;
  margin-top: 5px;
  font-weight: 400;
}
.cart-table__price {
  display: block;
  color: #f58220;
  font-weight: 600;
  margin-top: 2px;
}
@media only screen and (min-width: 768px) {
  .cart-table__price {
    display: inline-block;
    text-align: right;
    min-width: 100px;
    margin-top: 0;
  }
}
.cart-table__number {
  min-width: 126px;
}
@media only screen and (min-width: 768px) {
  .cart-table__number {
    min-width: 180px;
  }
}
@media only screen and (min-width: 1024px) {
  .cart-table__number {
    min-width: 200px;
  }
}
.cart-table .number {
  margin-left: auto;
}

.check {
  position: relative;
}
.check__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}
.check__label {
  position: relative;
  display: inline-block;
  padding-left: 18px;
  font-weight: 600;
}
.choice-group__item .check__label {
  display: block;
}
.check__input:disabled ~ .check__label {
  opacity: 0.5;
}
.check--large .check__label {
  padding-left: 28px;
}
.check__text {
  color: #fff;
  vertical-align: middle;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
.choice-group__item:hover .check__text,
.check__label:hover .check__text,
.check__input:checked ~ .check__label .check__text {
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
  color: #f58220;
}
.check__indicator {
  display: block;
  width: 12px;
  height: 12px;
  border: 3px solid #333;
  position: absolute;
  top: 5px;
  left: 0;
  background: transparent;
}
.check--round .check__indicator {
  border-radius: 50%;
}
.check__input:checked ~ .check__label .check__indicator {
  background: #f58220;
}
.check--large .check__indicator {
  width: 20px;
  height: 20px;
  border-width: 5px;
  background: #202020;
  top: 1px;
}

.choice-group__label {
  margin-bottom: 10px;
}
.choice-group--inline .choice-group__inner {
  margin-top: -5px;
  margin-bottom: -5px;
}
.choice-group__item {
  -webkit-transition-property: background-color;
  transition-property: background-color;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
.choice-group__item + .choice-group__item {
  margin-top: 10px;
}
.choice-group--inline .choice-group__item {
  display: inline-block;
  margin: 5px 10px 5px 0;
}
.dropdown .choice-group__item {
  padding: 5px 20px;
  margin: 0;
}
.dropdown .choice-group__item:hover {
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
  background-color: rgba(0, 0, 0, 0.1);
}
.choice-group__error {
  margin-top: 5px;
  color: red;
}
.choice-group__description {
  margin-top: 5px;
}

.cinema-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  margin: 20px 0 50px;
}
@media only screen and (min-width: 600px) {
  .cinema-info {
    margin-bottom: 100px;
  }
}
.cinema-info__inner {
  position: relative;
  height: 100%;
  border-radius: 3px;
  overflow: hidden;
}
.cinema-info__image {
  width: 100%;
  height: 100%;
  position: relative;
}
.cinema-info__image:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.cinema-info__image > .image__picture {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .cinema-info__image {
    position: relative;
  }
  .cinema-info__image:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 45.94595%;
  }
  .cinema-info__image > .image__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
.cinema-info__image > .image__picture {
  width: 100%;
  height: 100%;
}
.cinema-info__image > .image__picture img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.cinema-info__title {
  margin-bottom: 18px;
}
.cinema-info__content {
  margin-bottom: 32px;
}
.cinema-info__link .button__wrapper-inner {
  color: #f58220;
}
.cinema-info__wrapper {
  padding: 18px 10px;
}
@media only screen and (min-width: 768px) {
  .cinema-info__wrapper {
    padding: 30px 24px;
  }
}
@media only screen and (min-width: 1024px) {
  .cinema-info__wrapper {
    padding: 40px;
  }
}
.cinema-info__background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.cinema-info__background > .image__img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
}

.contact-notification {
  background-color: #333;
  border-radius: 3px;
  padding: 20px 10px;
}
@media only screen and (min-width: 768px) {
  .contact-notification {
    padding: 25px 24px;
  }
}
@media only screen and (min-width: 1024px) {
  .contact-notification {
    padding: 42px 40px 48px;
  }
}
.contact-notification__title {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.contact-notification__title > .icon {
  margin-right: 12px;
  color: #f58220;
  font-size: 24px;
}

.discount-card__image {
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  -webkit-box-shadow: inset 0 -4px 0 0 #f58220;
  box-shadow: inset 0 -4px 0 0 #f58220;
}
.discount-card__image:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 40%;
}
.discount-card__image > .image__img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
}
.discount-card__image .image__img {
  z-index: -1;
}
.discount-card__content {
  padding-top: 16px;
}
@media only screen and (min-width: 768px) {
  .discount-card__content {
    padding-top: 20px;
  }
}
.text.discount-card__content > * + * {
  margin-top: 10px;
}
@media only screen and (min-width: 768px) {
  .text.discount-card__content > * + * {
    margin-top: 20px;
  }
}

.editor img {
  max-width: 100%;
  height: auto;
}

.events {
  position: relative;
}
.events__subtitle {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.events--success .events__subtitle {
  margin-top: 40px;
}
.events__dropdown-link {
  display: block;
  padding: 8px 20px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
.events__dropdown-link:hover {
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
  color: #f58220;
}
.events__dd-label {
  margin-bottom: 18px;
}
.events__section-title {
  margin-bottom: 40px;
}
.events__contact-notification {
  margin-top: 24px;
}
.events__choice-group .radio__indicator {
  display: none;
}
.events__section {
  padding-bottom: 45px;
}
@media only screen and (min-width: 1024px) {
  .events__section {
    padding-bottom: 75px;
  }
}
.events__section--small-gap {
  padding-bottom: 30px;
}
@media only screen and (min-width: 768px) {
  .events__section--small-gap {
    padding-bottom: 50px;
  }
}
.events__bg {
  top: 0;
  width: 100%;
  position: relative;
}
.events__bg-image {
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  position: relative;
  position: absolute !important;
}
.events__bg-image:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.events__bg-image > .image__picture {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
}
@media screen and (min-width: 560px) and (orientation: landscape) {
  .events__bg-image {
    position: relative;
  }
  .events__bg-image:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 33.33333%;
  }
  .events__bg-image > .image__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .events__bg-image {
    position: relative;
  }
  .events__bg-image:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 33.33333%;
  }
  .events__bg-image > .image__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) {
  .events__bg-image {
    position: relative;
  }
  .events__bg-image:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 33.39844%;
  }
  .events__bg-image > .image__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
.events__bg-image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: -1px;
  right: 0;
  z-index: 1;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(50%, rgba(32, 32, 32, 0)),
    color-stop(96%, #202020),
    to(#202020)
  );
  background: linear-gradient(
    to bottom,
    rgba(32, 32, 32, 0) 50%,
    #202020 96%,
    #202020 100%
  );
}
.events--success .events__bg-image,
.events--special .events__bg-image {
  position: absolute !important;
  top: 0;
  left: auto;
  height: 100%;
}
@media only screen and (min-width: 768px) {
  .events--success .events__bg-image,
  .events--special .events__bg-image {
    height: auto;
  }
}
.events__head {
  display: inline-block;
  padding: 40px 20px 30px;
  text-align: center;
  width: 100%;
  z-index: 1;
}
@media only screen and (min-width: 768px) {
  .events__head {
    padding: 35px 20px 50px;
  }
}
.events--success .events__head,
.events--special .events__head {
  position: relative;
}
.events--success .events__head:before,
.events--special .events__head:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 66.66667%;
}
@media only screen and (min-width: 768px) {
  .events--success .events__head:before,
  .events--special .events__head:before {
    padding-top: 22%;
  }
}
@media screen and (min-width: 1440px) {
  .events--success .events__head:before,
  .events--special .events__head:before {
    padding-top: 20vw;
  }
}
@media only screen and (min-width: 768px) {
  .events__head--large-gap {
    padding: 40px 20px 60px;
  }
}
.events__back {
  text-transform: none;
  letter-spacing: 0;
  margin-top: 20px;
}
.events__back .button__wrapper-inner {
  border: none;
}
.events__main-content {
  padding-bottom: 30px;
}
@media only screen and (min-width: 768px) {
  .events__main-content {
    padding-bottom: 40px;
  }
}
.events--special .events__main-content {
  padding-bottom: 0;
}
.events__success-icon {
  font-size: 40px;
  color: #f58220;
  margin-bottom: 0;
}
.events__success-icon + .events__title {
  margin-top: 0;
}
.events__thumblist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -2px 26px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) {
  .events__thumblist {
    margin-bottom: 0;
  }
}
.events__thumblist-item {
  width: 100%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
  padding: 0 2px 4px;
  display: inline-block;
}
.events__bg-image .image__img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.icon {
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.gallery__item {
  position: relative;
}
.gallery__image .image__img {
  display: block;
  width: 100%;
  height: auto;
}
body.compensate-for-scrollbar {
  overflow: hidden;
}
.fancybox-active {
  height: auto;
}
.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden;
}
.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  width: 100%;
  z-index: 99992;
}
.fancybox-container * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}
.fancybox-bg {
  background: #000;
  opacity: 0;
  -webkit-transition-duration: inherit;
  transition-duration: inherit;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}
.fancybox-is-open .fancybox-bg {
  opacity: 1;
  -webkit-transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}
.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 0.25s ease, visibility 0s ease 0.25s;
  transition: opacity 0.25s ease, visibility 0s ease 0.25s;
  visibility: hidden;
  z-index: 99997;
}
.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease 0s, visibility 0s ease 0s;
  transition: opacity 0.25s ease 0s, visibility 0s ease 0s;
  visibility: visible;
}
.fancybox-infobar {
  color: #333;
  font-size: 13px;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.fancybox-toolbar {
  right: 0;
  top: 0;
}
.fancybox-stage {
  direction: ltr;
  overflow: visible;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 99994;
}
.fancybox-is-open .fancybox-stage {
  overflow: hidden;
}
.fancybox-slide {
  -webkit-backface-visibility: hidden;
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  white-space: normal;
  width: 100%;
  z-index: 99994;
}
.fancybox-slide::before {
  content: "";
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0;
}
.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block;
}
.fancybox-slide--image::before {
  display: none;
}
.fancybox-slide--html {
  padding: 6px;
}
.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle;
}
.fancybox-slide--image .fancybox-content {
  -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995;
}
.fancybox-can-zoomOut .fancybox-content {
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}
.fancybox-can-zoomIn .fancybox-content {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab;
}
.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.fancybox-container [data-selectable="true"] {
  cursor: text;
}
.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}
.fancybox-spaceball {
  z-index: 1;
}
.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%;
}
.fancybox-slide--video .fancybox-content {
  background: #000;
}
.fancybox-slide--map .fancybox-content {
  background: #e5e3df;
}
.fancybox-slide--iframe .fancybox-content {
  background: #fff;
}
.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}
.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0;
}
.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%;
}
.fancybox-error p {
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}
.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px;
}
.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #333;
}
.fancybox-button:hover {
  color: #fff;
}
.fancybox-button:focus {
  outline: none;
}
.fancybox-button.fancybox-focus {
  outline: 1px dotted;
}
.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888888;
  cursor: default;
  outline: none;
}
.fancybox-button div {
  height: 100%;
}
.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%;
}
.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0;
}
.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none;
}
.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none;
}
.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0;
  transform-origin: 0;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  z-index: 99998;
}
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #333;
  cursor: pointer;
  opacity: 0.8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401;
}
.fancybox-close-small:hover {
  color: #fff;
  opacity: 1;
}
.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0;
}
.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden;
}
.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none;
}
.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 100px;
  opacity: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 70px;
}
.fancybox-navigation .fancybox-button div {
  padding: 7px;
}
.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  left: env(safe-area-inset-left);
  padding: 31px 26px 31px 6px;
}
.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0;
  right: env(safe-area-inset-right);
}
.fancybox-caption {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 0, 0, 0.85)),
    color-stop(50%, rgba(0, 0, 0, 0.3)),
    color-stop(65%, rgba(0, 0, 0, 0.15)),
    color-stop(75.5%, rgba(0, 0, 0, 0.075)),
    color-stop(82.85%, rgba(0, 0, 0, 0.037)),
    color-stop(88%, rgba(0, 0, 0, 0.019)),
    to(rgba(0, 0, 0, 0))
  );
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.85) 0%,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0.15) 65%,
    rgba(0, 0, 0, 0.075) 75.5%,
    rgba(0, 0, 0, 0.037) 82.85%,
    rgba(0, 0, 0, 0.019) 88%,
    rgba(0, 0, 0, 0) 100%
  );
  bottom: 0;
  color: #eeeeee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 75px 44px 25px 44px;
  pointer-events: none;
  right: 0;
  text-align: center;
  z-index: 99996;
}
.fancybox-caption--separate {
  margin-top: -50px;
}
.fancybox-caption__body {
  max-height: 50vh;
  overflow: auto;
  pointer-events: all;
}
.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #333;
  text-decoration: none;
}
.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline;
}
.fancybox-loading {
  -webkit-animation: fancybox-rotate 1s linear infinite;
  animation: fancybox-rotate 1s linear infinite;
  background: transparent;
  border: 4px solid #888888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999;
}
@-webkit-keyframes fancybox-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes fancybox-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.fancybox-animated {
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1;
}
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: rotate(-360deg);
  transform: rotate(-360deg);
}
.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}
.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}
.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
}
.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}
.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}
.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
}
@media all and (max-height: 576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px;
  }
  .fancybox-slide--image {
    padding: 6px 0;
  }
  .fancybox-close-small {
    right: -6px;
  }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px;
  }
  .fancybox-caption {
    padding-left: 12px;
    padding-right: 12px;
  }
  @supports (padding: 0) {
    .fancybox-caption {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}
.fancybox-button--close {
  color: #f58220;
  background: none;
}
.fancybox-button--close > svg {
  height: 14px;
  width: 14px;
}
.fancybox-slide--image {
  overflow: hidden;
  padding: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
  opacity: 1;
}
@media only screen and (min-width: 768px) {
  .fancybox-slide--image {
    padding: 20px;
  }
}
.fancybox-container.fancybox-is-closing .fancybox-slide--image {
  opacity: 0;
}
.fancybox-button.fancybox-button--arrow_left,
.fancybox-button.fancybox-button--arrow_right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 20px;
  background: rgba(51, 51, 51, 0.4);
  padding: 0;
  border-radius: 50%;
  color: #fff;
  width: 40px;
  height: 40px;
}
.fancybox-button.fancybox-button--arrow_left:hover,
.fancybox-button.fancybox-button--arrow_right:hover {
  color: #f58220;
}
@media only screen and (min-width: 768px) {
  .fancybox-button.fancybox-button--arrow_left,
  .fancybox-button.fancybox-button--arrow_right {
    width: 60px;
    height: 60px;
  }
}
@media only screen and (min-width: 1024px) {
  .fancybox-button.fancybox-button--arrow_left,
  .fancybox-button.fancybox-button--arrow_right {
    margin-left: 40px;
  }
}
.fancybox-button.fancybox-button--arrow_left > svg,
.fancybox-button.fancybox-button--arrow_right > svg {
  height: 14px;
  width: 7px;
}
@media only screen and (min-width: 768px) {
  .fancybox-button.fancybox-button--arrow_left > svg,
  .fancybox-button.fancybox-button--arrow_right > svg {
    height: 20px;
    width: 10px;
  }
}
.fancybox-button.fancybox-button--arrow_right {
  margin-left: unset;
  margin-right: 20px;
}
@media only screen and (min-width: 1024px) {
  .fancybox-button.fancybox-button--arrow_right {
    margin-right: 40px;
  }
}

.grid {
  letter-spacing: -0.65em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: -10px;
}
@media only screen and (min-width: 768px) {
  .grid {
    margin-right: -6px;
    margin-left: -6px;
    margin-bottom: -12px;
  }
}
@media only screen and (min-width: 1024px) {
  .grid {
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: -20px;
  }
}
@media only screen and (min-width: 768px) {
  .grid--large-gap {
    margin-right: -12px;
    margin-left: -12px;
    margin-bottom: -12px;
  }
}
@media only screen and (min-width: 1170px) {
  .grid--large-gap {
    margin-right: -20px;
    margin-left: -20px;
    margin-bottom: -20px;
  }
}
@media only screen and (min-width: 768px) {
  .grid--huge-gap {
    margin-right: -18px;
    margin-left: -18px;
    margin-bottom: -18px;
  }
}
@media only screen and (min-width: 1170px) {
  .grid--huge-gap {
    margin-right: -30px;
    margin-left: -30px;
    margin-bottom: -30px;
  }
}
.grid--no-gutter {
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 0;
}
.grid--no-horizontal-gutter {
  margin-right: 0;
  margin-left: 0;
}
.grid--no-vertical-gutter {
  margin-bottom: 0;
}
.grid--double-v-spacing {
  margin-bottom: -30px;
}
@media only screen and (min-width: 768px) {
  .grid--double-v-spacing {
    margin-bottom: -40px;
  }
}
@media only screen and (min-width: 1024px) {
  .grid--double-v-spacing {
    margin-bottom: -50px;
  }
}
.grid__col {
  letter-spacing: 0;
  text-align: left;
  display: inline-block;
  vertical-align: top;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 5px;
  padding-left: 5px;
  margin-bottom: 10px;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
  width: 100%;
  outline: none;
}
@media only screen and (min-width: 768px) {
  .grid__col {
    padding-right: 6px;
    padding-left: 6px;
    margin-bottom: 12px;
  }
}
@media only screen and (min-width: 1024px) {
  .grid__col {
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) {
  .grid--large-gap > .grid__col {
    padding-right: 12px;
    padding-left: 12px;
    margin-bottom: 12px;
  }
}
@media only screen and (min-width: 1170px) {
  .grid--large-gap > .grid__col {
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) {
  .grid--huge-gap > .grid__col {
    padding-right: 18px;
    padding-left: 18px;
    margin-bottom: 18px;
  }
}
@media only screen and (min-width: 1170px) {
  .grid--huge-gap > .grid__col {
    padding-right: 30px;
    padding-left: 30px;
    margin-bottom: 30px;
  }
}
.grid--equalheight > .grid__col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.grid--no-gutter > .grid__col {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}
.grid--no-horizontal-gutter > .grid__col {
  padding-left: 0;
  padding-right: 0;
}
.grid--no-vertical-gutter > .grid__col {
  margin-bottom: 0;
}
.grid--double-v-spacing > .grid__col {
  margin-bottom: 30px;
}
@media only screen and (min-width: 768px) {
  .grid--double-v-spacing > .grid__col {
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 1024px) {
  .grid--double-v-spacing > .grid__col {
    margin-bottom: 50px;
  }
}
.grid__col--no-vertical-gutter {
  margin-bottom: 0;
}
@media only screen and (min-width: 768px) {
  .grid__col--third-sm {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 33.33333%;
    flex: 1 1 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
}
.grid__col--xs-1 {
  -webkit-box-ordinal-group: auto;
  -ms-flex-order: auto;
  order: auto;
  -ms-flex-preferred-size: 6.25%;
  flex-basis: 6.25%;
  max-width: 6.25%;
}
.grid__col--xs-2 {
  -webkit-box-ordinal-group: auto;
  -ms-flex-order: auto;
  order: auto;
  -ms-flex-preferred-size: 12.5%;
  flex-basis: 12.5%;
  max-width: 12.5%;
}
.grid__col--xs-3 {
  -webkit-box-ordinal-group: auto;
  -ms-flex-order: auto;
  order: auto;
  -ms-flex-preferred-size: 18.75%;
  flex-basis: 18.75%;
  max-width: 18.75%;
}
.grid__col--xs-4 {
  -webkit-box-ordinal-group: auto;
  -ms-flex-order: auto;
  order: auto;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%;
}
.grid__col--xs-5 {
  -webkit-box-ordinal-group: auto;
  -ms-flex-order: auto;
  order: auto;
  -ms-flex-preferred-size: 31.25%;
  flex-basis: 31.25%;
  max-width: 31.25%;
}
.grid__col--xs-6 {
  -webkit-box-ordinal-group: auto;
  -ms-flex-order: auto;
  order: auto;
  -ms-flex-preferred-size: 37.5%;
  flex-basis: 37.5%;
  max-width: 37.5%;
}
.grid__col--xs-7 {
  -webkit-box-ordinal-group: auto;
  -ms-flex-order: auto;
  order: auto;
  -ms-flex-preferred-size: 43.75%;
  flex-basis: 43.75%;
  max-width: 43.75%;
}
.grid__col--xs-8 {
  -webkit-box-ordinal-group: auto;
  -ms-flex-order: auto;
  order: auto;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}
.grid__col--xs-9 {
  -webkit-box-ordinal-group: auto;
  -ms-flex-order: auto;
  order: auto;
  -ms-flex-preferred-size: 56.25%;
  flex-basis: 56.25%;
  max-width: 56.25%;
}
.grid__col--xs-10 {
  -webkit-box-ordinal-group: auto;
  -ms-flex-order: auto;
  order: auto;
  -ms-flex-preferred-size: 62.5%;
  flex-basis: 62.5%;
  max-width: 62.5%;
}
.grid__col--xs-11 {
  -webkit-box-ordinal-group: auto;
  -ms-flex-order: auto;
  order: auto;
  -ms-flex-preferred-size: 68.75%;
  flex-basis: 68.75%;
  max-width: 68.75%;
}
.grid__col--xs-12 {
  -webkit-box-ordinal-group: auto;
  -ms-flex-order: auto;
  order: auto;
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%;
}
.grid__col--xs-13 {
  -webkit-box-ordinal-group: auto;
  -ms-flex-order: auto;
  order: auto;
  -ms-flex-preferred-size: 81.25%;
  flex-basis: 81.25%;
  max-width: 81.25%;
}
.grid__col--xs-14 {
  -webkit-box-ordinal-group: auto;
  -ms-flex-order: auto;
  order: auto;
  -ms-flex-preferred-size: 87.5%;
  flex-basis: 87.5%;
  max-width: 87.5%;
}
.grid__col--xs-15 {
  -webkit-box-ordinal-group: auto;
  -ms-flex-order: auto;
  order: auto;
  -ms-flex-preferred-size: 93.75%;
  flex-basis: 93.75%;
  max-width: 93.75%;
}
.grid__col--xs-16 {
  -webkit-box-ordinal-group: auto;
  -ms-flex-order: auto;
  order: auto;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}
.grid__col--offset-xs-1 {
  margin-left: 6.25%;
}
.grid__col--offset-xs-2 {
  margin-left: 12.5%;
}
.grid__col--offset-xs-3 {
  margin-left: 18.75%;
}
.grid__col--offset-xs-4 {
  margin-left: 25%;
}
.grid__col--offset-xs-5 {
  margin-left: 31.25%;
}
.grid__col--offset-xs-6 {
  margin-left: 37.5%;
}
.grid__col--offset-xs-7 {
  margin-left: 43.75%;
}
.grid__col--offset-xs-8 {
  margin-left: 50%;
}
.grid__col--offset-xs-9 {
  margin-left: 56.25%;
}
.grid__col--offset-xs-10 {
  margin-left: 62.5%;
}
.grid__col--offset-xs-11 {
  margin-left: 68.75%;
}
.grid__col--offset-xs-12 {
  margin-left: 75%;
}
.grid__col--offset-xs-13 {
  margin-left: 81.25%;
}
.grid__col--offset-xs-14 {
  margin-left: 87.5%;
}
.grid__col--offset-xs-15 {
  margin-left: 93.75%;
}
.grid__col--offset-xs-16 {
  margin-left: 100%;
}
.grid__col--xs {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  width: auto;
  max-width: 100%;
}
.grid__col--top-xs {
  -ms-flex-item-align: start;
  align-self: flex-start;
  vertical-align: top;
}
.grid__col--middle-xs {
  -ms-flex-item-align: center;
  align-self: center;
  vertical-align: middle;
}
.grid__col--bottom-xs {
  -ms-flex-item-align: end;
  align-self: flex-end;
  vertical-align: bottom;
}
.grid__col--alignright-xs {
  text-align: right;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.grid__col--alignleft-xs {
  text-align: left;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.grid__col--center-xs {
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.grid--reverse-xs {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.grid--row-xs {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.grid--column-reverse-xs {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.grid--start-xs {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-align: left;
}
.grid--center-xs {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.grid--end-xs {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: right;
}
.grid--top-xs {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.grid--top-xs > .grid__col {
  vertical-align: top;
}
.grid--middle-xs {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.grid--middle-xs > .grid__col {
  vertical-align: middle;
}
.grid--bottom-xs {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.grid--bottom-xs > .grid__col {
  vertical-align: bottom;
}
.grid--around-xs {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.grid--between-xs {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.grid__col--original-xs {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}
.grid__col--first-xs {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}
.grid__col--last-xs {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
@media only screen and (max-width: 767px) {
  .grid__col--reverse-xs {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media only screen and (min-width: 768px) {
  .grid__col--sm-1 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 6.25%;
    flex-basis: 6.25%;
    max-width: 6.25%;
  }
  .grid__col--sm-2 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 12.5%;
    flex-basis: 12.5%;
    max-width: 12.5%;
  }
  .grid__col--sm-3 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 18.75%;
    flex-basis: 18.75%;
    max-width: 18.75%;
  }
  .grid__col--sm-4 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .grid__col--sm-5 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 31.25%;
    flex-basis: 31.25%;
    max-width: 31.25%;
  }
  .grid__col--sm-6 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 37.5%;
    flex-basis: 37.5%;
    max-width: 37.5%;
  }
  .grid__col--sm-7 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 43.75%;
    flex-basis: 43.75%;
    max-width: 43.75%;
  }
  .grid__col--sm-8 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .grid__col--sm-9 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 56.25%;
    flex-basis: 56.25%;
    max-width: 56.25%;
  }
  .grid__col--sm-10 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 62.5%;
    flex-basis: 62.5%;
    max-width: 62.5%;
  }
  .grid__col--sm-11 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 68.75%;
    flex-basis: 68.75%;
    max-width: 68.75%;
  }
  .grid__col--sm-12 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .grid__col--sm-13 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 81.25%;
    flex-basis: 81.25%;
    max-width: 81.25%;
  }
  .grid__col--sm-14 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 87.5%;
    flex-basis: 87.5%;
    max-width: 87.5%;
  }
  .grid__col--sm-15 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 93.75%;
    flex-basis: 93.75%;
    max-width: 93.75%;
  }
  .grid__col--sm-16 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .grid__col--offset-sm-1 {
    margin-left: 6.25%;
  }
  .grid__col--offset-sm-2 {
    margin-left: 12.5%;
  }
  .grid__col--offset-sm-3 {
    margin-left: 18.75%;
  }
  .grid__col--offset-sm-4 {
    margin-left: 25%;
  }
  .grid__col--offset-sm-5 {
    margin-left: 31.25%;
  }
  .grid__col--offset-sm-6 {
    margin-left: 37.5%;
  }
  .grid__col--offset-sm-7 {
    margin-left: 43.75%;
  }
  .grid__col--offset-sm-8 {
    margin-left: 50%;
  }
  .grid__col--offset-sm-9 {
    margin-left: 56.25%;
  }
  .grid__col--offset-sm-10 {
    margin-left: 62.5%;
  }
  .grid__col--offset-sm-11 {
    margin-left: 68.75%;
  }
  .grid__col--offset-sm-12 {
    margin-left: 75%;
  }
  .grid__col--offset-sm-13 {
    margin-left: 81.25%;
  }
  .grid__col--offset-sm-14 {
    margin-left: 87.5%;
  }
  .grid__col--offset-sm-15 {
    margin-left: 93.75%;
  }
  .grid__col--offset-sm-16 {
    margin-left: 100%;
  }
  .grid__col--sm {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    width: auto;
    max-width: 100%;
  }
  .grid__col--top-sm {
    -ms-flex-item-align: start;
    align-self: flex-start;
    vertical-align: top;
  }
  .grid__col--middle-sm {
    -ms-flex-item-align: center;
    align-self: center;
    vertical-align: middle;
  }
  .grid__col--bottom-sm {
    -ms-flex-item-align: end;
    align-self: flex-end;
    vertical-align: bottom;
  }
  .grid__col--alignright-sm {
    text-align: right;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .grid__col--alignleft-sm {
    text-align: left;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .grid__col--center-sm {
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .grid--reverse-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .grid--row-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .grid--column-reverse-sm {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .grid--start-sm {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: left;
  }
  .grid--center-sm {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .grid--end-sm {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: right;
  }
  .grid--top-sm {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .grid--top-sm > .grid__col {
    vertical-align: top;
  }
  .grid--middle-sm {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .grid--middle-sm > .grid__col {
    vertical-align: middle;
  }
  .grid--bottom-sm {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .grid--bottom-sm > .grid__col {
    vertical-align: bottom;
  }
  .grid--around-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .grid--between-sm {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .grid__col--original-sm {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .grid__col--first-sm {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .grid__col--last-sm {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
@media only screen and (min-width: 768px) and (max-width: 767px) {
  .grid__col--reverse-sm {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media only screen and (min-width: 1024px) {
  .grid__col--md-1 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 6.25%;
    flex-basis: 6.25%;
    max-width: 6.25%;
  }
  .grid__col--md-2 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 12.5%;
    flex-basis: 12.5%;
    max-width: 12.5%;
  }
  .grid__col--md-3 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 18.75%;
    flex-basis: 18.75%;
    max-width: 18.75%;
  }
  .grid__col--md-4 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .grid__col--md-5 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 31.25%;
    flex-basis: 31.25%;
    max-width: 31.25%;
  }
  .grid__col--md-6 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 37.5%;
    flex-basis: 37.5%;
    max-width: 37.5%;
  }
  .grid__col--md-7 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 43.75%;
    flex-basis: 43.75%;
    max-width: 43.75%;
  }
  .grid__col--md-8 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .grid__col--md-9 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 56.25%;
    flex-basis: 56.25%;
    max-width: 56.25%;
  }
  .grid__col--md-10 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 62.5%;
    flex-basis: 62.5%;
    max-width: 62.5%;
  }
  .grid__col--md-11 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 68.75%;
    flex-basis: 68.75%;
    max-width: 68.75%;
  }
  .grid__col--md-12 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .grid__col--md-13 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 81.25%;
    flex-basis: 81.25%;
    max-width: 81.25%;
  }
  .grid__col--md-14 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 87.5%;
    flex-basis: 87.5%;
    max-width: 87.5%;
  }
  .grid__col--md-15 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 93.75%;
    flex-basis: 93.75%;
    max-width: 93.75%;
  }
  .grid__col--md-16 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .grid__col--offset-md-1 {
    margin-left: 6.25%;
  }
  .grid__col--offset-md-2 {
    margin-left: 12.5%;
  }
  .grid__col--offset-md-3 {
    margin-left: 18.75%;
  }
  .grid__col--offset-md-4 {
    margin-left: 25%;
  }
  .grid__col--offset-md-5 {
    margin-left: 31.25%;
  }
  .grid__col--offset-md-6 {
    margin-left: 37.5%;
  }
  .grid__col--offset-md-7 {
    margin-left: 43.75%;
  }
  .grid__col--offset-md-8 {
    margin-left: 50%;
  }
  .grid__col--offset-md-9 {
    margin-left: 56.25%;
  }
  .grid__col--offset-md-10 {
    margin-left: 62.5%;
  }
  .grid__col--offset-md-11 {
    margin-left: 68.75%;
  }
  .grid__col--offset-md-12 {
    margin-left: 75%;
  }
  .grid__col--offset-md-13 {
    margin-left: 81.25%;
  }
  .grid__col--offset-md-14 {
    margin-left: 87.5%;
  }
  .grid__col--offset-md-15 {
    margin-left: 93.75%;
  }
  .grid__col--offset-md-16 {
    margin-left: 100%;
  }
  .grid__col--md {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    width: auto;
    max-width: 100%;
  }
  .grid__col--top-md {
    -ms-flex-item-align: start;
    align-self: flex-start;
    vertical-align: top;
  }
  .grid__col--middle-md {
    -ms-flex-item-align: center;
    align-self: center;
    vertical-align: middle;
  }
  .grid__col--bottom-md {
    -ms-flex-item-align: end;
    align-self: flex-end;
    vertical-align: bottom;
  }
  .grid__col--alignright-md {
    text-align: right;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .grid__col--alignleft-md {
    text-align: left;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .grid__col--center-md {
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .grid--reverse-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .grid--row-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .grid--column-reverse-md {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .grid--start-md {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: left;
  }
  .grid--center-md {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .grid--end-md {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: right;
  }
  .grid--top-md {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .grid--top-md > .grid__col {
    vertical-align: top;
  }
  .grid--middle-md {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .grid--middle-md > .grid__col {
    vertical-align: middle;
  }
  .grid--bottom-md {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .grid--bottom-md > .grid__col {
    vertical-align: bottom;
  }
  .grid--around-md {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .grid--between-md {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .grid__col--original-md {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .grid__col--first-md {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .grid__col--last-md {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 767px) {
  .grid__col--reverse-md {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media only screen and (min-width: 1170px) {
  .grid__col--lg-1 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 6.25%;
    flex-basis: 6.25%;
    max-width: 6.25%;
  }
  .grid__col--lg-2 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 12.5%;
    flex-basis: 12.5%;
    max-width: 12.5%;
  }
  .grid__col--lg-3 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 18.75%;
    flex-basis: 18.75%;
    max-width: 18.75%;
  }
  .grid__col--lg-4 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .grid__col--lg-5 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 31.25%;
    flex-basis: 31.25%;
    max-width: 31.25%;
  }
  .grid__col--lg-6 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 37.5%;
    flex-basis: 37.5%;
    max-width: 37.5%;
  }
  .grid__col--lg-7 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 43.75%;
    flex-basis: 43.75%;
    max-width: 43.75%;
  }
  .grid__col--lg-8 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .grid__col--lg-9 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 56.25%;
    flex-basis: 56.25%;
    max-width: 56.25%;
  }
  .grid__col--lg-10 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 62.5%;
    flex-basis: 62.5%;
    max-width: 62.5%;
  }
  .grid__col--lg-11 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 68.75%;
    flex-basis: 68.75%;
    max-width: 68.75%;
  }
  .grid__col--lg-12 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .grid__col--lg-13 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 81.25%;
    flex-basis: 81.25%;
    max-width: 81.25%;
  }
  .grid__col--lg-14 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 87.5%;
    flex-basis: 87.5%;
    max-width: 87.5%;
  }
  .grid__col--lg-15 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 93.75%;
    flex-basis: 93.75%;
    max-width: 93.75%;
  }
  .grid__col--lg-16 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .grid__col--offset-lg-1 {
    margin-left: 6.25%;
  }
  .grid__col--offset-lg-2 {
    margin-left: 12.5%;
  }
  .grid__col--offset-lg-3 {
    margin-left: 18.75%;
  }
  .grid__col--offset-lg-4 {
    margin-left: 25%;
  }
  .grid__col--offset-lg-5 {
    margin-left: 31.25%;
  }
  .grid__col--offset-lg-6 {
    margin-left: 37.5%;
  }
  .grid__col--offset-lg-7 {
    margin-left: 43.75%;
  }
  .grid__col--offset-lg-8 {
    margin-left: 50%;
  }
  .grid__col--offset-lg-9 {
    margin-left: 56.25%;
  }
  .grid__col--offset-lg-10 {
    margin-left: 62.5%;
  }
  .grid__col--offset-lg-11 {
    margin-left: 68.75%;
  }
  .grid__col--offset-lg-12 {
    margin-left: 75%;
  }
  .grid__col--offset-lg-13 {
    margin-left: 81.25%;
  }
  .grid__col--offset-lg-14 {
    margin-left: 87.5%;
  }
  .grid__col--offset-lg-15 {
    margin-left: 93.75%;
  }
  .grid__col--offset-lg-16 {
    margin-left: 100%;
  }
  .grid__col--lg {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    width: auto;
    max-width: 100%;
  }
  .grid__col--top-lg {
    -ms-flex-item-align: start;
    align-self: flex-start;
    vertical-align: top;
  }
  .grid__col--middle-lg {
    -ms-flex-item-align: center;
    align-self: center;
    vertical-align: middle;
  }
  .grid__col--bottom-lg {
    -ms-flex-item-align: end;
    align-self: flex-end;
    vertical-align: bottom;
  }
  .grid__col--alignright-lg {
    text-align: right;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .grid__col--alignleft-lg {
    text-align: left;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .grid__col--center-lg {
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .grid--reverse-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .grid--row-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .grid--column-reverse-lg {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .grid--start-lg {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: left;
  }
  .grid--center-lg {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .grid--end-lg {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: right;
  }
  .grid--top-lg {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .grid--top-lg > .grid__col {
    vertical-align: top;
  }
  .grid--middle-lg {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .grid--middle-lg > .grid__col {
    vertical-align: middle;
  }
  .grid--bottom-lg {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .grid--bottom-lg > .grid__col {
    vertical-align: bottom;
  }
  .grid--around-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .grid--between-lg {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .grid__col--original-lg {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .grid__col--first-lg {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .grid__col--last-lg {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
@media only screen and (min-width: 1170px) and (max-width: 767px) {
  .grid__col--reverse-lg {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media only screen and (min-width: 1300px) {
  .grid__col--xl-1 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 6.25%;
    flex-basis: 6.25%;
    max-width: 6.25%;
  }
  .grid__col--xl-2 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 12.5%;
    flex-basis: 12.5%;
    max-width: 12.5%;
  }
  .grid__col--xl-3 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 18.75%;
    flex-basis: 18.75%;
    max-width: 18.75%;
  }
  .grid__col--xl-4 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .grid__col--xl-5 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 31.25%;
    flex-basis: 31.25%;
    max-width: 31.25%;
  }
  .grid__col--xl-6 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 37.5%;
    flex-basis: 37.5%;
    max-width: 37.5%;
  }
  .grid__col--xl-7 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 43.75%;
    flex-basis: 43.75%;
    max-width: 43.75%;
  }
  .grid__col--xl-8 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .grid__col--xl-9 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 56.25%;
    flex-basis: 56.25%;
    max-width: 56.25%;
  }
  .grid__col--xl-10 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 62.5%;
    flex-basis: 62.5%;
    max-width: 62.5%;
  }
  .grid__col--xl-11 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 68.75%;
    flex-basis: 68.75%;
    max-width: 68.75%;
  }
  .grid__col--xl-12 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .grid__col--xl-13 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 81.25%;
    flex-basis: 81.25%;
    max-width: 81.25%;
  }
  .grid__col--xl-14 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 87.5%;
    flex-basis: 87.5%;
    max-width: 87.5%;
  }
  .grid__col--xl-15 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 93.75%;
    flex-basis: 93.75%;
    max-width: 93.75%;
  }
  .grid__col--xl-16 {
    -webkit-box-ordinal-group: auto;
    -ms-flex-order: auto;
    order: auto;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .grid__col--offset-xl-1 {
    margin-left: 6.25%;
  }
  .grid__col--offset-xl-2 {
    margin-left: 12.5%;
  }
  .grid__col--offset-xl-3 {
    margin-left: 18.75%;
  }
  .grid__col--offset-xl-4 {
    margin-left: 25%;
  }
  .grid__col--offset-xl-5 {
    margin-left: 31.25%;
  }
  .grid__col--offset-xl-6 {
    margin-left: 37.5%;
  }
  .grid__col--offset-xl-7 {
    margin-left: 43.75%;
  }
  .grid__col--offset-xl-8 {
    margin-left: 50%;
  }
  .grid__col--offset-xl-9 {
    margin-left: 56.25%;
  }
  .grid__col--offset-xl-10 {
    margin-left: 62.5%;
  }
  .grid__col--offset-xl-11 {
    margin-left: 68.75%;
  }
  .grid__col--offset-xl-12 {
    margin-left: 75%;
  }
  .grid__col--offset-xl-13 {
    margin-left: 81.25%;
  }
  .grid__col--offset-xl-14 {
    margin-left: 87.5%;
  }
  .grid__col--offset-xl-15 {
    margin-left: 93.75%;
  }
  .grid__col--offset-xl-16 {
    margin-left: 100%;
  }
  .grid__col--xl {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    width: auto;
    max-width: 100%;
  }
  .grid__col--top-xl {
    -ms-flex-item-align: start;
    align-self: flex-start;
    vertical-align: top;
  }
  .grid__col--middle-xl {
    -ms-flex-item-align: center;
    align-self: center;
    vertical-align: middle;
  }
  .grid__col--bottom-xl {
    -ms-flex-item-align: end;
    align-self: flex-end;
    vertical-align: bottom;
  }
  .grid__col--alignright-xl {
    text-align: right;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .grid__col--alignleft-xl {
    text-align: left;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .grid__col--center-xl {
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .grid--reverse-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .grid--row-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .grid--column-reverse-xl {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .grid--start-xl {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: left;
  }
  .grid--center-xl {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .grid--end-xl {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: right;
  }
  .grid--top-xl {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .grid--top-xl > .grid__col {
    vertical-align: top;
  }
  .grid--middle-xl {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .grid--middle-xl > .grid__col {
    vertical-align: middle;
  }
  .grid--bottom-xl {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .grid--bottom-xl > .grid__col {
    vertical-align: bottom;
  }
  .grid--around-xl {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .grid--between-xl {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .grid__col--original-xl {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .grid__col--first-xl {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .grid__col--last-xl {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 767px) {
  .grid__col--reverse-xl {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  display: inline-block;
  cursor: pointer;
  -webkit-transition-property: opacity, -webkit-filter;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  overflow: visible;
  outline: none;
  height: 20px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.hamburger:focus {
  border: none;
  outline: none;
}
.hamburger:active {
  background: transparent;
}
.hamburger:hover {
  opacity: 1;
}
.hamburger-box {
  width: 22px;
  height: 18px;
  display: inline-block;
  position: relative;
}
.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 22px;
  height: 2px;
  background-color: #f58220;
  border-radius: 3px;
  position: absolute;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -8px;
}
.hamburger-inner::after {
  bottom: -8px;
}
.hamburger--slider .hamburger-inner {
  top: 1px;
}
.hamburger--slider .hamburger-inner::before {
  top: 8px;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-duration: 150ms;
  transition-duration: 150ms;
}
.hamburger--slider .hamburger-inner::after {
  top: 16px;
}
.is-menu-open .hamburger--slider .hamburger-inner {
  -webkit-transform: translate3d(0, 8px, 0) rotate(45deg);
  transform: translate3d(0, 8px, 0) rotate(45deg);
}
.is-menu-open .hamburger--slider .hamburger-inner::before {
  -webkit-transform: rotate(-45deg) translate3d(-3.14286px, -6px, 0);
  transform: rotate(-45deg) translate3d(-3.14286px, -6px, 0);
  opacity: 0;
}
.is-menu-open .hamburger--slider .hamburger-inner::after {
  -webkit-transform: translate3d(0, -16px, 0) rotate(-90deg);
  transform: translate3d(0, -16px, 0) rotate(-90deg);
}

.hero--background-image {
  position: relative;
}
.hero__bg-image {
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  position: relative;
  position: absolute;
}
.hero__bg-image:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.hero__bg-image > .image__picture {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
}
@media screen and (min-width: 560px) and (orientation: landscape) {
  .hero__bg-image {
    position: relative;
    position: absolute;
  }
  .hero__bg-image:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 33.33333%;
  }
  .hero__bg-image > .image__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .hero__bg-image {
    position: relative;
    position: absolute;
  }
  .hero__bg-image:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 33.33333%;
  }
  .hero__bg-image > .image__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) {
  .hero__bg-image {
    position: relative;
    position: absolute;
  }
  .hero__bg-image:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 33.39844%;
  }
  .hero__bg-image > .image__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
.hero__bg-image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: -1px;
  right: 0;
  z-index: 1;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(50%, rgba(32, 32, 32, 0)),
    color-stop(96%, #202020),
    to(#202020)
  );
  background: linear-gradient(
    to bottom,
    rgba(32, 32, 32, 0) 50%,
    #202020 96%,
    #202020 100%
  );
}
.hero__head {
  display: inline-block;
  padding: 40px 20px 30px;
  text-align: center;
  width: 100%;
  z-index: 1;
}
@media only screen and (min-width: 768px) {
  .hero__head {
    padding: 35px 20px 50px;
  }
}
.hero--tall .hero__head:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 66.66667%;
}
@media only screen and (min-width: 768px) {
  .hero--tall .hero__head:before {
    padding-top: 22%;
  }
}
@media screen and (min-width: 1440px) {
  .hero--tall .hero__head:before {
    padding-top: 20vw;
  }
}
.hero__subtitle {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.hero__bg-image .image__img {
  width: 100%;
}
.hero__title a {
  color: #fff;
}
.hero__title a:hover {
  color: #fff;
}

.icon-list--large {
  font-size: 18px;
}
.icon-list__item {
  position: relative;
  padding-left: 22px;
}
.icon-list__item + .icon-list__item {
  margin-top: 5px;
}
.icon-list--large .icon-list__item + .icon-list__item {
  margin-top: 10px;
}
.icon-list__icon {
  position: absolute;
  left: 0;
  top: 3px;
  color: #f58220;
  font-size: 12px;
}

.image__img.lazyload,
.image__img.lazyloading {
  opacity: 0;
  -webkit-transition: opacity 300ms ease-out;
  transition: opacity 300ms ease-out;
}
.image__img.lazyloaded {
  opacity: 1;
  -webkit-transition: opacity 300ms ease-out;
  transition: opacity 300ms ease-out;
}
.image--fluid .image__img {
  max-width: 100%;
  width: 100%;
  display: block;
  height: auto;
}

.languages {
  font-size: 11px;
}
@media only screen and (min-width: 1024px) {
  .languages {
    min-width: 110px;
  }
}
.languages__list {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 0 -6px;
  text-transform: uppercase;
}
@media only screen and (min-width: 1024px) {
  .languages__list {
    margin: 0 -12px;
  }
}
.languages__item {
  padding: 0 6px;
}
@media only screen and (min-width: 1024px) {
  .languages__item {
    padding: 0 10px;
  }
}
.languages__link {
  color: #888;
  line-height: 15px;
  border: none;
  position: relative;
}
.languages__item.is-current > .languages__link {
  color: #f58220;
  font-weight: 600;
}
.languages__item.is-current > .languages__link:before {
  content: "";
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: -14px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #000;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.languages__link:hover {
  color: #f58220;
  border: none;
}

.logo__link {
  border: none;
  display: block;
}
.logo__link:hover {
  border-bottom: none;
}
.logo {
  width: 100%;
  height: auto;
}
.logo__img {
  width: 110px;
  vertical-align: top;
}

.movie {
  text-align: center;
  padding-top: 48px;
}
.movie__header {
  position: relative;
  margin-bottom: 18px;
}
.movie__image {
  position: relative;
}
.movie__image:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 147.32143%;
}
.movie__image > .image__img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
}
.movie__image > .image__img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 3px;
}
.movie__title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: #fff;
}
@media only screen and (min-width: 1024px) {
  .movie__title {
    -webkit-transition-property: "color";
    transition-property: "color";
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    -webkit-transition-delay: 0ms;
    transition-delay: 0ms;
  }
}
.movie__link:hover .movie__title {
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
  color: #f58220;
}
.movie__original-title {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #fff;
}
@media only screen and (min-width: 1024px) {
  .movie__original-title {
    -webkit-transition-property: "color";
    transition-property: "color";
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    -webkit-transition-delay: 0ms;
    transition-delay: 0ms;
  }
}
.movie__link:hover .movie__original-title {
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
  color: #f58220;
}
.movie__icon {
  position: relative;
  z-index: 2;
  width: 70px;
  height: 70px;
  color: #f58220;
}
.movie__tag {
  position: absolute;
  top: -48px;
  left: 0;
  right: 0;
  z-index: 2;
}
.movie__tag-value {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  color: #000;
  z-index: 3;
}
.movie__tag-inner {
  display: inline-block;
  position: relative;
}
.movie__tag-inner:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-box-shadow: 0 0 40px 20px #000000;
  box-shadow: 0 0 40px 20px #000000;
  z-index: 1;
}

.navigation__list {
  position: relative;
  padding: 15px 0;
}
@media only screen and (min-width: 1025px) {
  .navigation__list {
    padding: 0;
    height: 100%;
  }
}
.navigation__item > .navigation__list {
  font-size: 14px;
}
@media only screen and (min-width: 1025px) {
  .navigation__item > .navigation__list.navigation__list--top {
    z-index: -1;
    padding: 40px;
    border-bottom: 2px solid #f58220;
  }
}
@media only screen and (min-width: 1025px) {
  .navigation__item > .navigation__list.navigation__list--user {
    padding: 28px 32px;
  }
}
@media only screen and (min-width: 1025px) {
  .navigation__item > .navigation__list.navigation__list--deep {
    display: block;
  }
}
@media only screen and (max-width: 1024px) {
  .navigation__item > .navigation__list:not(.navigation__list--deep) {
    padding: 0 0 0 20px;
  }
}
.navigation__list--user
  > .navigation__item
  > .navigation__link.navigation__sub-heading {
  font-size: 15px;
  width: 100%;
  text-align: left;
  font-weight: 400;
  line-height: 28px;
  white-space: nowrap;
}
@media only screen and (min-width: 1025px) {
  .navigation__list--top > .navigation__item {
    display: inline-block;
    padding: 0;
  }
  .navigation__item.is-megamenu > .navigation__list--top > .navigation__item {
    max-width: 160px;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 160px;
    flex: 1 0 160px;
    width: 100%;
    margin-bottom: 40px;
    margin-right: 20px;
  }
  .navigation__item.is-megamenu
    > .navigation__list--top
    > .navigation__item:not(:last-child):not(:nth-child(6)) {
    border-right: 2px solid rgba(255, 255, 255, 0.05);
  }
}
@media only screen and (min-width: 1025px) {
  .navigation__list--top > .navigation__item:not(:last-child) {
    padding-bottom: 10px;
  }
}
@media only screen and (min-width: 1025px) {
  .navigation__list--top.navigation__list--user
    > .navigation__item:not(:last-child) {
    padding-bottom: 2px;
  }
}
.navigation__list--deep {
  padding: 0;
}
.navigation__item.has-children {
  position: relative;
}
@media only screen and (min-width: 1024px) {
  .navigation__item.has-children {
    z-index: auto;
  }
}
.navigation__item.has-children:before {
  content: "";
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: -17px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #333;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  opacity: 0;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
  transition-delay: 0ms;
}
.navigation__item.has-children
  > .navigation__list.navigation__list--top.navigation__list--user {
  left: auto;
  right: 0;
  top: 50px;
  -webkit-transform: translateY(-100%) translateX(0%);
  transform: translateY(-100%) translateX(0%);
  z-index: -1;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}
.navigation__item.has-children.is-open:before {
  opacity: 1;
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.navigation__item.has-children.is-open
  > .navigation__list.navigation__list--top.navigation__list--user {
  visibility: visible;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}
.navigation__item {
  display: block;
  height: 100%;
}
@media screen and (min-width: 560px) and (max-width: 1024px) and (orientation: landscape) {
  .header__main .navigation__item > .navigation__link {
    padding: 14px 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .header__main .navigation__item > .navigation__link {
    padding: 21px 0 13px;
    height: 100%;
  }
}
@media only screen and (min-width: 1025px) {
  .navigation__list:not(.navigation__list--deep) > .navigation__item {
    display: inline-block;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1340px) {
  .header__navigation > .navigation__list > .navigation__item {
    padding: 0 7px;
  }
}
@media only screen and (min-width: 1341px) {
  .header__navigation > .navigation__list > .navigation__item {
    padding: 0 10px;
  }
}
@media only screen and (min-width: 1025px) {
  .navigation--top-nav > .navigation__list > .navigation__item {
    padding: 0 8px;
  }
}
.navigation__item.is-megamenu .navigation__item {
  height: auto;
}
@media only screen and (max-width: 1024px) {
  .navigation__item.has-children > .navigation__list {
    max-height: 0;
    overflow: hidden;
    -webkit-transition-property: max-height;
    transition-property: max-height;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    -webkit-transition-delay: 0ms;
    transition-delay: 0ms;
  }
}
.navigation__item.has-children .navigation__sub-heading {
  height: auto;
}
.navigation__item .is-megamenu .navigation__item {
  height: auto;
}
.navigation__item.has-children > .navigation__list--top {
  visibility: hidden;
}
@media only screen and (min-width: 1025px) {
  .navigation__item.has-children > .navigation__list--top {
    position: absolute;
    top: 60px;
    left: 50%;
    width: auto;
    height: auto;
    max-height: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    overflow: visible;
    -webkit-transform: translateY(-100%) translateX(-50%);
    transform: translateY(-100%) translateX(-50%);
    background-color: #333;
    -webkit-transition-property: visibility, -webkit-transform;
    transition-property: visibility, -webkit-transform;
    transition-property: transform, visibility;
    transition-property: transform, visibility, -webkit-transform;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    -webkit-transition-delay: 100ms;
    transition-delay: 100ms;
    -webkit-transition-delay: 0 100ms;
    transition-delay: 0 100ms;
  }
}
.navigation__item.is-megamenu > .navigation__list {
  pointer-events: none;
}
.navigation__item.is-megamenu.is-open > .navigation__list {
  pointer-events: all;
}
@media only screen and (min-width: 1025px) {
  .navigation__item.is-megamenu > .navigation__list--top {
    position: fixed;
    right: 0;
    left: 0;
    top: 100px;
    margin: 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    max-width: 1400px;
    z-index: -1;
    padding: 40px 40px 0 40px;
    border-bottom: 2px solid #f58220;
    -webkit-transform: translateY(-100%) translateX(0);
    transform: translateY(-100%) translateX(0);
  }
}
@media only screen and (min-width: 1400px) {
  .navigation__item.is-megamenu > .navigation__list--top {
    left: 50%;
    margin-left: -700px;
  }
}
.is-scrolled .navigation__item.is-megamenu > .navigation__list--top {
  top: 50px;
}
.navigation__item.has-children.is-open > .navigation__list {
  visibility: visible;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  max-height: 450px;
}
.navigation__item.has-children.is-open > .navigation__list--deep {
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  max-height: 200px;
}
@media only screen and (min-width: 1025px) {
  .navigation__item.has-children.is-open > .navigation__list--top {
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    -webkit-transform: translateY(0) translateX(-50%);
    transform: translateY(0) translateX(-50%);
    max-height: none;
  }
}
.navigation__item.is-megamenu.has-children.is-open > .navigation__list--top {
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}
.navigation__item:last-of-type {
  margin-right: 0;
}
.navigation__link {
  position: relative;
  display: block;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
  border: none;
  padding: 13px 20px;
}
@media only screen and (min-width: 1025px) {
  .navigation__link {
    padding: 0;
    display: inline-block;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1340px) {
  .navigation__link {
    font-size: 13px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1341px) {
  .navigation__link {
    font-size: 14px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1170px) {
  .navigation__link {
    padding: 0;
    display: inline-block;
  }
}
.navigation--top-nav .navigation__link {
  text-transform: none;
  color: #888;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0;
}
.navigation__list--deep .navigation__link {
  font-weight: 400;
}
@media only screen and (min-width: 1025px) {
  .navigation__list--deep .navigation__link {
    font-size: 13px;
    line-height: 18px;
  }
}
.navigation__item > .navigation__list .navigation__link {
  text-transform: none;
  padding: 8px 20px;
  letter-spacing: 0;
  line-height: 19px;
}
@media only screen and (min-width: 1025px) {
  .navigation__item > .navigation__list .navigation__link {
    padding: 0;
    line-height: 15px;
  }
}
.navigation__item
  > .navigation__list
  > .navigation__item.has-children
  > .navigation__link {
  color: #f58220;
}
.navigation__item.is-current > .navigation__link {
  color: #f58220;
}
.navigation--top-nav .navigation__item.is-current > .navigation__link {
  font-weight: 600;
}
.navigation--top-nav .navigation__item.is-current > .navigation__link:before {
  content: "";
  position: absolute;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #000;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -10px;
}
@media only screen and (min-width: 1025px) {
  .header__navigation
    > .navigation__list
    > .navigation__item.has-children
    > .navigation__link:before {
    content: "";
    position: absolute;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #333;
    -webkit-transform: translateX(-50%) translateY(100%);
    transform: translateX(-50%) translateY(100%);
    bottom: 0;
    opacity: 0;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    -webkit-transition-delay: 0ms;
    transition-delay: 0ms;
    transition-delay: 0ms;
  }
}
@media only screen and (min-width: 1025px) {
  .header__navigation
    > .navigation__list
    > .navigation__item.has-children.is-open
    > .navigation__link:before {
    opacity: 1;
    -webkit-transform: translateX(-50%) translateY(0);
    transform: translateX(-50%) translateY(0);
    -webkit-transition-delay: 100ms;
    transition-delay: 100ms;
  }
}
.navigation__item:hover > .navigation__link,
.navigation__link:hover {
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
  border: none;
  color: #f58220;
}
.navigation__item-dropdown {
  font-size: 8px;
  color: #fff;
  position: absolute;
  right: 20px;
  top: 15px;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
@media only screen and (min-width: 1025px) {
  .navigation__item-dropdown {
    position: relative;
    top: -1px;
    left: auto;
    right: auto;
    -webkit-transition-property: color, -webkit-transform;
    transition-property: color, -webkit-transform;
    transition-property: transform, color;
    transition-property: transform, color, -webkit-transform;
    -webkit-transition-duration: 600ms, 300ms;
    transition-duration: 600ms, 300ms;
    -webkit-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transition-delay: 100ms, 0ms;
    transition-delay: 100ms, 0ms;
  }
}
@media only screen and (min-width: 1025px) {
  .navigation__list--top .navigation__item-dropdown {
    display: none;
  }
}
.navigation__item
  > .navigation__list:not(.navigation__list--deep)
  .navigation__item-dropdown {
  color: #f58220;
}
.navigation__item.is-open > .navigation__item-dropdown {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
@media only screen and (min-width: 1025px) {
  .navigation__item:hover > .navigation__item-dropdown {
    -webkit-transition-duration: 600ms, 0ms;
    transition-duration: 600ms, 0ms;
    color: #f58220;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
.navigation__sub-level {
  margin-bottom: 46px;
  position: relative;
}
.navigation__sub-level:before {
  content: "";
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  border-right: 2px solid #fff;
  opacity: 0.05;
}
.navigation__sub-heading {
  letter-spacing: 0;
}
@media only screen and (min-width: 1025px) {
  .navigation__sub-heading {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
  }
}
@media only screen and (min-width: 1025px) {
  .is-megamenu .navigation__sub-heading {
    margin-bottom: 18px;
  }
}
.navigation__grid-item:nth-child(6) .navigation__sub-level:before {
  content: none;
}
.navigation__grid-item:last-of-type .navigation__sub-level:before {
  content: none;
}

.news__inner {
  color: #fff;
  display: block;
}
.news__inner:hover {
  color: #fff;
}
.news__date {
  margin-top: 0;
}
.news__image {
  position: relative;
  margin-bottom: 10px;
}
.news__image:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 50%;
}
.news__image > .image__img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
}
.news__image > .image__img {
  border-radius: 3px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.news__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}
.news__category {
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 14px;
  color: #f58220;
}
.news__inner:hover .news__title {
  color: #f58220;
}

.notification {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.notification--bordered {
  padding: 10px;
  border: 2px dotted #888;
}
.notification__icon {
  display: inline-block;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 32px;
  flex: 0 0 32px;
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin-right: 10px;
  max-width: 32px;
  font-size: 32px;
  color: #208af5;
}
.notification--success .notification__icon {
  color: #00b947;
}
.notification--small .notification__icon {
  font-size: 24px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 24px;
  flex: 0 0 24px;
  max-width: 24px;
}
.notification__text {
  display: inline-block;
  -ms-flex-preferred-size: calc(100% - 32px);
  flex-basis: calc(100% - 32px);
  max-width: calc(100% - 32px);
  vertical-align: top;
}
.notification--bordered .notification__text {
  font-weight: 600;
}
.notification--small .notification__text {
  font-size: 13px;
  -ms-flex-preferred-size: calc(100% - 24px);
  flex-basis: calc(100% - 24px);
  max-width: calc(100% - 24px);
}

.offer {
  position: relative;
  z-index: 1;
}
.offer__image {
  border-radius: 3px;
  overflow: hidden;
  position: relative;
}
.offer__image:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 66.66667%;
}
.offer__image > .image__picture {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .offer__image {
    position: relative;
  }
  .offer__image:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 84.74576%;
  }
  .offer__image > .image__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) {
  .offer__image {
    position: relative;
  }
  .offer__image:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 63.55932%;
  }
  .offer__image > .image__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
@media only screen and (min-width: 1170px) {
  .offer__image {
    position: relative;
  }
  .offer__image:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 50.84746%;
  }
  .offer__image > .image__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
.offer--large .offer__image {
  position: relative;
}
.offer--large .offer__image:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.offer--large .offer__image > .image__picture {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .offer--large .offer__image {
    position: relative;
  }
  .offer--large .offer__image:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 41.66667%;
  }
  .offer--large .offer__image > .image__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) {
  .offer--large .offer__image {
    position: relative;
  }
  .offer--large .offer__image:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 31.25%;
  }
  .offer--large .offer__image > .image__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
@media only screen and (min-width: 1170px) {
  .offer--large .offer__image {
    position: relative;
  }
  .offer--large .offer__image:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 25%;
  }
  .offer--large .offer__image > .image__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
.offer__image .image__picture {
  z-index: -1;
}
.offer__image .image__img {
  width: 100%;
  height: 100%;
}

.pagination__list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.pagination__item {
  display: inline-block;
}

.poster__image {
  position: relative;
}
.poster__image:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 148.75%;
}
.poster__image > .image__img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
}
.poster__image > .image__img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 3px;
}

.radio {
  position: relative;
}
.radio__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}
.radio__label {
  position: relative;
  display: inline-block;
  padding-left: 18px;
}
.choice-group__item .radio__label {
  display: block;
}
.radio__input:disabled ~ .radio__label {
  opacity: 0.5;
}
.radio--large .radio__label {
  padding-left: 28px;
}
.radio__text {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  vertical-align: middle;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
.choice-group__item:hover .radio__text,
.radio__label:hover .radio__text,
.radio__input:checked ~ .radio__label .radio__text {
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
  color: #f58220;
}
.radio__indicator {
  display: block;
  width: 12px;
  height: 12px;
  border: 3px solid #333;
  position: absolute;
  top: 4px;
  left: 0;
  background: transparent;
  border-radius: 50%;
}
.radio__input:checked ~ .radio__label .radio__indicator {
  background: #f58220;
}
.radio--squared .radio__indicator {
  border-radius: 0;
}
.radio--light .radio__indicator {
  border-color: #202020;
}
.radio--large .radio__indicator {
  width: 20px;
  height: 20px;
  border-width: 5px;
  background: #202020;
  top: 1px;
}

.schedule {
  padding: 16px 0;
}
@media only screen and (min-width: 768px) {
  .schedule {
    padding: 12px 0;
  }
}
.schedule__inner {
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .schedule__inner {
    padding: 12px 0;
  }
}
.schedule__inner--space-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 18px;
}
@media only screen and (min-width: 768px) {
  .schedule__inner--space-between {
    margin-top: 0;
  }
}
.schedule--favourite .schedule__inner--space-between {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
@media only screen and (min-width: 768px) {
  .schedule--favourite .schedule__inner--space-between {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 1024px) {
  .schedule--favourite .schedule__inner--space-between {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.schedule__inner--flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media only screen and (min-width: 768px) {
  .schedule__inner--flex {
    display: block;
  }
}
.schedule--search .schedule__title {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.schedule__purchase-btn {
  width: 74%;
}
@media only screen and (min-width: 768px) {
  .schedule__purchase-btn {
    width: unset;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1024px) {
  .schedule__purchase-btn {
    font-size: 13px;
  }
}
.schedule--favourite .schedule__purchase-btn {
  width: auto;
  min-width: 190px;
}
@media only screen and (min-width: 768px) {
  .schedule--favourite .schedule__purchase-btn {
    min-width: 0;
  }
}
@media only screen and (min-width: 768px) {
  .schedule__remove-btn {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 1024px) {
  .schedule__remove-btn {
    margin-right: 0;
    margin-top: 15px;
  }
}
.schedule__search-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  color: #888;
  margin-top: 12px;
}
@media only screen and (min-width: 1024px) {
  .schedule__search-meta {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
.schedule__search-meta-item {
  margin-top: 0;
}
.schedule__search-meta-item + .schedule__search-meta-item {
  margin-top: 12px;
}
@media only screen and (min-width: 1024px) {
  .schedule__search-meta-item + .schedule__search-meta-item {
    margin-top: 0;
    margin-left: 40px;
  }
}
.schedule__time {
  margin-right: 25px;
}
.schedule__original-title {
  color: #fff;
}
.schedule--search .schedule__original-title {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.schedule__genre {
  color: #888;
  margin-bottom: 12px;
  margin-top: 4px;
}
.schedule__meta-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -13px -13px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 260px;
}
@media only screen and (min-width: 1024px) {
  .schedule__graph {
    margin-right: 10px;
  }
}
.schedule__meta-box-item-value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  text-align: left;
}
.schedule__meta-box-item-value-icon {
  color: #f58220;
  margin-right: 3px;
  font-size: 12px;
}
.schedule__meta-box-item {
  color: #888;
  padding: 0 13px 13px;
}
.schedule__meta-box-item:first-of-type {
  margin-left: 0;
}
.schedule__meta-box-item:last-of-type {
  margin-right: 0;
}
.schedule__meta-wrapper {
  width: 50%;
}
@media only screen and (min-width: 768px) {
  .schedule__meta-wrapper {
    width: 100%;
  }
}
.schedule__location,
.schedule__room {
  color: #fff;
}
.schedule__trailer-btn {
  margin-right: 24px;
}
@media only screen and (min-width: 768px) {
  .schedule__trailer-btn {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 1024px) {
  .schedule__grid-poster {
    -ms-flex-preferred-size: 100px;
    flex-basis: 100px;
    max-width: 100px;
  }
}
@media only screen and (min-width: 1024px) {
  .schedule__grid-shrink {
    -ms-flex-negative: 2;
    flex-shrink: 2;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: calc(100% - 100px);
  }
}
.schedule__grid--vertical-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media only screen and (min-width: 1024px) {
  .schedule__grid--vertical-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.schedule__buttons-tablet {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.schedule__buttons-tablet > * {
  margin-top: 16px;
}
.schedule__buttons-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100%;
}
@media only screen and (min-width: 768px) {
  .schedule__buttons-list {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media only screen and (min-width: 1024px) {
  .schedule__buttons-list {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}
.schedule__buttons-list > * + * {
  margin-left: 8px;
}
@media only screen and (min-width: 768px) {
  .schedule__buttons-list > * + * {
    margin-left: 40px;
  }
}
.schedule__tag {
  margin-bottom: 10px;
}

.search-movie {
  margin-bottom: 48px;
}
@media only screen and (min-width: 1024px) {
  .search-movie {
    margin-bottom: 100px;
  }
}
.search-movie__title {
  margin: 24px 0;
}
@media only screen and (min-width: 768px) {
  .search-movie__title {
    margin: 40px 0;
  }
}
.search-movie__textfield {
  margin-bottom: 20px;
}
.search-movie__text {
  margin-bottom: 20px;
}
.search-movie__list-item {
  border-bottom: 2px solid #333;
}
.search-movie__list-item:first-child {
  border-top: 2px solid #333;
}

.textfield {
  position: relative;
  font-size: 15px;
  line-height: 22px;
  color: #fff;
  width: 100%;
  max-width: 100%;
}
.textfield.is-disabled {
  opacity: 0.5;
}
.textfield__inner {
  position: relative;
  /* padding: 24px 0 0; */
}
.textfield--search .textfield__inner {
  padding-top: 0;
}
.textfield--search-large .textfield__inner {
  overflow: hidden;
}
.textfield__required {
  color: #f58220;
  margin-left: 3px;
}
.textfield__input {
  border: 1px solid #444;
  border-radius: 3px;
  background: #202020;
  display: block;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  margin: 0;
  width: 100%;
  text-align: left;
  color: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 13px 12px;
  -webkit-transition-property: background;
  transition-property: background;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
  margin-top: 5px;
}
.textfield--with-icon .textfield__input {
  padding-left: 56px;
}
.textfield--light .textfield__input {
  background-color: #fff;
  color: #202020;
  border-color: #888;
}
.textfield.is-focused .textfield__input {
  outline: none;
  border-color: #333;
}
.textfield.is-invalid .textfield__input {
  border-color: red;
}
.textfield.is-disabled .textfield__input {
  background-color: transparent;
  outline: none;
}
.textfield--search .textfield__input {
  padding: 9px 10px 9px 46px;
  height: 46px;
  border-radius: 30px;
  color: #fff;
  background: #202020;
  border: none;
  font-weight: 400;
  font-size: 16px;
}
@media only screen and (min-width: 768px) {
  .textfield--search .textfield__input {
    height: 30px;
    padding: 9px 15px;
    font-size: 11px;
    background: #333;
  }
}
.textfield--search-large .textfield__input {
  padding: 14px 24px;
  border-radius: 25px;
  color: #fff;
  background: #333;
  border: none;
  font-weight: 400;
  font-size: 16px;
  height: auto;
}
@media only screen and (min-width: 768px) {
  .textfield--search-large .textfield__input {
    font-size: 18px;
  }
}
.textfield--center .textfield__input {
  text-align: center;
}
.textfield__input:hover {
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
}
.textfield--search .textfield__input:hover {
  background: #444;
}
.textfield__input::-ms-clear {
  display: none;
}
.textfield__label {
  bottom: 0;
  color: #888;
  font-size: 13px;
  line-height: 18px;
  left: 0;
  right: 0;
  pointer-events: none;
  /* position: absolute; */
  display: block;
  top: 0;
  width: 100%;
  /* overflow: hidden; */
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
html.no-js .textfield__label,
.textfield.is-focused .textfield__label,
.textfield.is-dirty .textfield__label {
  top: 0;
}
.textfield.is-focused .textfield__label {
  color: #888;
}
.textfield.is-invalid .textfield__label {
  color: red;
}
.textfield--search .textfield__label {
  color: #888;
  -webkit-transition: none;
  transition: none;
  top: 11px;
  left: 40px;
  font-size: 11px;
  line-height: 1;
}
@media only screen and (min-width: 768px) {
  .textfield--search .textfield__label {
    left: 15px;
  }
}
.textfield--search-large .textfield__label {
  font-size: 16px;
  left: 24px;
  top: 16px;
}
@media only screen and (min-width: 768px) {
  .textfield--search-large .textfield__label {
    font-size: 18px;
  }
}
.textfield--center .textfield__label {
  text-align: center;
}
.textfield.textfield--search.is-focused .textfield__label,
.textfield.textfield--search.is-dirty .textfield__label {
  opacity: 0;
}
.textfield__error {
  margin-top: 5px;
  color: red;
}
.textfield__description {
  margin-top: 5px;
}
.textfield__input-wrapper {
  position: relative;
}
.textfield__icon-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: 0;
  background: #333;
  height: 100%;
  width: 48px;
  border: 1px solid #444;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.textfield--search .textfield__icon {
  position: absolute;
  left: 15px;
  color: #fff;
  top: 13px;
  font-size: 20px;
  -webkit-transform: none;
  transform: none;
}
@media only screen and (min-width: 768px) {
  .textfield--search .textfield__icon {
    right: 10px;
    left: auto;
    top: 8px;
    font-size: 15px;
  }
}
.textfield--search-large .textfield__icon {
  position: absolute;
  left: auto;
  right: 22px;
  color: #ffffff;
  top: 16px;
  font-size: 15px;
  -webkit-transform: none;
  transform: none;
}
@media only screen and (min-width: 768px) {
  .textfield--search-large .textfield__icon {
    top: 18px;
  }
}

.select__icon {
  position: absolute;
  top: 19px;
  right: 15px;
  font-size: 12px;
  color: #f58220;
  pointer-events: none;
}
.select__icon > .icon {
  pointer-events: none;
}
.select__input {
  font-weight: 600;
}
.select__input::-ms-expand {
  display: none;
}
.select__input option {
  color: #444;
  font-weight: 400;
}

.separator {
  margin: 10px 0;
  border: none;
  background: transparent;
  padding: 0;
  height: 0;
}
@media only screen and (min-width: 768px) {
  .separator {
    margin: 24px 0;
  }
}
@media only screen and (min-width: 1024px) {
  .separator {
    margin: 32px 0;
  }
}
@media only screen and (min-width: 1170px) {
  .separator {
    margin: 40px 0;
  }
}
.separator--large {
  margin: 25px 0;
}
@media only screen and (min-width: 768px) {
  .separator--large {
    margin: 30px 0;
  }
}
@media only screen and (min-width: 1024px) {
  .separator--large {
    margin: 40px 0;
  }
}
@media only screen and (min-width: 1170px) {
  .separator--large {
    margin: 50px 0;
  }
}
.separator--line {
  border: none;
  margin: 13px 0 10px;
  border-bottom: 2px solid #333;
}
@media only screen and (min-width: 768px) {
  .separator--line {
    margin: 18px 0 30px;
  }
}

.session {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  border-radius: 4px;
  background: #111;
  padding: 3px 3px 18px;
  position: relative;
  height: 100%;
}
.session--large {
  padding: 3px 10px 3px 3px;
  height: 100%;
  text-align: left;
}
@media only screen and (min-width: 768px) {
  .session--large {
    padding-right: 16px;
  }
}
@media only screen and (min-width: 768px) {
  .session--large {
    padding-right: 20px;
  }
}
.session__grid {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) {
  .session__grid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
.session__purchase-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
}
.session__header {
  margin-top: 30px;
  padding: 0 15px;
}
.session--large .session__header {
  margin-top: 21px;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .session--large .session__header {
    margin-top: 12px;
  }
}
@media only screen and (min-width: 1170px) {
  .session--large .session__header {
    margin-top: 15px;
  }
}
.session--large .session__header--no-margin {
  margin-top: 7px;
}
@media only screen and (min-width: 768px) {
  .session--large .session__header--no-margin {
    margin-top: 12px;
  }
}
@media only screen and (min-width: 1170px) {
  .session--large .session__header--no-margin {
    margin-top: 15px;
  }
}
.session__footer {
  display: block;
  padding: 20px 5px 0;
}
@media only screen and (min-width: 768px) {
  .session__footer {
    padding: 16px 16px 0;
  }
}
@media only screen and (min-width: 1024px) {
  .session__footer {
    padding: 20px 17px 0;
  }
}
.session__content {
  width: 100%;
  margin-top: auto;
}
.session__image {
  position: relative;
}
.session__image:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 147.32143%;
}
.session__image > .image__img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
}
.session__image > .image__img {
  border-radius: 3px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
@media only screen and (min-width: 768px) {
  .session__image > .image__img {
    max-height: 100%;
  }
}
.session--large .session__image {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  max-height: 100%;
  height: 100%;
}
@media only screen and (min-width: 768px) {
  .session--large .session__image {
    width: 100%;
  }
}
.session--large .session__image > .image__img {
  border-radius: 3px;
  width: 100%;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  height: auto;
}
@media only screen and (min-width: 768px) {
  .session--large .session__image > .image__img {
    max-height: 100%;
  }
}
.session__title {
  display: inline-block;
}
@media only screen and (min-width: 768px) {
  .session__title {
    display: block;
  }
}
.session__original-title {
  font-weight: 400;
  display: inline-block;
  margin: 4px 0 8px;
}
@media only screen and (min-width: 768px) {
  .session__original-title {
    display: block;
  }
}
.session__genre {
  color: #888;
  font-weight: 600;
  margin-bottom: 12px;
  font-size: 11px;
  line-height: 15px;
  margin-top: 4px;
}
@media only screen and (min-width: 768px) {
  .session__genre {
    font-size: 13px;
    line-height: 18px;
  }
}
@media only screen and (min-width: 1024px) {
  .session__genre {
    margin-bottom: 17px;
  }
}
.session__meta-box {
  width: 100%;
  background-color: #202020;
  padding: 8px 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  margin: 24px 9px;
}
.session--large .session__meta-box {
  margin: 16px 0 0;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
@media only screen and (min-width: 768px) {
  .session--large .session__meta-box {
    margin: 20px 0;
  }
}
.session__meta-box-item-value {
  color: #fff;
  text-transform: uppercase;
  text-align: left;
  font-size: 11px;
  line-height: 15px;
  font-weight: 600;
  margin-top: 0;
}
@media only screen and (min-width: 768px) {
  .session__meta-box-item-value {
    font-size: 13px;
    line-height: 18px;
  }
}
.session__meta-box-item {
  color: #888;
  font-size: 13px;
  line-height: 18px;
  margin: 0 13px;
}
.session--large .session__meta-box-item {
  margin: 0 5px;
}
@media only screen and (min-width: 1170px) {
  .session--large .session__meta-box-item {
    margin: 0 8px;
  }
}
.session__tag {
  position: absolute;
  z-index: 2;
  margin-top: -14px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.session__tag--large {
  position: absolute;
  left: 50vw;
  top: -15px;
  margin-top: 0;
}
@media only screen and (min-width: 768px) {
  .session__tag--large {
    left: 50%;
  }
}
.session__cta {
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}
.session--large .session__grid-item {
  position: relative;
}
.session__grid-item:not(.session__grid-item--footer) {
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .session__grid-item:not(.session__grid-item--footer) {
    margin-bottom: 0;
  }
}
.session--large
  .session__grid-item:not(.session__grid-item--content):not(.session__grid-item--footer) {
  position: relative;
  min-width: 35%;
  max-width: 35%;
}
.session--large
  .session__grid-item:not(.session__grid-item--content):not(.session__grid-item--footer):before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 147.32143%;
}
.session--large
  .session__grid-item:not(.session__grid-item--content):not(.session__grid-item--footer)
  > .session__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
}
.session__grid-item--content {
  padding-left: 10px;
  width: 65%;
}
@media only screen and (min-width: 768px) {
  .session__grid-item--content {
    width: initial;
    padding-left: 20px;
  }
}
.session__grid-item--footer {
  width: 100%;
}
.session__times-icon {
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 18px;
}
@media only screen and (min-width: 768px) {
  .session__times-icon {
    display: block;
  }
}
@media only screen and (min-width: 1024px) {
  .session__times-icon {
    font-size: 24px;
    left: -4px;
  }
}
.session__times-list {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 8px 0 8px;
  position: relative;
  padding-left: 24px;
}
@media only screen and (min-width: 768px) {
  .session__times-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.session--large .session__times-list {
  margin-top: 0;
}
@media only screen and (min-width: 768px) {
  .session--large .session__times-list {
    margin-bottom: 12px;
    margin-top: 5px;
  }
}
.session__times-item-container {
  margin: 0 8px 10px 0;
  font-size: 14px;
  line-height: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media only screen and (min-width: 768px) {
  .session__times-item-container {
    font-size: 16px;
    line-height: 18px;
  }
}
@media only screen and (min-width: 1170px) {
  .session__times-item-container {
    font-size: 18px;
    line-height: 23px;
    margin-right: 15px;
    margin-bottom: 5px;
  }
}
.session__times-item {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: 0;
}
.session__times {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -20px;
}
.session__times-time {
  width: 100%;
  padding: 0 20px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .session__times-time {
    text-align: left;
  }
}
.session__times--mobile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.session__trailer-btn > .button__icon {
  color: #fff;
}
@media only screen and (min-width: 768px) {
  .session__trailer-btn > .button__icon {
    font-size: 20px;
    margin-top: -2px;
  }
}
.session__tooltip {
  display: inline-block;
  margin-left: 3px;
}
.session__tooltip .tooltip__icon-container {
  margin-top: 3px;
}

.snacks-picker__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px 0;
}
.snacks-picker__item:not(:last-child) {
  border-bottom: 2px solid #444;
}
.snacks-picker__title {
  display: block;
  margin-bottom: 15px;
}
.snacks-picker__content {
  display: inline-block;
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .snacks-picker__content {
    min-width: 500px;
  }
}
.snacks-picker__subtitle {
  margin-bottom: 15px;
}
.snacks-picker__group:not(:last-child) {
  margin-bottom: 30px;
}
@media only screen and (min-width: 768px) {
  .snacks-picker__group:not(:last-child) {
    margin-bottom: 35px;
  }
}
.snacks-picker__price {
  display: block;
  color: #f58220;
  margin-top: 4px;
}
@media only screen and (min-width: 768px) {
  .snacks-picker__price {
    min-width: 80px;
    margin-top: 0;
    text-align: right;
    display: inline-block;
  }
}
@media only screen and (min-width: 1024px) {
  .snacks-picker__price {
    min-width: 100px;
  }
}
.snacks-picker__actions {
  min-width: 100px;
  text-align: right;
}
@media only screen and (min-width: 768px) {
  .snacks-picker__actions {
    min-width: 145px;
  }
}
@media only screen and (min-width: 1024px) {
  .snacks-picker__actions {
    min-width: 175px;
  }
}
.snacks-picker__modal-content {
  text-align: center;
}
.snacks-picker__addform > * + * {
  margin-top: 20px;
}
.snacks-picker__addform .number {
  margin: auto;
}

.steps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow: hidden;
}
.steps__item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  font-size: 13px;
  color: #888;
  z-index: auto;
}
@media only screen and (min-width: 768px) {
  .steps__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}
.steps__item:first-child {
  border-radius: 9px 0 0 9px;
  padding-left: 0;
}
.steps__item:last-child {
  border-radius: 0 9px 9px 0;
  padding-right: 0;
}
.steps__item.is-active {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
@media only screen and (min-width: 768px) {
  .steps__item.is-active {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}
.steps__item:not(:first-child):before {
  content: "";
  position: absolute;
  display: block;
  top: 5px;
  left: -24px;
  width: 30px;
  height: 30px;
  border-right: 2px solid #202020;
  border-bottom: 2px solid #202020;
  background: #000;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.steps__item.is-active:after {
  content: "";
  position: absolute;
  display: block;
  top: -10px;
  right: -5px;
  width: 60px;
  height: 60px;
  -webkit-box-shadow: 2px 2px 0 #202020;
  box-shadow: 2px 2px 0 #202020;
  background: #f58220;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  z-index: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@media only screen and (min-width: 1024px) {
  .steps__item.is-active:after {
    right: -10px;
  }
}
.steps__item:last-child.is-active:after {
  display: none;
}
.steps__item.is-active ~ .steps__item:before {
  background: #333;
}
.steps__item.is-active + .steps__item:before {
  display: none;
}
.steps__item-inner {
  background: #000;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 25px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}
@media only screen and (min-width: 1024px) {
  .steps__item-inner {
    padding-left: 40px;
  }
}
.steps__item:first-child .steps__item-inner {
  padding-left: 12px;
  border-radius: 3px 0 0 3px;
  border-left: 1px solid #000;
}
@media only screen and (min-width: 1024px) {
  .steps__item:first-child .steps__item-inner {
    padding-left: 20px;
    border-radius: 6px 0 0 6px;
  }
}
.steps__item:last-child .steps__item-inner {
  border-radius: 0 3px 3px 0;
  border-right: 1px solid #000;
}
@media only screen and (min-width: 1024px) {
  .steps__item:last-child .steps__item-inner {
    border-radius: 0 6px 6px 0;
  }
}
.steps__item.is-active .steps__item-inner {
  background: #f58220;
  border-color: #f58220;
  color: #fff;
  padding-left: 35px;
  margin-left: -10px;
  margin-right: 10px;
  font-weight: 600;
}
@media only screen and (min-width: 1024px) {
  .steps__item.is-active .steps__item-inner {
    padding-left: 50px;
  }
}
.steps__item:first-child.is-active .steps__item-inner {
  padding-left: 12px;
  margin-left: 0;
}
@media only screen and (min-width: 1024px) {
  .steps__item:first-child.is-active .steps__item-inner {
    padding-left: 20px;
  }
}
.steps__item:last-child.is-active .steps__item-inner {
  margin-right: 0;
}
.steps__item.is-active ~ .steps__item .steps__item-inner {
  background: #333;
  border-color: #393939;
  padding-left: 25px;
}
@media only screen and (min-width: 1024px) {
  .steps__item.is-active ~ .steps__item .steps__item-inner {
    padding-left: 40px;
  }
}
.steps__item.is-active + .steps__item .steps__item-inner {
  padding-left: 35px;
  margin-left: -10px;
}
@media only screen and (min-width: 1024px) {
  .steps__item.is-active + .steps__item .steps__item-inner {
    padding-left: 50px;
  }
}
.steps__item-text {
  z-index: 2;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .steps__item-text:before {
    display: none;
  }
}
.steps__item:not(.is-active):nth-child(1) .steps__item-text:before {
  content: "1";
}
.steps__item:not(.is-active):nth-child(2) .steps__item-text:before {
  content: "2";
}
.steps__item:not(.is-active):nth-child(3) .steps__item-text:before {
  content: "3";
}
.steps__item:not(.is-active):nth-child(4) .steps__item-text:before {
  content: "4";
}
.steps__item:not(.is-active) .steps__item-text-string {
  display: none;
}
@media only screen and (min-width: 768px) {
  .steps__item:not(.is-active) .steps__item-text-string {
    display: inline-block;
  }
}

.tag {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #fff;
  background: #f58220;
  padding: 7px 14px;
  border-radius: 15px;
  line-height: 1;
  font-weight: 600;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  vertical-align: middle;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}
.tag--square {
  padding: 3px 6px 4px;
  border-radius: 3px;
  font-size: 11px;
  line-height: 13px;
}
.tag--disabled {
  background: #202020;
  color: #f58220;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.tag--inverse {
  background: #fff;
  color: #393939;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.tag--small {
  text-transform: uppercase;
  font-size: 9px;
  line-height: 13px;
  padding: 4px 5px 2px 5px;
  border-radius: 3px;
}
.tag__icon {
  font-size: 14px;
  margin-right: 8px;
}

.textarea__input {
  display: block;
  resize: none;
  height: 120px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 13px 12px;
}

.thumbnail {
  position: relative;
  border-radius: 3px;
}
.thumbnail__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  width: 29%;
  height: 29%;
  z-index: 2;
  color: #fff;
  pointer-events: none;
}
.thumbnail__image {
  position: relative;
  background: #202020;
  border-radius: 3px;
}
.thumbnail__image:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.69291%;
}
.thumbnail__image > .image__img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
}
.thumbnail__image > img {
  border-radius: 3px;
}
.thumbnail__image > img:hover {
  opacity: 0.65;
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
}

.ticket-picker {
  padding: 3px;
  border-radius: 4px;
  background-color: #111;
  -webkit-box-shadow: 0 15px 30px 0 rgba(255, 255, 255, 0.05);
  box-shadow: 0 15px 30px 0 rgba(255, 255, 255, 0.05);
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .ticket-picker {
    margin-bottom: 40px;
  }
}
.ticket-picker__row-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ticket-picker__row {
  position: relative;
  padding: 10px 10px 14px;
  margin: 0 -3px;
}
.ticket-picker__row:not(:first-child) {
  border-top: 1px solid #202020;
}
.ticket-picker__row:not(:last-child) {
  border-bottom: 1px solid #202020;
}
.ticket-picker--events .ticket-picker__row {
  padding: 10px 20px;
}
.ticket-picker__button {
  margin-left: auto;
  font-size: 13px;
}
.ticket-picker__options {
  border-bottom: 4px solid #f58220;
  border-radius: 3px;
}
.ticket-picker__meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 14px;
}
.ticket-picker__meta-item-value {
  color: #fff;
  text-align: left;
}
.ticket-picker__meta-item:first-child .ticket-picker__meta-item-value {
  font-weight: 600;
}
.ticket-picker__meta-item {
  color: #888;
  margin: 0 8px;
}
.ticket-picker__meta-item:first-of-type {
  margin-left: 0;
}
.ticket-picker__meta-item:last-of-type {
  margin-right: 0;
}
.ticket-picker__upcoming {
  position: relative;
  background-color: #000;
  text-align: center;
  padding: 30px 30px 45px;
  border-radius: 4px;
}
.ticket-picker__strip {
  position: absolute;
  left: 6px;
  top: 5px;
  height: calc(100% - 8px);
  width: 9px;
  background-image: radial-gradient(
    circle closest-side,
    #202020 2.3px,
    transparent 2.8px,
    transparent 5.3px
  );
  background-repeat: repeat-y;
  background-size: 10px 9px;
}
.ticket-picker--events .ticket-picker__strip {
  background-image: radial-gradient(
    circle closest-side,
    #202020 2.3px,
    transparent 2.8px,
    transparent 5.1px
  );
  background-size: 10px 10px;
  left: 5px;
  width: 7px;
}
.ticket-picker:not(.ticket-picker--events)
  .ticket-picker__results
  .ticket-picker__strip {
  display: none;
}
.ticket-picker__strip--end {
  left: auto;
  right: 5px;
}
.ticket-picker--events .ticket-picker__strip--end {
  left: auto;
  right: 7px;
}
.ticket-picker__upcoming-icon {
  color: #f58220;
  font-size: 20px;
  margin-bottom: 14px;
}
.ticket-picker__date-label {
  font-weight: 400;
}
.ticket-picker__date {
  font-weight: 600;
}
.ticket-picker__graph {
  margin-left: 10px;
}
@media only screen and (min-width: 768px) {
  .ticket-picker__graph {
    margin-left: 20px;
  }
}
.ticket-picker__time {
  font-size: 26px;
}
.ticket-picker__dropdown {
  margin-bottom: 2px;
}
.ticket-picker__title {
  margin: 5px 0 18px;
}
.ticket-picker__extra {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8px;
}
.ticket-picker__extra-icon-container {
  background: #208af5;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ticket-picker__extra-icon {
  font-size: 9px;
}
.ticket-picker__extra-text {
  margin-left: 6px;
}

.timer {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 26px;
  line-height: 30px;
  font-weight: 600;
}
.timer--small {
  background-color: #f58220;
  padding: 3px 4px;
  border-radius: 3px;
  font-size: 8px;
  line-height: 12px;
}
@media only screen and (min-width: 1170px) {
  .timer--small {
    padding: 4px;
    font-size: 11px;
    line-height: 13px;
  }
}
.timer__icon {
  margin-right: 6px;
  color: #f58220;
  font-size: 20px;
}
.timer--small .timer__icon {
  margin-right: 3px;
  color: #fff;
  font-size: 10px;
}
@media only screen and (min-width: 1170px) {
  .timer--small .timer__icon {
    font-size: 11px;
  }
}

.checkout {
  padding-top: 10px;
  padding-bottom: 80px;
}
@media only screen and (min-width: 768px) {
  .checkout {
    padding-top: 24px;
  }
}
@media only screen and (min-width: 1024px) {
  .checkout {
    padding-top: 40px;
    padding-bottom: 100px;
  }
}
.checkout__head {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: none;
}
@media only screen and (min-width: 768px) {
  .checkout__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.checkout--step-5 .checkout__head {
  display: block;
  border: none;
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .checkout--step-5 .checkout__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
.checkout__title {
  font-size: 26px;
  font-weight: 600;
}
.checkout__nav {
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .checkout__nav {
    margin-bottom: 40px;
  }
}
.checkout__step-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 15px;
}
@media only screen and (min-width: 768px) {
  .checkout__step-title {
    margin-bottom: 35px;
  }
}
.checkout__snacks-title {
  margin-bottom: 20px;
}
.checkout__step-bubble {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 24px;
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  font-size: 13px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #f58220;
  border-radius: 50%;
  margin-right: 8px;
}
@media only screen and (min-width: 768px) {
  .checkout__step-bubble {
    font-size: 15px;
  }
}
.checkout__step-number {
  line-height: 1;
  margin-top: 1px;
}
.checkout__cart-item {
  padding: 0 0 40px;
}
.checkout__cart-item.graph {
  padding: 0;
}
.checkout__step {
  margin-bottom: 30px;
}
.checkout__step--notification {
  margin-bottom: 0;
}
.checkout__step--background {
  background-color: #333;
  padding: 20px 20px 40px;
}
.checkout__step--email {
  padding: 20px;
}
@media only screen and (min-width: 768px) {
  .checkout__step--email {
    padding: 20px 20px 40px;
  }
}
.checkout__step--narrow {
  max-width: 800px;
  margin: 0 auto;
}
@media only screen and (min-width: 768px) {
  .checkout__step--offset {
    padding-left: 140px;
  }
}
.checkout__grid-row {
  padding: 20px 0 0;
}
@media only screen and (min-width: 768px) {
  .checkout__grid-row {
    padding: 20px 0;
  }
}
.checkout__grid-row:last-child {
  padding-bottom: 0;
}
.checkout--step-2 .checkout__grid-row {
  padding: 0;
}
.checkout__step-row {
  display: block;
  text-align: center;
  padding: 10px 0;
}
.checkout__seats {
  max-width: 770px;
  margin: auto;
}
.checkout__sum {
  font-size: 22px;
  font-weight: 600;
  display: block;
  line-height: 1;
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .checkout__sum {
    margin-top: 0;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 1024px) {
  .checkout__sum {
    font-size: 26px;
  }
}
.checkout--step-5 .checkout__sum {
  margin-top: 0;
  margin-bottom: 30px;
}
@media only screen and (min-width: 768px) {
  .checkout--step-5 .checkout__sum {
    margin-bottom: 20px;
  }
}
.checkout--step-3 .checkout__sum {
  margin-top: 30px;
}
@media only screen and (min-width: 768px) {
  .checkout--step-3 .checkout__sum {
    margin-top: 0;
  }
}
.checkout__sum-number {
  color: #f58220;
}
.checkout__button {
  margin-top: 20px;
}
@media only screen and (min-width: 768px) {
  .checkout__button {
    margin: 0;
  }
}
@media only screen and (min-width: 768px) {
  .checkout__button + .checkout__button {
    margin-left: 10px;
  }
}
@media only screen and (min-width: 768px) {
  .checkout__button--pay {
    margin-left: 8px;
  }
}
.checkout__payment-button {
  margin: 5px 0;
}
@media only screen and (min-width: 700px) {
  .checkout__payment-button {
    margin: 5px;
  }
}
.checkout__payments {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 auto;
}
@media only screen and (min-width: 1220px) {
  .checkout__payments {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
.checkout__payments .button--bank-wider {
  margin-left: 5px;
  margin-right: 5px;
}
.checkout__payments + .checkout__payments {
  margin-bottom: 0;
}
.checkout__payments--banks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}
@media only screen and (min-width: 1220px) {
  .checkout__payments--banks {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}
.checkout__payments--banks .button--bank {
  width: 100%;
}
.checkout__payments--banks .button--bank-swedbank {
  margin-right: 5px;
}
.checkout__payments--banks .button--bank-seb {
  margin-left: 5px;
}
.checkout__payments--banks .button--bank-luminor {
  margin-right: 5px;
}
.checkout__payments--banks .button--bank-lhv {
  margin-left: 5px;
}
@media only screen and (min-width: 1220px) {
  .checkout__payments--banks-last {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}
.checkout__notification {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
  margin: auto;
  clear: both;
}
.checkout__step--notification .checkout__notification {
  margin: 0 0 20px;
}
.checkout__notification--success {
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .checkout__purchase-title {
    display: inline-block;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
    padding-left: 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .checkout__purchase-title {
    -ms-flex-preferred-size: calc(100% - 210px);
    flex-basis: calc(100% - 210px);
    max-width: calc(100% - 210px);
  }
}
.checkout__purchase {
  background-color: #333;
  border-radius: 3px;
  padding: 15px;
  font-size: 26px;
  font-weight: 600;
  display: none;
}
.checkout__purchase a {
  color: #fff;
}
@media only screen and (min-width: 768px) {
  .checkout__purchase {
    display: inline-block;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media only screen and (min-width: 1024px) {
  .checkout__purchase {
    -ms-flex-preferred-size: 210px;
    flex-basis: 210px;
    max-width: 210px;
  }
}
.checkout__purchase-number {
  color: #fff;
}
.checkout__purchase-label {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 10px;
}
.checkout__ad-image .image__img {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .checkout__email-field {
    max-width: 400px;
    margin: 0 auto;
  }
}

.footer {
  background: #000;
  color: #fff;
  padding-bottom: 60px;
  margin-top: auto;
}
.footer__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 60px;
  margin: 0 auto 60px;
  width: 120px;
  height: auto;
}
.footer__grid {
  margin-left: -5px;
  margin-right: -5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: -52px;
}
@media only screen and (min-width: 768px) {
  .footer__grid {
    margin-left: -6px;
    margin-right: -6px;
  }
}
@media only screen and (min-width: 1024px) {
  .footer__grid {
    margin-left: -8px;
    margin-right: -8px;
  }
}
@media only screen and (min-width: 1170px) {
  .footer__grid {
    margin-left: -10px;
    margin-right: -10px;
  }
}
@media only screen and (min-width: 768px) {
  .footer__grid {
    padding-bottom: 24px;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 768px) {
  .footer__grid {
    padding-bottom: 0;
  }
}
.footer__grid-item {
  padding-left: 5px;
  padding-right: 5px;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
  max-width: 50%;
  margin-bottom: 30px;
}
@media only screen and (min-width: 768px) {
  .footer__grid-item {
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media only screen and (min-width: 1024px) {
  .footer__grid-item {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media only screen and (min-width: 1170px) {
  .footer__grid-item {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media only screen and (min-width: 768px) {
  .footer__grid-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 25%;
    flex: 0 1 25%;
    max-width: 25%;
    margin-bottom: -24px;
  }
}
@media only screen and (min-width: 1024px) {
  .footer__grid-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 20%;
    flex: 0 1 20%;
    max-width: 20%;
  }
}
.footer__subitem-wrapper {
  margin-bottom: 24px;
}
.footer__social-item--tablet {
  display: block;
}
@media only screen and (min-width: 1024px) {
  .footer__social-item--tablet {
    display: none;
  }
}
.footer__subtitle {
  margin-bottom: 16px;
}
.footer__text {
  color: #888;
}
.footer__subitem-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 11px;
  line-height: 16px;
  color: #888;
}
@media only screen and (min-width: 768px) {
  .footer__subitem-item {
    font-size: 13px;
    line-height: 18px;
  }
}
.footer__subitem-item--w-icon {
  margin-bottom: 18px;
}
.footer__subitem-link {
  color: #888;
  border-bottom: none;
}
.footer__subitem-link:hover {
  color: #f58220;
  border-bottom: none;
}
.footer__subitem-item--w-icon .footer__subitem-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.footer__subitem-icon {
  color: #fff;
  margin-right: 10px;
  font-size: 30px;
}
.footer__subitem-wrapper--icon {
  margin-bottom: 15px;
}

.form {
  margin-bottom: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  .form {
    margin-bottom: 100px;
  }
}
.form--apply {
  margin-top: 30px;
}
@media only screen and (min-width: 768px) {
  .form--apply {
    margin-top: 35px;
  }
}
@media only screen and (min-width: 1170px) {
  .form--apply {
    margin-top: 45px;
  }
}
.form__inner {
  padding: 20px 10px 10px 10px;
  background: #333;
  border-radius: 3px;
  max-width: 800px;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .form__inner {
    padding: 40px;
  }
}
.form__section {
  width: 100%;
}
.form__section + .form__section {
  margin-top: 40px;
}
.form__section-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 40px;
  text-align: center;
}
.form__field.textfield textarea,
.form__field.textfield input {
  border: 1px solid #444;
}
.form__field.textfield textarea:focus,
.form__field.textfield input:focus {
  border-color: #888;
}
.form__field .radio__indicator,
.form__field .check__indicator {
  border-color: #202020;
}
.form__item + .form__item {
  margin-top: 20px;
}
.form__item .choice-group__label {
  color: #888;
}
.form__submit-button {
  margin-top: 34px;
}
.form__section-bubble {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 24px;
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #f58220;
  border-radius: 50%;
  margin-right: 8px;
}
.form__number {
  line-height: 1;
  margin-top: 1px;
}

.gift-card__text {
  color: #888;
  text-align: left;
}
.gift-card__field {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  color: #888;
}
.gift-card__textfield {
  max-width: 100%;
}
@media only screen and (min-width: 768px) {
  .gift-card__textfield {
    max-width: 400px;
  }
}
.gift-card__button {
  margin-top: 9px;
  margin-left: 14px;
}
@media only screen and (min-width: 480px) {
  .gift-card__button {
    margin-left: 11px;
  }
}
@media only screen and (min-width: 768px) {
  .gift-card__button {
    margin-left: 18px;
  }
}
.gift-card__textfield .textfield__inner {
  padding-top: 10px;
}

.gift-ticket {
  background-color: #111;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.gift-ticket__inner {
  padding: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.gift-ticket__image {
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.gift-ticket__image:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.gift-ticket__image > .image__img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
}
.gift-ticket__image .image__img {
  vertical-align: top;
}
.gift-ticket__content {
  padding: 20px 10px 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.gift-ticket__description {
  padding: 0 14px;
}
@media only screen and (min-width: 1024px) {
  .gift-ticket__description {
    padding: 0;
  }
}
.gift-ticket__disclaimer {
  font-weight: 600;
  padding: 20px 24px 0;
  margin-top: auto;
}
@media only screen and (min-width: 768px) {
  .gift-ticket__disclaimer {
    padding: 20px 14px 0;
  }
}
@media only screen and (min-width: 1024px) {
  .gift-ticket__disclaimer {
    padding-left: 0;
    padding-right: 0;
  }
}
.gift-ticket__quantity {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}
.gift-ticket__price {
  margin-top: 18px;
}
@media only screen and (min-width: 768px) {
  .gift-ticket__price {
    margin-top: 16px;
  }
}
.gift-ticket__add {
  margin-top: 26px;
}

.history {
  position: relative;
  padding: 35px 0 56px;
}
@media only screen and (min-width: 768px) {
  .history {
    padding: 44px 0 56px;
  }
}
@media only screen and (min-width: 1024px) {
  .history {
    padding: 48px 0 56px;
  }
}
@media only screen and (min-width: 1170px) {
  .history {
    padding: 54px 0 56px;
  }
}
.history__grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -2.5px;
}
@media only screen and (min-width: 768px) {
  .history__grid {
    margin: -3px;
  }
}
@media only screen and (min-width: 1024px) {
  .history__grid {
    margin: -4px;
  }
}
@media only screen and (min-width: 1170px) {
  .history__grid {
    margin: -5px;
  }
}
.history__grid--simple {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow: auto;
  margin-right: -12px;
  margin-left: -12px;
  position: relative;
  -webkit-overflow-scrolling: touch;
}
.history__grid--simple:after {
  content: "";
  padding-right: 24px;
}
.history__grid--simple:before {
  content: "";
  padding-left: 24px;
}
@media only screen and (min-width: 768px) {
  .history__grid--simple {
    margin-right: -24px;
    margin-left: -24px;
  }
}
@media only screen and (min-width: 1170px) {
  .history__grid--simple {
    -ms-flex-wrap: unset;
    flex-wrap: unset;
    overflow: hidden;
  }
}
.history__grid--side {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
@media only screen and (min-width: 768px) {
  .history__grid--side {
    margin-right: -6px;
    margin-left: -6px;
  }
}
@media only screen and (min-width: 1024px) {
  .history__grid--side {
    margin-right: -8px;
    margin-left: -8px;
  }
}
@media only screen and (min-width: 1170px) {
  .history__grid--side {
    margin-right: -10px;
    margin-left: -10px;
  }
}
.history__grid-item {
  display: none;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66%;
  flex: 0 0 16.66%;
  max-width: 16.66%;
  padding: 2.5px;
}
.history__grid-item:nth-child(-n + 6) {
  display: block;
}
@media only screen and (min-width: 768px) {
  .history__grid-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
    padding: 3px;
  }
  .history__grid-item:nth-child(-n + 10) {
    display: block;
  }
}
@media only screen and (min-width: 1024px) {
  .history__grid-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66%;
    flex: 0 0 16.66%;
    max-width: 16.66%;
    padding: 4px;
  }
  .history__grid-item:nth-child(-n + 12) {
    display: block;
  }
}
@media only screen and (min-width: 1170px) {
  .history__grid-item {
    padding: 5px;
  }
}
.history__grid--simple .history__grid-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  max-width: 40%;
}
@media only screen and (min-width: 768px) {
  .history__grid--simple .history__grid-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
  }
}
@media only screen and (min-width: 1024px) {
  .history__grid--simple .history__grid-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 100%;
    flex: 0 1 100%;
    max-width: unset;
  }
}
.history__grid-item.history__grid-item--recommended {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33%;
  flex: 0 0 33.33%;
  max-width: 33.33%;
}
@media only screen and (min-width: 768px) {
  .history__grid-item.history__grid-item--recommended {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    display: none;
  }
  .history__grid-item.history__grid-item--recommended:nth-child(-n + 4) {
    display: block;
  }
}
@media only screen and (min-width: 1024px) {
  .history__grid-item.history__grid-item--recommended {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
    max-width: 33%;
  }
  .history__grid-item.history__grid-item--recommended:nth-child(-n + 6) {
    display: block;
  }
}
.history__grid-item--side-first {
  padding-left: 5px;
  padding-right: 5px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
@media only screen and (min-width: 768px) {
  .history__grid-item--side-first {
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media only screen and (min-width: 1024px) {
  .history__grid-item--side-first {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media only screen and (min-width: 1170px) {
  .history__grid-item--side-first {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media only screen and (min-width: 768px) {
  .history__grid-item--side-first {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }
}
@media only screen and (min-width: 1024px) {
  .history__grid-item--side-first {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.history__grid-item--side-second {
  padding-left: 5px;
  padding-right: 5px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
@media only screen and (min-width: 768px) {
  .history__grid-item--side-second {
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media only screen and (min-width: 1024px) {
  .history__grid-item--side-second {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media only screen and (min-width: 1170px) {
  .history__grid-item--side-second {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media only screen and (min-width: 768px) {
  .history__grid-item--side-second {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }
}
@media only screen and (min-width: 1024px) {
  .history__grid-item--side-second {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.history__title {
  position: relative;
  z-index: 2;
  text-align: center;
  margin-bottom: 24px;
}
@media only screen and (min-width: 768px) {
  .history--expanded .history__title {
    text-align: left;
  }
}
.history__inner {
  position: relative;
  overflow: hidden;
}
.history__inner-item:first-of-type {
  border-bottom: 2px solid rgba(255, 255, 255, 0.05);
  padding-bottom: 30px;
  margin-bottom: 0;
}
@media only screen and (min-width: 768px) {
  .history__inner-item:first-of-type {
    border-bottom: none;
    padding-right: 42px;
    padding-bottom: 0;
    border-right: 2px solid rgba(255, 255, 255, 0.05);
  }
}
.history__inner-item + .history__inner-item {
  padding-top: 20px;
}
@media only screen and (min-width: 768px) {
  .history__inner-item + .history__inner-item {
    border-bottom: none;
    padding-left: 36px;
    padding-top: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .history__inner-item + .history__inner-item {
    border-bottom: none;
    padding-left: 30px;
  }
}
.history__background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: #333;
}
@media only screen and (min-width: 1024px) {
  .history__background {
    height: 100%;
    width: 50%;
  }
}
.history__background > .image__img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  opacity: 0.7;
}
@media only screen and (min-width: 768px) {
  .history__background > .image__img {
    opacity: 1;
  }
}
@media only screen and (min-width: 1024px) {
  .history--expanded .history__background {
    height: 100%;
    width: 100%;
  }
}

.job-offer {
  background-color: #111;
  padding: 3px;
}
@media only screen and (min-width: 768px) {
  .job-offer__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
  }
}
.job-offer__image {
  border-radius: 3px;
  overflow: hidden;
}
@media only screen and (min-width: 768px) {
  .job-offer__image {
    position: relative;
    max-width: 240px;
    -ms-flex-preferred-size: 240px;
    flex-basis: 240px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
  }
  .job-offer__image:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 65%;
  }
  .job-offer__image > .image__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) {
  .job-offer__image {
    max-width: 400px;
    -ms-flex-preferred-size: 400px;
    flex-basis: 400px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
}
.job-offer__image .image__img {
  vertical-align: top;
}
@media only screen and (min-width: 768px) {
  .job-offer__image .image__img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
@media only screen and (min-width: 1024px) {
  .job-offer__image .image__img {
    height: auto;
    -o-object-fit: none;
    object-fit: none;
  }
}
@media only screen and (min-width: 768px) {
  .job-offer__image .image__picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
@media only screen and (min-width: 1024px) {
  .job-offer__image .image__picture {
    height: auto;
  }
}
.job-offer__content {
  padding: 20px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
@media only screen and (min-width: 768px) {
  .job-offer__content {
    padding: 15px 24px;
  }
}
@media only screen and (min-width: 1024px) {
  .job-offer__content {
    padding: 0 35px;
  }
}

.jobs-list {
  margin-bottom: 30px;
}
@media only screen and (min-width: 768px) {
  .jobs-list {
    margin-bottom: 60px;
  }
}
@media only screen and (min-width: 1170px) {
  .jobs-list {
    margin-bottom: 80px;
  }
}
.jobs-list__title {
  margin-bottom: 30px;
}
@media only screen and (min-width: 768px) {
  .jobs-list__title {
    margin-bottom: 40px;
  }
}

.movie-details {
  position: relative;
  padding-bottom: 80px;
}
@media only screen and (min-width: 1024px) {
  .movie-details {
    padding-bottom: 90px;
  }
}
.movie-details--upcoming {
  padding-top: 25px;
}
@media only screen and (min-width: 768px) {
  .movie-details--upcoming {
    padding-top: 60px;
  }
}
.movie-details__inner {
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
@media only screen and (min-width: 768px) {
  .movie-details__inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
.movie-details__grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media only screen and (max-width: 767px) {
  .movie-details__main {
    margin-bottom: 30px;
  }
}
.movie-details__bg-image {
  position: relative;
  z-index: -1;
  position: relative;
}
.movie-details__bg-image:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.movie-details__bg-image > .image__picture {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
}
@media screen and (min-width: 560px) and (orientation: landscape) {
  .movie-details__bg-image {
    position: relative;
  }
  .movie-details__bg-image:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 33.33333%;
  }
  .movie-details__bg-image > .image__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .movie-details__bg-image {
    position: relative;
  }
  .movie-details__bg-image:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 33.33333%;
  }
  .movie-details__bg-image > .image__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) {
  .movie-details__bg-image {
    position: relative;
  }
  .movie-details__bg-image:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 33.33333%;
  }
  .movie-details__bg-image > .image__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
.movie-details__bg-image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: -1px;
  right: 0;
  z-index: 1;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(50%, rgba(32, 32, 32, 0)),
    color-stop(96%, #202020),
    to(#202020)
  );
  background: linear-gradient(
    to bottom,
    rgba(32, 32, 32, 0) 50%,
    #202020 96%,
    #202020 100%
  );
}
.movie-details__bg {
  top: 0;
  width: 100%;
  position: relative;
}
.movie-details--upcoming .movie-details__bg {
  position: absolute;
}
.movie-details__aside {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
@media only screen and (min-width: 768px) {
  .movie-details__aside {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
}
.movie-details__title {
  margin-bottom: 5px;
}
.movie-details .poster__image {
  margin-bottom: 15px;
}
@media only screen and (min-width: 1024px) {
  .movie-details .poster__image {
    margin-bottom: 30px;
  }
}
.movie-details .movie-details__aside .poster__image {
  display: none;
}
@media only screen and (min-width: 768px) {
  .movie-details .movie-details__aside .poster__image {
    display: block;
  }
}
.movie-details__thumblist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -2px 26px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.movie-details__thumblist-item {
  width: 100%;
  -ms-flex-preferred-size: calc(100% / 3);
  flex-basis: calc(100% / 3);
  max-width: calc(100% / 3);
  padding: 0 2px 4px;
  display: inline-block;
}
@media only screen and (min-width: 1024px) {
  .movie-details__thumblist-item {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
}
.movie-details__thumblist-title {
  margin-bottom: 10px;
}
.movie-details__head {
  margin-bottom: 15px;
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
}
@media only screen and (min-width: 768px) {
  .movie-details__head {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 1024px) {
  .movie-details__head {
    margin-bottom: 60px;
  }
}
.movie-details--upcoming .movie-details__head {
  position: relative;
  text-align: left;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .movie-details--upcoming .movie-details__head {
    margin-bottom: 30px;
  }
}
.movie-details__poster {
  display: block;
  width: 35%;
  float: left;
  margin-right: 20px;
}
@media only screen and (min-width: 768px) {
  .movie-details__poster {
    margin-right: 24px;
  }
}
.movie-details__subtitle {
  margin-bottom: 10px;
  font-size: 13px;
}
@media only screen and (min-width: 768px) {
  .movie-details__subtitle {
    font-size: 16px;
    margin-bottom: 24px;
  }
}
@media only screen and (min-width: 1024px) {
  .movie-details__subtitle {
    font-size: 18px;
  }
}
.movie-details__social {
  padding-bottom: 8px;
}
.movie-details__social .movie-details__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.movie-details__list-label {
  margin-bottom: 3px;
}
.movie-details__rating-icon {
  color: #f58220;
  font-size: 14px;
}
.movie-details__floating {
  margin-bottom: 38px;
}
@media only screen and (min-width: 768px) {
  .movie-details__floating {
    padding: 0 10px;
  }
}
.movie-details__content {
  margin-top: 35px;
}
.movie-details__list-item {
  display: block;
  width: 100%;
  margin-bottom: 15px;
}
@media only screen and (min-width: 1024px) {
  .movie-details__list-item--half {
    display: block;
    width: 50%;
    float: left;
  }
}
@media only screen and (min-width: 1024px) {
  .movie-details__list-item--half:nth-child(even) {
    float: right;
  }
}
.movie-details__button:not(:last-child) {
  margin-right: 16px;
}
.movie-details__button--trailer {
  margin-bottom: 16px;
}
@media only screen and (min-width: 768px) {
  .movie-details__button--trailer {
    margin-bottom: 0;
  }
}
.movie-details__button .button__icon {
  font-size: 16px;
}
.movie-details__button--trailer .button__icon {
  font-size: 20px;
}
.movie-details__bg-image .image__img {
  width: 100%;
}
.movie-details__notification {
  margin: 0 0 20px 12px;
}
.movie-details__notification .notification__icon {
  -ms-flex-item-align: auto;
  align-self: auto;
}

.movie-list {
  background: #000;
  padding: 64px 0 60px;
  text-align: center;
  overflow: hidden;
}
.movie-list__title {
  margin-bottom: 42px;
  font-weight: 600;
}
.movie-list__list {
  margin-left: -10px;
  margin-right: -10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow: auto;
  padding-bottom: 24px;
  -webkit-overflow-scrolling: touch;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
@media only screen and (min-width: 768px) {
  .movie-list__list {
    margin-left: -24px;
    margin-right: -24px;
  }
}
@media only screen and (min-width: 1024px) {
  .movie-list__list {
    overflow: hidden;
  }
}
.movie-list__item {
  padding-left: 5px;
  padding-right: 5px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  max-width: 40%;
}
@media only screen and (min-width: 768px) {
  .movie-list__item {
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media only screen and (min-width: 1024px) {
  .movie-list__item {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media only screen and (min-width: 1170px) {
  .movie-list__item {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.movie-list__item:first-of-type {
  border-left: 10px solid transparent;
}
@media only screen and (min-width: 768px) {
  .movie-list__item:first-of-type {
    border-left: 24px solid transparent;
  }
}
.movie-list__item:last-of-type {
  border-right: 10px solid transparent;
}
@media only screen and (min-width: 768px) {
  .movie-list__item:last-of-type {
    border-right: 24px solid transparent;
  }
}
@media only screen and (min-width: 768px) {
  .movie-list__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
  }
}
@media only screen and (min-width: 1024px) {
  .movie-list__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 20%;
    flex: 0 1 20%;
    max-width: 20%;
  }
}

.news-list {
  padding: 54px 0 80px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .news-list {
    padding: 54px 0 50px;
  }
}
@media only screen and (min-width: 1024px) {
  .news-list {
    padding: 54px 0 36px;
  }
}
@media only screen and (min-width: 1170px) {
  .news-list {
    padding: 84px 0 80px;
  }
}
.news-list__title {
  margin-bottom: 20px;
  font-weight: 600;
}
@media only screen and (min-width: 768px) {
  .news-list__title {
    margin-bottom: 36px;
  }
}

.order-confirmation__item {
  margin-bottom: 10px;
  padding-bottom: 20px;
  border-bottom: 2px solid #333;
}
@media only screen and (min-width: 768px) {
  .order-confirmation__item {
    margin-bottom: 20px;
  }
}
.checkout--step-3 .order-confirmation__item:last-child {
  border-bottom: none;
}
.order-confirmation__item-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.order-confirmation__item-poster {
  width: 60px;
  margin-right: 10px;
}
@media only screen and (min-width: 768px) {
  .order-confirmation__item-poster {
    width: 120px;
    margin-right: 24px;
  }
}
.order-confirmation__notification {
  margin-top: 20px;
  max-width: 700px;
}
.order-confirmation__item-poster .image__img {
  height: auto;
}
.order-confirmation__item-icon {
  color: #888;
  margin-right: 8px;
}
.order-confirmation--large .order-confirmation__item-icon {
  font-size: 20px;
}
.order-confirmation-checkout__item-data {
  width: 100%;
}
.order-confirmation__item-title,
.order-confirmation__item-location,
.order-confirmation__item-time {
  display: inline-block;
  margin-bottom: 5px;
  color: #fff;
}
.order-confirmation__item-meta-item-label {
  color: #888;
}
.order-confirmation__item-location,
.order-confirmation__item-time,
.order-confirmation__item-title,
.order-confirmation__item-location,
.order-confirmation__item-time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.order-confirmation--large .order-confirmation__item-time {
  margin-bottom: 11px;
}
.order-confirmation__item-title--original {
  font-weight: 400;
  margin-left: 5px;
}
.order-confirmation__item-meta {
  margin-top: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.order-confirmation__list {
  display: table;
  margin-top: 10px;
  width: 100%;
  border-spacing: 0 2px;
}
.order-confirmation__list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}
.order-confirmation__list-item + .order-confirmation__list-item {
  margin-top: 2px;
}
.order-confirmation--large .order-confirmation__list-item {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
@media only screen and (min-width: 768px) {
  .order-confirmation__list-item {
    display: table-row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.order-confirmation__list-item-seat {
  display: inline-block;
  margin-right: 10px;
}
.order-confirmation__list-item-seat:last-child {
  margin-right: 0;
}
.order-confirmation__list-cell {
  min-height: auto;
  padding: 10px;
  display: block;
  width: 39%;
  background: #333;
  vertical-align: middle;
  font-size: 13px;
  line-height: 18px;
}
@media only screen and (min-width: 768px) {
  .order-confirmation__list-cell {
    height: 50px;
    padding: 0 14px;
    width: 33.33%;
    display: table-cell;
    white-space: nowrap;
    font-size: 15px;
    line-height: 22px;
  }
  .order-confirmation__list-cell:first-of-type {
    white-space: normal;
  }
}
.order-confirmation__list-cell:first-child {
  border-radius: 3px 0 0 0;
}
@media only screen and (min-width: 768px) {
  .order-confirmation__list-cell:first-child {
    border-radius: 3px 0 0 3px;
  }
}
.order-confirmation__list-cell:nth-child(3) {
  border-radius: 0 3px 0 0;
}
@media only screen and (min-width: 768px) {
  .order-confirmation__list-cell:nth-child(3) {
    border-radius: 0;
  }
}
.order-confirmation__list-cell:last-child {
  border-radius: 0 0 3px 3px;
}
@media only screen and (min-width: 768px) {
  .order-confirmation__list-cell:last-child {
    border-radius: 0 3px 3px 0;
  }
}
.order-confirmation--large .order-confirmation__list-cell {
  min-height: 50px;
  width: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 5px;
}
.order-confirmation--large .order-confirmation__list-cell:first-child {
  padding-left: 10px;
}
.order-confirmation--large .order-confirmation__list-cell:last-child {
  padding-right: 10px;
}
@media only screen and (min-width: 768px) {
  .order-confirmation--large .order-confirmation__list-cell {
    padding: 0 14px;
    display: table-cell;
    -ms-flex-wrap: unset;
    flex-wrap: unset;
  }
}
.order-confirmation__item-time-date {
  color: #f58220;
  margin-left: 28px;
}
@media only screen and (min-width: 768px) {
  .order-confirmation__item-time-date {
    margin-left: 20px;
  }
}
@media only screen and (min-width: 768px) {
  .order-confirmation__list-cell--label {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .order-confirmation--large .order-confirmation__list-cell--label {
    width: auto;
  }
}
.order-confirmation__list-cell--price {
  width: 22%;
  text-align: right;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  .order-confirmation__list-cell--price {
    width: 33.33%;
  }
}
.order-confirmation__list-cell--buttons {
  width: 100%;
  padding-top: 0;
}
@media only screen and (min-width: 768px) {
  .order-confirmation__list-cell--buttons {
    display: table-cell;
    width: auto;
    white-space: nowrap;
  }
}
.order-confirmation--large .order-confirmation__list-cell--buttons {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: right;
  white-space: nowrap;
  font-size: 12px;
  color: #888;
}
@media only screen and (min-width: 768px) {
  .order-confirmation--large .order-confirmation__list-cell--buttons {
    display: table-cell;
  }
}
.checkout--step-4
  .order-confirmation--large
  .order-confirmation__list-cell--buttons {
  display: none;
}
.order-confirmation__button + .order-confirmation__button {
  margin-left: 8px;
}
@media only screen and (min-width: 768px) {
  .order-confirmation__button + .order-confirmation__button {
    margin-left: 10px;
  }
}
.order-confirmation--large .order-confirmation__button {
  background: none;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .order-confirmation--large .order-confirmation__button {
    padding: 8px 20px;
  }
}
.order-confirmation--large .order-confirmation__button > .icon {
  color: #888;
  font-size: 14px;
}
.order-confirmation--large .order-confirmation__button .button__wrapper-inner {
  color: #888;
}

.premiere-list {
  margin: 42px 0 18px;
}
@media only screen and (min-width: 768px) {
  .premiere-list {
    margin: 55px 0 64px;
  }
}
@media only screen and (min-width: 1024px) {
  .premiere-list {
    margin: 58px 0 60px;
  }
}
@media only screen and (min-width: 1170px) {
  .premiere-list {
    margin: 62px 0 100px;
  }
}
.premiere-list__title {
  margin-bottom: 18px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .premiere-list__title {
    margin-bottom: 45px;
  }
}
@media only screen and (min-width: 1024px) {
  .premiere-list__title {
    margin-bottom: 53px;
  }
}
@media only screen and (min-width: 1170px) {
  .premiere-list__title {
    margin-bottom: 50px;
  }
}

.profile {
  padding-top: 13px;
  margin-bottom: 40px;
}
@media only screen and (min-width: 768px) {
  .profile {
    padding-top: 16px;
    margin-bottom: 80px;
  }
}
@media only screen and (min-width: 1024px) {
  .profile {
    padding-top: 40px;
    margin-bottom: 100px;
  }
}
.profile__title {
  margin: 15px 0 24px;
}
@media only screen and (min-width: 1024px) {
  .profile__title {
    margin: 0;
  }
}
.profile__title + :not(.text):not(.grid):not(.profile__progress-list) {
  margin-top: 36px;
}
.profile-tickets__list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}
.profile-tickets__list-item + .profile-tickets__list-item {
  margin-top: 2px;
}
.profile-tickets--large .profile-tickets__list-item {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
@media only screen and (min-width: 768px) {
  .profile-tickets__list-item {
    display: table-row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.profile-history__item-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.profile-history__list {
  display: table;
  margin-top: 10px;
  width: 100%;
  border-spacing: 0 2px;
}
.profile-tickets__list-cell {
  min-height: auto;
  padding: 10px;
  display: block;
  width: 33.33%;
  background: #333;
  vertical-align: middle;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .profile-tickets__list-cell {
    text-align: left;
    height: 50px;
    padding: 0 14px;
    width: 33.33%;
    display: table-cell;
    white-space: nowrap;
    font-size: 15px;
    line-height: 22px;
  }
  .profile-tickets__list-cell:first-of-type {
    white-space: normal;
  }
}
.profile-tickets__list-cell.profile-tickets__list-cell--price {
  text-align: right;
}
@media only screen and (min-width: 768px) {
  .profile-tickets__list-cell.profile-tickets__list-cell--price {
    text-align: left;
  }
}
.profile-tickets__list-cell.profile-tickets__list-cell--label {
  font-weight: 600;
  text-align: left;
}
.profile-history__item
  .profile-tickets__list-cell.profile-tickets__list-cell--label {
  font-weight: 400;
}
@media only screen and (min-width: 768px) {
  .profile-tickets__list-cell.profile-tickets__list-cell--label {
    width: 100%;
  }
}
.profile-tickets__list-cell.profile-tickets__list-cell--buttons {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .profile-tickets__list-cell.profile-tickets__list-cell--buttons {
    width: 33.33%;
  }
}
.profile-tickets__list-cell:first-child {
  border-radius: 3px 3px 0 0;
}
@media only screen and (min-width: 768px) {
  .profile-tickets__list-cell:first-child {
    border-radius: 3px 0 0 3px;
  }
}
.profile-tickets__list-cell:nth-child(3) {
  border-radius: 0;
}
@media only screen and (min-width: 768px) {
  .profile-tickets__list-cell:nth-child(3) {
    border-radius: 0;
  }
}
.profile-tickets__list-cell:last-child {
  border-radius: 0 0 3px 3px;
}
@media only screen and (min-width: 768px) {
  .profile-tickets__list-cell:last-child {
    border-radius: 0 3px 3px 0;
  }
}
.profile-tickets--large .profile-tickets__list-cell {
  min-height: 50px;
  width: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 5px;
}
.profile-tickets--large .profile-tickets__list-cell:first-child {
  padding-left: 10px;
}
.profile-tickets--large .profile-tickets__list-cell:last-child {
  padding-right: 10px;
}
@media only screen and (min-width: 768px) {
  .profile-tickets--large .profile-tickets__list-cell {
    padding: 0 14px;
    display: table-cell;
    -ms-flex-wrap: unset;
    flex-wrap: unset;
  }
}
.profile-tickets__list {
  border-spacing: 0 2px;
  margin-top: 20px;
}
.profile-tickets__list-item-seat {
  display: inline-block;
  margin-right: 10px;
}
.profile-tickets__list-item-seat:last-child {
  margin-right: 0;
}
.profile-tickets__button {
  min-width: 100%;
  padding: 8px 20px;
}
@media only screen and (min-width: 768px) {
  .profile-tickets__button {
    min-width: auto;
  }
}
.profile-tickets__button + .profile-tickets__button {
  margin-left: 8px;
}
@media only screen and (min-width: 768px) {
  .profile-tickets__button + .profile-tickets__button {
    margin-left: 10px;
  }
}
.profile-tickets--large .profile-tickets__button {
  background: none;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .profile-tickets--large .profile-tickets__button {
    padding: 8px 20px;
  }
}
.profile-tickets--large .profile-tickets__button > .icon {
  color: #888;
  font-size: 14px;
}
.profile-tickets--large .profile-tickets__button .button__wrapper-inner {
  color: #888;
}
.profile__sub-title {
  margin-bottom: 20px;
}
.profile-history__item {
  padding: 20px 0;
  border-bottom: 2px solid #333;
}
.profile-history__item:first-child {
  border-top: 2px solid #333;
}
.profile-history__item-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.profile-history__item-poster {
  width: 60px;
  margin-right: 10px;
}
.profile-history__item-poster > .image__img {
  border-radius: 3px;
  width: 100%;
  height: auto;
}
@media only screen and (min-width: 768px) {
  .profile-history__item-poster {
    width: 120px;
    margin-right: 24px;
  }
}
.profile-tickets__list-cell--label {
  width: 100%;
}
.profile-history__item-title,
.profile-history__item-location,
.profile-history__item-time {
  display: inline-block;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  font-weight: 600;
}
@media only screen and (min-width: 600px) {
  .profile-history__item-title,
  .profile-history__item-location,
  .profile-history__item-time {
    font-size: 16px;
    line-height: 20px;
  }
}
@media only screen and (min-width: 1200px) {
  .profile-history__item-title,
  .profile-history__item-location,
  .profile-history__item-time {
    font-size: 18px;
    line-height: 22px;
  }
}
.profile-tickets__list-cell--full {
  width: 66.67%;
  text-align: left;
}
@media only screen and (min-width: 1024px) {
  .profile-tickets__list-cell--full {
    width: 33.33%;
  }
}
.profile-history__item-icon {
  color: #888;
  margin-right: 8px;
}
.profile-history--large .profile-history__item-icon {
  font-size: 20px;
}
.profile-history__item-title--original {
  font-size: 13px;
  font-weight: 400;
  margin-left: 5px;
}
@media only screen and (min-width: 768px) {
  .profile-history__item-title--original {
    font-size: 15px;
  }
}
.profile-history__item-location,
.profile-history__item-time,
.profile-history__item-title,
.profile-history__item-location,
.profile-history__item-time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.profile-tickets__list-cell--ticket {
  width: 66.67%;
  text-align: left;
}

.schedule-list {
  margin: 18px 0 40px;
}
@media only screen and (min-width: 768px) {
  .schedule-list {
    margin: 24px 0 64px;
  }
}
@media only screen and (min-width: 1024px) {
  .schedule-list {
    margin: 32px 0 100px;
  }
}
.schedule-list--favourites {
  padding-top: 40px;
}
.schedule-list__item {
  border-bottom: 2px solid #333;
}
.schedule-list__item:last-of-type {
  border-bottom: none;
}
.schedule-list--title .schedule-list__item {
  border-top: 2px solid #333;
  margin-top: -2px;
}
.schedule-list--title .schedule-list__item:last-of-type {
  border-bottom: 2px solid #333;
}
.schedule-list__title + .schedule-list__item {
  border-top: 2px solid #333;
}
.schedule-list__title {
  margin-bottom: 20px;
  text-align: center;
}
.schedule-list__section {
  border-bottom: 2px solid #333;
}
.schedule-list--title .schedule-list__section {
  border-bottom: none;
}
.schedule-list__section + .schedule-list__section {
  margin-top: 68px;
}
.schedule-list--favourites .schedule-list__items {
  margin-top: 18px;
}
@media only screen and (min-width: 768px) {
  .schedule-list--favourites .schedule-list__items {
    margin-top: 24px;
  }
}
@media only screen and (min-width: 1024px) {
  .schedule-list--favourites .schedule-list__items {
    margin-top: 32px;
  }
}

.session-list {
  margin: 20px 0 42px;
}
@media only screen and (min-width: 768px) {
  .session-list {
    margin: 16px 0 56px;
  }
}
@media only screen and (min-width: 1024px) {
  .session-list {
    margin: 16px 0 60px;
  }
}
@media only screen and (min-width: 1170px) {
  .session-list {
    margin: 20px 0 64px;
  }
}
.session__item {
  margin-bottom: 20px;
}

.sidebar-navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #111;
  border-radius: 4px;
  padding: 3px;
  -webkit-box-shadow: 0 15px 30px rgba(255, 255, 255, 0.05);
  box-shadow: 0 15px 30px rgba(255, 255, 255, 0.05);
}
.sidebar-navigation__person {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border-radius: 3px;
  border-bottom: 4px solid #f58220;
}
.sidebar-navigation__nav {
  padding: 14px 0;
  position: relative;
}
.sidebar-navigation__nav:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 30px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(17, 17, 17, 0)),
    to(#111)
  );
  background: linear-gradient(to right, rgba(17, 17, 17, 0) 0%, #111 100%);
}
@media only screen and (min-width: 768px) {
  .sidebar-navigation__nav {
    padding: 40px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .sidebar-navigation__nav:after {
    content: none;
  }
}
.sidebar-navigation__person-id {
  margin-top: 19px;
  margin-bottom: 18px;
}
.sidebar-navigation__person-image {
  position: relative;
  margin-bottom: 20px;
}
.sidebar-navigation__person-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 25px;
  line-height: 18px;
}
.sidebar-navigation__tag {
  position: absolute;
  top: 5px;
  right: 0;
}
@media only screen and (min-width: 768px) {
  .sidebar-navigation__tag {
    top: 0;
  }
}
.sidebar-navigation__nav-list {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}
@media only screen and (min-width: 768px) {
  .sidebar-navigation__nav-list {
    white-space: normal;
    overflow: hidden;
  }
}
.sidebar-navigation__nav-item {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 14px;
  line-height: 30px;
  white-space: nowrap;
  margin-left: 24px;
}
.sidebar-navigation__nav-item:last-child {
  margin-right: 24px;
}
@media only screen and (min-width: 768px) {
  .sidebar-navigation__nav-item {
    display: block;
    font-size: 16px;
    margin-left: 0;
  }
  .sidebar-navigation__nav-item:last-child {
    margin-right: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .sidebar-navigation__nav-item {
    font-size: 18px;
    line-height: 32px;
    margin-left: 0;
  }
}
.sidebar-navigation__nav-link {
  position: relative;
  color: #fff;
}
.sidebar-navigation__nav-item.is-current > .sidebar-navigation__nav-link {
  color: #f58220;
}
.sidebar-navigation__nav-link--w-tag {
  padding-right: 26px;
}

body {
  overflow-y: scroll;
  background: #202020;
  font-size: 11px;
  line-height: 15px;
}
@media only screen and (min-width: 600px) {
  body {
    font-size: 13px;
    line-height: 18px;
  }
}
#page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}
body.is-scroll-disabled #page {
  overflow-y: hidden;
  position: fixed;
  width: 100%;
}
#page:before {
  content: "";
  background-color: rgba(32, 32, 32, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  pointer-events: all;
  -webkit-transition-property: opacity, visibility;
  transition-property: opacity, visibility;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
@media only screen and (min-width: 1024px) {
  #page:before {
    display: none;
  }
}
body.is-search-open #page:before,
body.is-menu-open #page:before {
  opacity: 1;
  visibility: visible;
}
.h-container {
  padding: 0 10px;
  width: 100%;
  max-width: 1448px;
  margin: 0 auto;
}
@media only screen and (min-width: 768px) {
  .h-container {
    padding: 0 24px;
  }
}
@media only screen and (min-width: 1170px) {
  .h-container {
    max-width: 1460px;
    padding: 0 30px;
  }
}
.h-clearfix:after {
  content: "";
  display: table;
  clear: both;
}
.h-pull-left {
  float: left;
}
.h-pull-right {
  float: right;
}
.h-hidden {
  display: none !important;
}
.h-visible {
  display: block !important;
}
.h-visiblility-hidden {
  visibility: hidden;
}
@media only screen and (max-width: 767px) {
  .h-hidden-xs {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .h-hidden-sm {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1169px) {
  .h-hidden-md {
    display: none !important;
  }
}
@media only screen and (min-width: 1170px) and (max-width: 1299px) {
  .h-hidden-lg {
    display: none !important;
  }
}
@media only screen and (min-width: 1300px) {
  .h-hidden-xl {
    display: none !important;
  }
}
.h-flex-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.accordion__item + .accordion__item {
  margin-top: 2px;
}
.accordion__header {
  position: relative;
  display: block;
  padding: 10px 20px 10px;
  background: transparent;
}
.accordion__content {
  display: none;
}
html.no-js .accordion__content,
.accordion__item.is-open > .accordion__content {
  display: block;
}
.accordion__inner {
  padding: 10px 20px 30px;
}

.cookie {
  position: fixed;
  z-index: 100;
  bottom: 20px;
  width: 100%;
  -webkit-transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22),
    -webkit-transform 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22),
    -webkit-transform 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: transform 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22),
    opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: transform 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22),
    opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22),
    -webkit-transform 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.cookie.js-closed {
  opacity: 0;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  pointer-events: none;
}
.cookie__inner {
  width: 100%;
  background: #fff;
  color: #000;
  padding: 19px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
}
.cookie__text {
  display: inline;
}
.cookie__close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  text-decoration: underline;
  font-weight: 600;
  padding-left: 20px;
}
.cookie__close-icon {
  color: #f58220;
  margin-left: 9px;
}
.cookie__link {
  text-decoration: underline;
  font-weight: 600;
  white-space: nowrap;
}

.graph {
  width: 24px;
  height: 24px;
  background-color: #333;
  border-radius: 50%;
}
.graph__wrapper {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #888;
}
.graph__label {
  margin-left: 10px;
}
.graph__circle .graph__mask,
.graph__circle .graph__fill {
  width: 24px;
  height: 24px;
  position: absolute;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -webkit-transition: -webkit-transform 300ms;
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
}
.graph__mask {
  clip: rect(0, 24px, 24px, 12px);
}
.graph__mask .graph__fill {
  clip: rect(0, 12px, 24px, 0);
  background-color: #f58220;
}
.graph__inset {
  width: 16px;
  height: 16px;
  position: absolute;
  margin-left: 4px;
  margin-top: 4px;
  background-color: #202020;
  border-radius: 50%;
}
.ticket-picker__results .graph__inset {
  background-color: #111;
}

.map {
  height: 300px;
}

.modal-container {
  position: relative;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  background: rgba(32, 32, 32, 0.8);
}
.modal-container.is-visible {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.modal-container__inner {
  max-width: 800px;
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: auto;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: auto;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
}
.modal-container--full .modal-container__inner {
  padding: 0;
  max-width: 100%;
}
.modal {
  display: none;
  position: relative;
  padding: 40px 20px;
  background: #fff;
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  color: #000;
}
@media only screen and (min-width: 768px) {
  .modal {
    padding: 40px;
  }
}
.modal-container .modal {
  display: block;
  pointer-events: auto;
}
.modal--gallery {
  width: 100%;
  padding: 0;
}
.modal--small {
  max-width: 400px;
  margin: 0 auto;
  border: 2px solid #fff;
  background: #333;
  color: #fff;
  border-radius: 3px;
}
.modal--small-side {
  padding: 0;
  max-width: 800px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border: 2px solid #fff;
}
@media only screen and (min-width: 768px) {
  .modal--small-side {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
}
.modal-container .modal--small-side {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.modal--large-side {
  padding: 0;
  max-width: 800px;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) {
  .modal--large-side {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
.modal-container .modal--large-side {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
@media only screen and (min-width: 768px) {
  .modal-container .modal--large-side {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
.modal__image {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  max-width: 100%;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .modal__image {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
  }
}
@media only screen and (min-width: 768px) {
  .modal--large-side .modal__image {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
  }
}
.modal__image-text {
  position: absolute;
  top: 0;
  left: 0;
  padding: 30px;
  color: #fff;
}
.modal__content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  max-width: 100%;
  padding: 10px;
}
@media only screen and (min-width: 768px) {
  .modal__content {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 60%;
    flex: 1 1 60%;
    max-width: 60%;
    padding: 30px;
  }
}
@media only screen and (min-width: 1024px) {
  .modal__content {
    padding: 40px;
  }
}
.modal--small-side .modal__content {
  padding: 20px 10px;
}
@media only screen and (min-width: 768px) {
  .modal--small-side .modal__content {
    padding: 40px;
  }
}
@media only screen and (min-width: 768px) {
  .modal--large-side .modal__content {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    max-width: 40%;
  }
}
.modal__close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: 0;
  padding: 0;
  outline: none;
  color: #f58220;
  font-size: 15px;
  cursor: pointer;
  z-index: 2;
}
@media only screen and (min-width: 1024px) {
  .modal__close {
    -webkit-transition-property: "color";
    transition-property: "color";
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    -webkit-transition-delay: 0ms;
    transition-delay: 0ms;
  }
}
.modal__close:hover {
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
  color: #ffa427;
}
.modal--gallery .modal__close {
  display: none;
}
.modal__image .image,
.modal__image .image__picture {
  width: 100%;
  height: 100%;
}
.modal--small-side .image__img {
  border-radius: 3px 0 0 3px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.modal__club-flag {
  position: absolute;
  top: 0;
  left: 10px;
  background-color: #f58220;
  padding: 5px;
  line-height: 1;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 2px;
}
@media only screen and (min-width: 768px) {
  .modal__club-flag {
    left: 30px;
  }
}
.modal__flag-icon {
  font-size: 22px;
}
.modal__login-row {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  .modal__login-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
.modal__login-col:first-child {
  margin-bottom: 5px;
}
@media only screen and (min-width: 768px) {
  .modal__login-col:first-child {
    margin-bottom: 0;
  }
}
.modal__login-footer {
  text-align: center;
  margin-top: 20px;
}
@media only screen and (min-width: 768px) {
  .modal__login-footer {
    margin-top: 30px;
  }
}
.modal__login-form .textfield + .textfield {
  margin-top: 20px;
}
.modal__login-title {
  max-width: 80%;
}

.tabs {
  position: relative;
}
.tabs__nav {
  padding-bottom: 20px;
  margin: 0;
  width: 100%;
  max-width: 100%;
  overflow-y: hidden;
}
.tabs__nav:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 10px;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(32, 32, 32, 0)),
    to(#202020)
  );
  background: linear-gradient(to left, rgba(32, 32, 32, 0) 0%, #202020 100%);
  pointer-events: none;
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
.tabs__nav:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 80px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(32, 32, 32, 0)),
    to(#202020)
  );
  background: linear-gradient(to right, rgba(32, 32, 32, 0) 0%, #202020 100%);
  pointer-events: none;
}
.tabs__nav.is-scrolled:before {
  opacity: 1;
}
.tabs__nav-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap;
}
.tabs__nav-item {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  padding: 0;
  width: 80px;
  padding-right: 20px;
}
@media only screen and (min-width: 768px) {
  .tabs__nav-item {
    width: 100px;
  }
}
.tabs__nav-link {
  text-decoration: none;
  color: #fff;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
  white-space: normal;
}
.tabs__nav-link:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -20px;
  -webkit-transform: translateX(calc(-50% - 10px)) translateY(100%);
  transform: translateX(calc(-50% - 10px)) translateY(100%);
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #333;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
  opacity: 0;
}
.tabs__nav-link.is-current:after {
  opacity: 1;
  -webkit-transition-delay: 450ms;
  transition-delay: 450ms;
  -webkit-transform: translateX(calc(-50% - 10px)) translateY(0);
  transform: translateX(calc(-50% - 10px)) translateY(0);
}
.tabs__nav-link:hover,
.tabs__nav-link.is-current {
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
  color: #f58220;
}
.tabs__content {
  background-color: #333;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  z-index: 2;
  max-height: 0;
  height: 100%;
  -webkit-transition-property: height, max-height;
  transition-property: height, max-height;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
  transition-delay: 0ms;
}
.tabs__content-item {
  display: none;
}
html.no-js .tabs__content-item,
.tabs__content-item.is-open {
  display: block;
}
.tabs__content-inner {
  padding: 20px 10px;
}
@media only screen and (min-width: 768px) {
  .tabs__content-inner {
    padding: 40px 25px;
  }
}
@media only screen and (min-width: 1024px) {
  .tabs__content-inner {
    padding: 40px;
  }
}
.tabs__nav-image {
  width: 60px;
  margin: 0 auto 10px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}
.tabs__nav-image:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.tabs__nav-image > .image__img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .tabs__nav-image {
    width: 80px;
  }
}
.tabs__nav-link.is-current .tabs__nav-image {
  -webkit-box-shadow: inset 0 0 0 2px #f58220;
  box-shadow: inset 0 0 0 2px #f58220;
}
.tabs__close {
  color: #f58220;
  position: absolute;
  top: 19px;
  right: 10px;
  font-size: 15px;
  cursor: pointer;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
@media only screen and (min-width: 768px) {
  .tabs__close {
    top: 20px;
    right: 25px;
  }
}
@media only screen and (min-width: 1024px) {
  .tabs__close {
    right: 18px;
  }
}
.tabs__close:hover {
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
  color: #ffa427;
}
.tabs__nav-image .image__img {
  z-index: -1;
}

.search-form {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
}
body.is-search-open .search-form:after {
  content: "";
  position: absolute;
  bottom: 0;
  -webkit-transform: translateX(2px);
  transform: translateX(2px);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #333;
}
.search-form--large {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.search-form__trigger-icon {
  color: #fff;
  font-size: 15px;
  position: relative;
  vertical-align: middle;
}
.search-form__form {
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transition-duration: 600ms;
  transition-duration: 600ms;
  -webkit-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
  position: fixed;
  top: 40px;
  left: 0;
  right: 0;
  padding: 26px 20px 45px;
  background: #333;
  width: 100%;
  border-bottom: 1px solid #f58220;
  -webkit-transform: translateY(-150%);
  transform: translateY(-150%);
  pointer-events: none;
  z-index: -1;
}
@media only screen and (min-width: 768px) {
  .search-form__form {
    position: static;
    top: auto;
    left: auto;
    right: auto;
    padding: 0;
    background: transparent;
    border-bottom: none;
    -webkit-transform: none;
    transform: none;
    pointer-events: all;
    z-index: 2;
  }
}
body.is-search-open .search-form__form {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  pointer-events: all;
  -webkit-transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.search-form--large .search-form__form {
  position: static;
  top: auto;
  left: auto;
  right: auto;
  padding: 0;
  background: transparent;
  border-bottom: none;
  -webkit-transform: none;
  transform: none;
  pointer-events: all;
  z-index: 2;
}
.search-form__title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .search-form__title {
    display: none;
  }
}
.search-form__close-btn {
  position: absolute;
  color: #f58220;
  font-size: 16px;
  top: 10px;
  right: 4px;
  background: none;
  border: none;
}
@media only screen and (min-width: 768px) {
  .search-form__close-btn {
    display: none;
  }
}
.search-form__trigger-btn {
  color: #f58220;
  background: none;
  border: none;
  padding: 4px 0;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
  background-color: #202020;
}
.header + * {
  margin-top: 90px;
}
@media only screen and (min-width: 768px) {
  .header + * {
    margin-top: 100px;
  }
}
.header__logo {
  width: 85px;
  height: auto;
  margin-right: auto;
  position: relative;
  z-index: 3;
}
@media only screen and (min-width: 768px) {
  .header__logo {
    width: 110px;
  }
}
@media only screen and (min-width: 1025px) {
  .header__logo {
    margin-right: 0;
  }
}
.header__top-bar {
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 4;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
body.is-scrolled .header__top-bar {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.header__main {
  background: #000;
  position: relative;
  -webkit-transition-property: margin-top;
  transition-property: margin-top;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
body.is-scrolled .header__main {
  margin-top: -40px;
}
.header__main:before {
  content: "";
  height: 50px;
  background-color: #000;
  width: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
}
@media only screen and (min-width: 768px) {
  .header__main:before {
    height: 60px;
  }
}
@media only screen and (min-width: 1025px) {
  .header__main:before {
    display: none;
  }
}
.header__inner {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
}
@media only screen and (min-width: 768px) {
  .header__inner {
    height: 60px;
  }
}
@media only screen and (min-width: 1025px) {
  .header__inner {
    padding: 0;
  }
}
.header__navigation {
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-transform: translateY(-98%);
  transform: translateY(-98%);
  background-color: #333;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
  border-bottom: 2px solid #f58220;
  max-height: calc(100vh - 90px - 64px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
@media only screen and (min-width: 1025px) {
  .header__navigation {
    width: auto;
    height: 100%;
    position: relative;
    -webkit-transform: none;
    transform: none;
    top: auto;
    left: auto;
    -webkit-transition: none;
    transition: none;
    background-color: transparent;
    margin-left: 20px;
    margin-right: auto;
    border: none;
    z-index: auto;
    max-height: none;
    overflow: visible;
  }
}
body.is-menu-open .header__navigation {
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
}
@media only screen and (min-width: 768px) {
  body.is-menu-open .header__navigation {
    -webkit-transform: translateY(60px);
    transform: translateY(60px);
  }
}
.header__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.header__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px 0;
  background-color: transparent;
  margin: 0;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  border: none;
  letter-spacing: 0;
  text-transform: none;
  font-weight: 400;
  line-height: 1;
  height: 40px;
  min-width: 40px;
  position: relative;
  z-index: 3;
}
.header__button:last-child {
  padding-right: 0;
}
.header__button:hover {
  -webkit-transition: none;
  transition: none;
  background: none;
}
@media only screen and (min-width: 1024px) {
  .header__button {
    height: 48px;
    padding: 5px 0 5px 10px;
  }
}
@media only screen and (min-width: 1240px) {
  .header__button {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 5px 10px;
    height: 40px;
  }
}
.header__timer {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  padding: 0 4px;
  margin-bottom: 2px;
  line-height: 10px;
  font-size: 8px;
  height: 10px;
  min-height: 10px;
}
@media only screen and (min-width: 1240px) {
  .header__timer {
    margin-left: 12px;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    padding: 3px 4px;
    margin-bottom: 0;
    line-height: 12px;
    font-size: 11px;
    min-height: 18px;
  }
}
.header__timer .timer__icon {
  font-size: 6px;
  margin-right: 2px;
}
@media only screen and (min-width: 1240px) {
  .header__timer .timer__icon {
    font-size: 10px;
    margin-right: 3px;
  }
}
.header__toggle {
  font-weight: 600;
  display: none;
}
@media only screen and (max-width: 1024px) {
  .header__toggle {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}
.header__button-icon {
  color: #f58220;
  font-size: 21px;
  margin-bottom: 4px;
  min-height: 21px;
}
@media only screen and (min-width: 1024px) {
  .header__button-icon {
    font-size: 23px;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width: 1240px) {
  .header__button-icon {
    margin-bottom: 0;
    margin-right: 6px;
  }
}
.header__button-icon--small {
  font-size: 12px;
  margin-bottom: 1px;
  min-height: 12px;
}
@media only screen and (min-width: 1240px) {
  .header__button-icon--small {
    margin-bottom: 4px;
    font-size: 23px;
    min-height: 21px;
  }
}
.header__button-label {
  color: #fff;
  font-size: 7px;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
  white-space: nowrap;
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}
@media only screen and (min-width: 1024px) {
  .header__button-label {
    font-size: 11px;
  }
}
@media only screen and (min-width: 1240px) {
  .header__button-label {
    font-size: 13px;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
}
@media only screen and (min-width: 1025px) {
  .button:hover .header__button-label {
    -webkit-transition-duration: 0ms;
    transition-duration: 0ms;
    color: #f58220;
  }
}
.header__hamburger {
  margin-bottom: 5px;
}
.header__top-bar-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100%;
  background-color: #202020;
}
.header__meta-link {
  margin-left: 15px;
}
@media only screen and (min-width: 1025px) {
  .header__meta-link {
    margin-left: 10px;
  }
}
@media only screen and (min-width: 1170px) {
  .header__meta-link {
    margin-left: 15px;
  }
}
.header__meta-link .button__wrapper-inner {
  text-decoration: none;
  font-size: 10px;
  letter-spacing: 0;
  font-weight: 400;
}
@media only screen and (min-width: 768px) {
  .header__meta-link .button__wrapper-inner {
    font-size: 11px;
  }
}
@media only screen and (min-width: 1025px) {
  .header__meta-link:hover .button__wrapper-inner {
    color: #f58220;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    -webkit-transition-delay: 0ms;
    transition-delay: 0ms;
  }
}
.header__meta-link .button__icon {
  margin-left: 0;
}
.header__meta-link--reg .button__icon {
  color: #000;
}
.header__meta {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header__top-nav {
  margin-left: 20px;
}
@media only screen and (max-width: 1024px) {
  .header__top-nav {
    display: none;
  }
}

.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slider {
  position: relative;
}
.slider:not(.slider--packages):not(.slider--giftcards):not(.slider--pricing) {
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 900ms;
  transition-duration: 900ms;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition-delay: 150ms;
  transition-delay: 150ms;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  will-change: transform;
}
.slider:not(.slider--packages):not(.slider--giftcards):not(.slider--pricing):before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.slider:not(.slider--packages):not(.slider--giftcards):not(.slider--pricing)
  > .slider__items {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
}
@media screen and (min-width: 560px) and (orientation: landscape) {
  .slider:not(.slider--packages):not(.slider--giftcards):not(.slider--pricing) {
    position: relative;
  }
  .slider:not(.slider--packages):not(.slider--giftcards):not(.slider--pricing):before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 33.33333%;
  }
  .slider:not(.slider--packages):not(.slider--giftcards):not(.slider--pricing)
    > .slider__items {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .slider:not(.slider--packages):not(.slider--giftcards):not(.slider--pricing) {
    position: relative;
  }
  .slider:not(.slider--packages):not(.slider--giftcards):not(.slider--pricing):before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 33.33333%;
  }
  .slider:not(.slider--packages):not(.slider--giftcards):not(.slider--pricing)
    > .slider__items {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .slider:not(.slider--packages):not(.slider--giftcards):not(.slider--pricing) {
    position: relative;
  }
  .slider:not(.slider--packages):not(.slider--giftcards):not(.slider--pricing):before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 33.33333%;
  }
  .slider:not(.slider--packages):not(.slider--giftcards):not(.slider--pricing)
    > .slider__items {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
@media only screen and (min-width: 1170px) {
  .slider:not(.slider--packages):not(.slider--giftcards):not(.slider--pricing) {
    position: relative;
  }
  .slider:not(.slider--packages):not(.slider--giftcards):not(.slider--pricing):before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 33.33333%;
  }
  .slider:not(.slider--packages):not(.slider--giftcards):not(.slider--pricing)
    > .slider__items {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
.slider--pricing,
.slider--giftcards,
.slider--packages {
  padding: 0 20px;
}
@media only screen and (min-width: 768px) {
  .slider--pricing,
  .slider--giftcards,
  .slider--packages {
    padding: 0;
  }
}
@media only screen and (min-width: 768px) {
  .slider--pricing {
    padding: 0 2px;
    background: #000;
    border-radius: 3px;
    padding-bottom: 14px;
  }
}
.slider__items {
  position: relative;
}
.slider__items:after {
  content: "";
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(32, 32, 32, 0)),
    color-stop(50%, rgba(32, 32, 32, 0)),
    to(#202020)
  );
  background: linear-gradient(
    to bottom,
    rgba(32, 32, 32, 0) 0%,
    rgba(32, 32, 32, 0) 50%,
    #202020 100%
  );
}
.slider__item {
  position: relative;
  display: inline-block;
  -webkit-transition-delay: 0ms !important;
  transition-delay: 0ms !important;
}
.slider__item.is-next,
.slider__item.is-prev {
  -webkit-transition-delay: 300ms !important;
  transition-delay: 300ms !important;
  opacity: 1 !important;
}
.slider__content-item {
  display: inline-block !important;
  -webkit-transition-delay: 0ms !important;
  transition-delay: 0ms !important;
  white-space: normal;
  visibility: hidden;
}
.slick-initialized .slider__content-item {
  visibility: visible;
}
.slider--giftcards .slider__content-item {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  height: auto;
}
@media only screen and (min-width: 768px) {
  .slider--giftcards .slider__content-item {
    padding: 0 6px;
  }
}
.slider--pricing .slider__content-item,
.slider--packages .slider__content-item {
  background-color: #000;
  padding: 3px 0;
}
.slider__content-item:first-child {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.slider--giftcards .slider__content-item:first-child {
  padding-left: 0;
}
.slider--giftcards .slider__content-item:last-child {
  padding-right: 0;
}
.slider__content-item.slick-current {
  -webkit-transition-delay: 700ms !important;
  transition-delay: 700ms !important;
}
.slider__content {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 2;
  color: #fff;
  text-align: center;
  padding-bottom: 5px;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
.slider--pricing .slider__content,
.slider--giftcards .slider__content,
.slider--packages .slider__content {
  position: relative;
  -webkit-transition: none;
  transition: none;
  padding: 0;
  bottom: auto;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}
.slider__content-sub {
  font-size: 14px;
}
@media only screen and (min-width: 768px) {
  .slider__content-sub {
    font-size: 16px;
  }
}
.slider__content-title {
  margin-bottom: 5px;
}
.slider--pricing .slider__content-title,
.slider--packages .slider__content-title {
  margin-bottom: 12px;
  max-width: 60%;
}
@media only screen and (min-width: 768px) {
  .slider--pricing .slider__content-title,
  .slider--packages .slider__content-title {
    max-width: 100%;
  }
}
@media only screen and (min-width: 1024px) {
  .slider--pricing .slider__content-title,
  .slider--packages .slider__content-title {
    max-width: 65%;
  }
}
.slider__content-title a {
  color: #fff;
}
.slider__content-title a:hover {
  color: #fff;
}
.slider__tag {
  margin-bottom: 18px;
}
.slider__buttons {
  margin-top: 20px;
}
@media only screen and (min-width: 1170px) {
  .slider__buttons {
    margin-top: 30px;
  }
}
.slider__arrow {
  position: absolute;
  top: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: #fff;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
@media only screen and (min-width: 1170px) {
  .slider__arrow {
    width: 60px;
    height: 60px;
  }
}
.slider--pricing .slider__arrow,
.slider--giftcards .slider__arrow,
.slider--packages .slider__arrow {
  background-color: #f58220;
}
.slider__arrow.slick-disabled {
  background-color: #333;
  opacity: 1;
}
.slider__arrow:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.slider--pricing .slider__arrow:hover,
.slider--giftcards .slider__arrow:hover,
.slider--packages .slider__arrow:hover {
  background-color: #fff;
}
.slider__arrow:hover.slick-disabled {
  background-color: #333;
}
.slider__arrow--right {
  right: 10px;
}
@media only screen and (min-width: 768px) {
  .slider__arrow--right {
    right: 24px;
  }
}
@media only screen and (min-width: 1024px) {
  .slider__arrow--right {
    right: 40px;
  }
}
.slider--pricing .slider__arrow--right,
.slider--giftcards .slider__arrow--right,
.slider--packages .slider__arrow--right {
  right: -20px;
}
@media only screen and (min-width: 1170px) {
  .slider--pricing .slider__arrow--right,
  .slider--giftcards .slider__arrow--right,
  .slider--packages .slider__arrow--right {
    right: -30px;
  }
}
.slider__arrow--left {
  left: 10px;
}
@media only screen and (min-width: 768px) {
  .slider__arrow--left {
    left: 24px;
  }
}
@media only screen and (min-width: 1024px) {
  .slider__arrow--left {
    left: 40px;
  }
}
.slider--pricing .slider__arrow--left,
.slider--giftcards .slider__arrow--left,
.slider--packages .slider__arrow--left {
  left: -20px;
}
@media only screen and (min-width: 1170px) {
  .slider--pricing .slider__arrow--left,
  .slider--giftcards .slider__arrow--left,
  .slider--packages .slider__arrow--left {
    left: -30px;
  }
}
.button__icon {
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
.slider__arrow .button__icon {
  font-size: 14px;
  margin: 0;
}
.slider--pricing .slider__arrow .button__icon,
.slider--packages .slider__arrow .button__icon {
  color: #fff;
}
@media only screen and (min-width: 1170px) {
  .slider__arrow .button__icon {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .button__icon .slider__content-button {
    font-size: 20px;
  }
}
.slider__arrow--right .button__icon {
  margin: 0 0 0 2px;
}
.slider__arrow--left .button__icon {
  margin: 0 2px 0 0;
}
.slider__arrow.slick-disabled .button__icon {
  color: rgba(255, 255, 255, 0.2);
}
.slider__arrow:hover .button__icon {
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
  color: #f58220;
}
.slider__arrow.slick-disabled:hover .button__icon {
  color: rgba(255, 255, 255, 0.2);
}
.slider__arrow-icon--right {
  margin-right: 10px;
}
.slider__arrow-icon--left {
  margin-left: 10px;
}
.slider__image {
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 900ms;
  transition-duration: 900ms;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition-delay: 150ms;
  transition-delay: 150ms;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  will-change: transform;
}
.slider__image:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.slider__image > .image__picture {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
}
@media screen and (min-width: 560px) and (orientation: landscape) {
  .slider__image {
    position: relative;
  }
  .slider__image:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 33.33333%;
  }
  .slider__image > .image__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .slider__image {
    position: relative;
  }
  .slider__image:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 33.33333%;
  }
  .slider__image > .image__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
@media screen and (min-width: 1024px) and (orientation: landscape) {
  .slider__image {
    position: relative;
  }
  .slider__image:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 33.33333%;
  }
  .slider__image > .image__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
@media only screen and (min-width: 1170px) {
  .slider__image {
    position: relative;
  }
  .slider__image:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 33.33333%;
  }
  .slider__image > .image__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}
.slider__item.is-prev .slider__image {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.slider__item.is-next .slider__image {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.slider__item.slick-current .slider__image {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.slick-track {
  white-space: nowrap;
  overflow: hidden;
}
.slider--giftcards .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.slider__content-button {
  padding: 0 8px;
}
@media only screen and (min-width: 768px) {
  .slider__content-button {
    padding: 0 12px;
  }
}
@media only screen and (min-width: 1024px) {
  .slider__content-button {
    font-size: 15px;
    padding: 0 15px;
  }
}
.slider__image .image__img {
  width: 100%;
  height: 100%;
}
.slider__package-image {
  max-width: 80px;
  max-height: 80px;
  margin: auto;
}
.slider__content-item--large .slider__package-image {
  max-width: 100px;
  max-height: 100px;
}
.slider__package-row {
  min-height: 90px;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  .slider__package-row {
    min-height: 100px;
  }
}
.slider__package-row.slider__package-row--has-image {
  min-height: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  .slider__package-row.slider__package-row--has-image {
    min-height: 215px;
  }
}
.slider__package-row:first-child {
  padding-top: 20px;
}
.slider--pricing .slider__package-row:first-child {
  min-height: 106px;
}
.slider__package-row:nth-child(odd) {
  background-color: #111;
  border-radius: 3px;
}
.slider__package-row--last {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media only screen and (min-width: 1024px) {
  .slider__package-row--last {
    padding: 10px 20px 20px;
  }
}
.slider--pricing .slider__sticky-item .slider__package-row,
.slider--pricing .slider__content-item:first-child .slider__package-row {
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  text-align: left;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
@media only screen and (min-width: 600px) {
  .slider--pricing .slider__sticky-item .slider__package-row,
  .slider--pricing .slider__content-item:first-child .slider__package-row {
    font-size: 15px;
    line-height: 22px;
  }
}
.slider--pricing .slider__sticky-item .slider__package-row,
.slider--packages .slider__sticky-item .slider__package-row,
.slider--pricing .slider__content-item:first-child .slider__package-row,
.slider--packages .slider__content-item:first-child .slider__package-row {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.slider--pricing .slider__package-row {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  padding: 20px;
}
.slider--pricing .slider__package-row,
.slider--packages .slider__package-row {
  -webkit-box-shadow: inset 2px 0 0 #202020;
  box-shadow: inset 2px 0 0 #202020;
}
.slider__package-price {
  color: #f58220;
}
.slider__package-pricelabel {
  font-weight: 600;
  color: #fff;
  margin-bottom: 8px;
}
.slider--pricing .slider__package-pricelabel {
  margin-bottom: 4px;
}
.slider__package-priceper {
  color: #888;
  font-weight: 400;
  margin-top: 2px;
}
.slider__package-list {
  font-size: 15px;
  text-align: left;
  line-height: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (min-width: 1024px) {
  .slider__package-list {
    line-height: 26px;
  }
}
.slider__package-list-item {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.slider__package-orderbutton {
  margin-top: 40px;
}
.slider__package-list-icon {
  color: #f58220;
  font-size: 14px;
  float: left;
  margin-right: 8px;
  margin-top: 5px;
}
.slider__package-list-content {
  display: inline-block;
  white-space: normal;
}
.slider__footer-title {
  margin-bottom: 10px;
}
.slider__footer {
  text-align: center;
  background-color: #000;
  position: relative;
}
.slider--packages .is-slideable + .slider__footer:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 50px;
  left: -1px;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(32, 32, 32, 0)),
    to(#202020)
  );
  background: linear-gradient(to left, rgba(32, 32, 32, 0) 0%, #202020 100%);
}
.slider--packages .is-slideable + .slider__footer:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 50px;
  left: auto;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(32, 32, 32, 0)),
    to(#202020)
  );
  background: linear-gradient(to right, rgba(32, 32, 32, 0) 0%, #202020 100%);
  right: -1px;
}
.slider--packages .is-slideable.is-first + .slider__footer:before {
  content: none;
}
.slider--pricing .is-slideable.is-last + .slider__footer:after,
.slider--packages .is-slideable.is-last + .slider__footer:after {
  content: none;
}
.slider__footer-item {
  padding: 20px;
}
.slider__footer-item:nth-child(odd) {
  background-color: #111;
}
.slider--pricing .slider__footer-item {
  border-radius: 3px;
}
.slider__note {
  padding: 22px 20px;
}
.slider--packages .slick-list,
.slider--pricing .slick-list {
  border-radius: 3px;
}
.slider--pricing .is-slideable .slick-list:before,
.slider--giftcards .is-slideable .slick-list:before,
.slider--packages .is-slideable .slick-list:before,
.slider--pricing .is-slideable .slick-list:after,
.slider--giftcards .is-slideable .slick-list:after,
.slider--packages .is-slideable .slick-list:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: -1px;
  bottom: 0;
  height: 100%;
  width: 50px;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(32, 32, 32, 0)),
    to(#202020)
  );
  background: linear-gradient(to left, rgba(32, 32, 32, 0) 0%, #202020 100%);
  pointer-events: none;
  opacity: 1;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
.slider--pricing .is-first .slick-list:before,
.slider--giftcards .is-first .slick-list:before,
.slider--packages .is-first .slick-list:before {
  opacity: 0;
}
@media only screen and (max-width: 767px) {
  .slider--pricing .is-slideable .slick-list:before {
    opacity: 0;
  }
}
.slider--pricing .is-last .slick-list:after,
.slider--giftcards .is-last .slick-list:after,
.slider--packages .is-last .slick-list:after {
  opacity: 0;
}
.slider--pricing .is-slideable .slick-list:after,
.slider--giftcards .is-slideable .slick-list:after,
.slider--packages .is-slideable .slick-list:after {
  left: auto;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(32, 32, 32, 0)),
    to(#202020)
  );
  background: linear-gradient(to right, rgba(32, 32, 32, 0) 0%, #202020 100%);
  right: -1px;
}
.slider__agerange {
  font-weight: 400;
  font-size: 13px;
}
.slider__sticky-item {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(50% + 1px);
}

.dropdown {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.dropdown--single {
  padding: 3px;
  background-color: #111;
  border-radius: 3px;
}
.dropdown__strip {
  position: absolute;
  left: 5px;
  top: 5px;
  bottom: 5px;
  width: 7px;
  background-image: radial-gradient(
    circle closest-side,
    #202020 2.3px,
    transparent 2.8px,
    transparent 5.1px
  );
  background-repeat: repeat-y;
  background-position: center top;
  background-size: 10px 10px;
  z-index: 2;
}
.dropdown--choices .dropdown__strip {
  background-size: 10px 9.5px;
}
@media (-webkit-min-device-pixel-ratio: 2) and (max-width: 768px) {
  .dropdown--choices .dropdown__strip {
    background-size: 10px 8.8px;
  }
}
@media only screen and (min-width: 1024px) {
  .filter__item .dropdown__strip {
    display: none;
  }
}
@media only screen and (min-width: 1024px) {
  .filter__item:first-child .dropdown__strip {
    display: inline-block;
  }
}
.dropdown__strip--end {
  left: unset;
  right: 5px;
}
@media only screen and (min-width: 1024px) {
  .filter__item:first-child .dropdown__strip--end {
    display: none;
  }
}
@media only screen and (min-width: 1024px) {
  .filter__item:last-child .dropdown__strip--end {
    display: inline-block;
  }
}
.dropdown__trigger {
  display: block;
  position: relative;
  z-index: 1;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  background-image: none;
  border: none;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  max-width: 100%;
  -ms-flex-item-align: center;
  align-self: center;
  padding: 15px 26px 15px 23px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: left;
  background-color: #333;
  margin: 0;
}
@media only screen and (min-width: 1024px) {
  .dropdown__trigger {
    font-size: 18px;
  }
}
.dropdown--choices .dropdown__trigger {
  padding: 14px;
  font-size: 15px;
}
.ticket-picker__dropdown:hover .dropdown__trigger,
.ticket-picker__dropdown.is-active .dropdown__trigger,
.dropdown--choices:hover .dropdown__trigger,
.dropdown--choices.is-active .dropdown__trigger {
  background-color: #2d2d2d;
}
.filter__dropdown .dropdown__trigger {
  padding: 13px 24px 13px 24px;
}
@media only screen and (min-width: 1024px) {
  .filter__dropdown .dropdown__trigger {
    padding-left: 16px;
  }
}
@media only screen and (min-width: 1024px) {
  .filter__item:first-child .dropdown__trigger {
    padding-left: 25px;
  }
}
.dropdown__trigger:hover {
  background-image: none;
  color: #fff;
  border: none;
}
.dropdown--single .dropdown__trigger {
  border-bottom: 3px solid #f58220;
  border-radius: 0 0 3px 3px;
}
.dropdown--single .dropdown__trigger:hover {
  background-color: #2d2d2d;
}
.dropdown__label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
}
.dropdown__label-text {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.dropdown__label-text:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 35px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(51, 51, 51, 0)),
    color-stop(30%, #333),
    to(#333)
  );
  background: linear-gradient(
    to right,
    rgba(51, 51, 51, 0) 0%,
    #333 30%,
    #333 100%
  );
}
.ticket-picker__dropdown:hover .dropdown__label-text:after,
.ticket-picker__dropdown.is-active .dropdown__label-text:after,
.dropdown--choices:hover .dropdown__label-text:after,
.dropdown--choices.is-active .dropdown__label-text:after,
.dropdown--single:hover .dropdown__label-text:after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(45, 45, 45, 0)),
    color-stop(30%, #2d2d2d),
    to(#2d2d2d)
  );
  background: linear-gradient(
    to right,
    rgba(45, 45, 45, 0) 0%,
    #2d2d2d 30%,
    #2d2d2d 100%
  );
}
.dropdown--single .dropdown__label-text {
  text-align: center;
  width: calc(100% - 50px);
}
.dropdown--single .dropdown__label-text:after {
  content: none;
}
.dropdown__content {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}
.dropdown__box {
  position: absolute !important;
  visibility: visible;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  z-index: 3;
  background-color: #444;
  color: #fff;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0);
  border-radius: 0 0 3px 3px;
  max-height: 0;
  overflow: hidden;
  pointer-events: none;
  -webkit-transition-property: max-height, height, -webkit-box-shadow;
  transition-property: max-height, height, -webkit-box-shadow;
  transition-property: max-height, height, box-shadow;
  transition-property: max-height, height, box-shadow, -webkit-box-shadow;
  -webkit-transition-duration: 675ms;
  transition-duration: 675ms;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
@media only screen and (min-width: 1024px) {
  .dropdown--choices .dropdown__box {
    border-bottom: 4px solid #f58220;
  }
}
.dropdown__box:before {
  content: "";
  position: absolute;
  left: 50%;
  top: -7px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #444;
  pointer-events: none;
}
.dropdown.is-active .dropdown__box {
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
  pointer-events: auto;
}
.dropdown--single .dropdown__box {
  top: -6px;
}
.dropdown__box-inner {
  padding: 15px 0;
  border-bottom: 4px solid #f58220;
}
@media only screen and (min-width: 1024px) {
  .dropdown--choices .dropdown__box-inner {
    border: none;
  }
}
.dropdown--single .dropdown__box-inner {
  text-align: center;
}
.dropdown__label-icon {
  color: #888;
  font-size: 20px;
  margin-right: 12px;
}
.dropdown__icon {
  margin-right: 21px;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 13px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  color: #f58220;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
.dropdown--choices .dropdown__icon {
  margin-right: 10px;
}
.filter__dropdown .dropdown__icon {
  margin-right: 20px;
}
@media only screen and (min-width: 1024px) {
  .filter__dropdown .dropdown__icon {
    margin-right: 10px;
  }
}
@media only screen and (min-width: 1024px) {
  .filter__item:last-child .dropdown__icon {
    margin-right: 20px;
  }
}
.dropdown.is-active .dropdown__icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.dropdown--choices .choice-group__label {
  display: none;
}
.dropdown__sublabel {
  font-size: 11px;
  color: #888;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 3px;
}
.dropdown__sublabel-icon {
  font-size: 15px;
  margin-right: 4px;
  top: -2px;
  position: relative;
}
.dropdown__box-actions {
  margin-top: 20px;
  padding: 0 20px 14px;
}

.number {
  width: 100%;
  max-width: 140px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.number--inline {
  display: inline-block;
}
.number__input {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 80px;
  flex: 0 1 80px;
  max-width: 60px;
  width: 100%;
  height: 46px;
  display: inline-block;
  padding: 0 8px;
  margin: 0 10px;
  border: 1px solid #444;
  color: #fff;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  background-color: #333;
  -moz-appearance: textfield;
}
.number__input::-webkit-inner-spin-button,
.number__input::-webkit-outer-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}
.number__increment,
.number__decrement {
  position: relative;
  background-color: #f58220;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
  max-width: 30px;
  height: 30px;
  width: 100%;
  border-radius: 50%;
  color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 0;
  cursor: pointer;
  -webkit-transition-property: background-color;
  transition-property: background-color;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
.number__increment:hover,
.number__decrement:hover {
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
  background-color: #ffa427;
}
.number__decrement:after {
  content: "";
  width: 10px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #fff;
}
.number.is-min .number__decrement {
  opacity: 0.5;
}
.number.is-min .number__decrement:hover {
  background-color: #f58220;
}
.number.is-disabled .number__decrement {
  pointer-events: none;
}
.number__increment:after {
  content: "";
  width: 10px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #fff;
}
.number__increment:before {
  content: "";
  width: 2px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #fff;
}
.number.is-max .number__increment {
  opacity: 0.5;
}
.number.is-max .number__increment:hover {
  background-color: #f58220;
}
.number.is-disabled .number__increment {
  pointer-events: none;
}
.number__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.number__label {
  display: block;
}

.progress {
  padding: 16px 0 20px;
  border-bottom: 2px solid #333;
}
.progress:last-child {
  border-bottom: none;
}
@media only screen and (min-width: 768px) {
  .progress {
    padding: 36px 0 20px;
  }
}
.progress__bar {
  position: relative;
  background: #444;
  border-radius: 3px;
  margin: 36px 0 0 0;
}
.progress__inner {
  position: relative;
  width: calc(100% - 50px);
  height: 6px;
  margin: 12px 0 40px;
}
@media only screen and (min-width: 768px) {
  .progress__inner {
    width: calc(100% - 90px);
  }
}
.progress__title {
  color: #f58220;
  margin-bottom: 4px;
}
.progress--olearys .progress__title {
  color: #29b79d;
}
.progress--blender .progress__title {
  color: #fec708;
}
.progress--visits .progress__title {
  color: #c90940;
}
.progress--icecafe .progress__title {
  color: #3d93ff;
}
.progress__bar-rewards {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  top: -73px;
  left: -3px;
  z-index: 3;
}
@media only screen and (min-width: 768px) {
  .progress__bar-rewards {
    top: -75px;
    left: -9px;
  }
}
.progress__reward-wrapper {
  position: relative;
}
.progress__reward-wrapper:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-box-shadow: 0 5px 20px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 20px 20px rgba(0, 0, 0, 0.2);
  width: 0;
  height: 0;
  border-radius: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  z-index: -1;
}
.progress__reward-icon {
  width: 20px;
  height: auto;
  color: #444;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
@media only screen and (min-width: 768px) {
  .progress__reward-icon {
    width: 45px;
  }
}
.progress__milestone-text,
.progress__award-text {
  position: absolute;
  top: 14px;
  width: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  color: #000;
}
.progress__milestone-subtitle,
.progress__award-subtitle {
  font-size: 8px;
  font-weight: 600;
}
.progress__milestone-inner-icon + .progress__milestone-subtitle,
.progress__milestone-inner-icon + .progress__award-subtitle {
  font-size: 10px;
}
.progress__award-subtitle {
  color: #fff;
}
.progress__award-inner-icon + .progress__award-subtitle {
  font-size: 10px;
}
.progress__milestone-title,
.progress__award-title {
  font-size: 20px;
  font-weight: 600;
  color: #f58220;
  margin-top: 4px;
}
.progress__award-title {
  color: #fff;
}
.progress__award-inner-icon {
  font-size: 24px;
  color: #fff;
}
.progress__awards {
  padding: 16px 0;
}
.progress__awards-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.progress__awards-item + .progress__awards-item {
  margin-top: 10px;
}
@media only screen and (min-width: 768px) {
  .progress__awards-item + .progress__awards-item {
    margin-top: 16px;
  }
}
@media only screen and (min-width: 768px) {
  .progress__awards-item {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.progress__milestone-inner-icon {
  font-size: 24px;
  color: #f58220;
}
.progress--olearys .progress__milestone-inner-icon {
  color: #29b79d;
}
.progress--blender .progress__milestone-inner-icon {
  color: #fec708;
}
.progress--visits .progress__milestone-inner-icon {
  color: #c90940;
}
.progress--icecafe .progress__milestone-inner-icon {
  color: #3d93ff;
}
.progress__milestone {
  position: absolute;
  right: 0;
  top: -30px;
}
.progress__milestone-icon {
  width: 45px;
  height: 68px;
  color: #fff;
}
.progress__award {
  position: relative;
  display: inline-block;
  margin-right: 14px;
}
.progress__award-texts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.progress__award-texts a {
  color: #fff;
}
@media only screen and (min-width: 768px) {
  .progress__award-texts {
    margin-right: 12px;
  }
}
@media only screen and (min-width: 768px) {
  .progress__award-meta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.progress__award-meta > * + * {
  margin-top: 12px;
}
@media only screen and (min-width: 768px) {
  .progress__award-meta > * + * {
    margin-top: 0;
  }
}
.progress__award-icon {
  position: relative;
  height: 68px;
  width: 45px;
  color: #f58220;
}
.progress--olearys .progress__award-icon {
  color: #29b79d;
}
.progress--blender .progress__award-icon {
  color: #fec708;
}
.progress--visits .progress__award-icon {
  color: #c90940;
}
.progress--icecafe .progress__award-icon {
  color: #3d93ff;
}
.progress__complete {
  position: absolute;
  width: 100%;
  max-width: 0;
  top: 0;
  left: 0;
  height: 6px;
  border-radius: 3px;
  -webkit-transition-property: max-width;
  transition-property: max-width;
  -webkit-transition-duration: 900ms;
  transition-duration: 900ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
.progress__complete-value,
.progress__complete-min,
.progress__complete-max {
  position: absolute;
  top: 12px;
  font-size: 13px;
  white-space: nowrap;
}
@media only screen and (min-width: 768px) {
  .progress__complete-value,
  .progress__complete-min,
  .progress__complete-max {
    font-size: 15px;
  }
}
.progress__complete-value {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-weight: 600;
  top: -24px;
}
.progress__complete-max {
  left: auto;
  right: 0;
}
@media only screen and (min-width: 768px) {
  .progress__complete-max {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
}

.screen {
  background-color: #000;
}
.screen__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media only screen and (min-width: 1024px) {
  .screen__inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    height: 100vh;
  }
}
.screen__main {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
}
@media only screen and (min-width: 1024px) {
  .screen__main {
    padding: 20px;
    height: 100%;
  }
}
.screen__sidebar {
  display: inline-block;
  background-color: #202020;
  padding-top: 15px;
  overflow: auto;
}
@media only screen and (min-width: 1024px) {
  .screen__sidebar {
    -ms-flex-preferred-size: 320px;
    flex-basis: 320px;
    max-width: 320px;
    min-width: 320px;
    height: 100%;
  }
}
.screen__item {
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -webkit-transition-property: background-color;
  transition-property: background-color;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
.screen__item:hover {
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
  background-color: #444;
}
.screen__item:hover .screen__item-title {
  color: #f58220;
}
.screen__item.is-active {
  background-color: #444;
}
.screen__item-thumbnail {
  display: inline-block;
  -ms-flex-preferred-size: 130px;
  flex-basis: 130px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  max-width: 130px;
  width: 100%;
}
.screen__item-content {
  padding-left: 10px;
  color: #fff;
}
.screen__sidebar-title {
  margin-bottom: 15px;
  text-align: center;
}
.screen__item-time {
  color: #888;
  margin-top: 5px;
  display: block;
  font-weight: 400;
}
.screen__close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  padding: 10px;
}
@media only screen and (min-width: 1024px) {
  .screen__close {
    top: 19px;
    right: 18px;
  }
}
.screen__close .button__icon {
  color: #f58220;
  font-size: 15px;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
.screen__close:hover .button__icon {
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
  color: #fff;
}
.screen__main-media {
  width: 100%;
  position: relative;
}
.screen__main-media:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.25%;
}
.screen__main-media > .screen__iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
}
.screen__main-media iframe {
  height: 100%;
}

.tooltip {
  cursor: pointer;
  position: relative;
  width: 17px;
  height: 17px;
}
.tooltip__inner-container {
  position: absolute;
  pointer-events: none;
  -webkit-transition: -webkit-transform 200ms ease-in-out;
  transition: -webkit-transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
  width: 100vw;
  z-index: 1001;
}
html[data-whatinput="mouse"] .tooltip:hover .tooltip__inner-container {
  -webkit-transform: translateY(-22px);
  transform: translateY(-22px);
}
.tooltip.fade-out .tooltip__inner-container {
  -webkit-transform: translateY(-32px);
  transform: translateY(-32px);
}
.tooltip.is-open .tooltip__inner-container {
  -webkit-transform: translateY(-22px);
  transform: translateY(-22px);
}
.tooltip__content {
  background: #fff;
  color: #000;
  padding: 14px 15px;
  border-radius: 3px;
  font-weight: 600;
  position: relative;
  z-index: 1000;
  opacity: 0;
  visibility: visible !important;
  -webkit-transition: opacity 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
}
.tooltip:hover .tooltip__content {
  -webkit-transition: opacity 200ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: opacity 200ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
html[data-whatinput="mouse"] .tooltip:hover .tooltip__content {
  opacity: 1;
}
.tooltip.is-open .tooltip__content {
  -webkit-transition: opacity 200ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: opacity 200ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
  opacity: 1;
}
.tooltip__content[x-placement="top-start"],
.tooltip__content[x-placement="top-end"],
.tooltip__content[x-placement="top"] {
  margin-bottom: 12px;
}
.tooltip__content[x-placement="bottom-start"],
.tooltip__content[x-placement="bottom-end"],
.tooltip__content[x-placement="bottom"] {
  margin-top: 12px;
}
.tooltip__content[x-placement="right-start"],
.tooltip__content[x-placement="right-end"],
.tooltip__content[x-placement="right"] {
  margin-left: 12px;
}
.tooltip__content[x-placement="left-start"],
.tooltip__content[x-placement="left-end"],
.tooltip__content[x-placement="left"] {
  margin-right: 12px;
}
.tooltip__icon-container {
  width: 17px;
  height: 17px;
  vertical-align: top;
}
.tooltip__icon {
  font-size: 17px;
}
.tooltip__content[x-placement="bottom"] .tooltip__arrow,
.tooltip__content[x-placement="bottom-start"] .tooltip__arrow,
.tooltip__content[x-placement="bottom-end"] .tooltip__arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  top: -7px;
}
.tooltip__content[x-placement="right"] .tooltip__arrow,
.tooltip__content[x-placement="right-start"] .tooltip__arrow,
.tooltip__content[x-placement="right-end"] .tooltip__arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-right: 7px solid #fff;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  left: -7px;
}
.tooltip__content[x-placement="left"] .tooltip__arrow,
.tooltip__content[x-placement="left-start"] .tooltip__arrow,
.tooltip__content[x-placement="left-end"] .tooltip__arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 7px solid #fff;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  right: -7px;
}
.tooltip__content[x-placement="top"] .tooltip__arrow,
.tooltip__content[x-placement="top-start"] .tooltip__arrow,
.tooltip__content[x-placement="top-end"] .tooltip__arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 7px solid #fff;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  bottom: -7px;
}
.tooltip__inner {
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  text-transform: none;
}

.upload__input {
  display: none;
}
.upload__text {
  display: block;
  color: #888;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) {
  .upload__text {
    margin-bottom: 15px;
  }
}
.upload__text::after {
  content: "\A";
  white-space: pre;
}
.upload__link {
  pointer-events: none;
}
.upload__files {
  font-weight: 600;
  margin-left: 15px;
  vertical-align: middle;
  color: #fff;
}
.upload__label {
  display: inline-block;
  cursor: pointer;
}

.filter {
  position: relative;
  z-index: 4;
  padding-top: 25px;
}
@media only screen and (min-width: 1024px) {
  .filter {
    padding-top: 45px;
  }
}
@media only screen and (min-width: 1170px) {
  .filter {
    padding-top: 55px;
  }
}
.filter__bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  background: #333;
  border-radius: 3px;
  border-bottom: none;
  color: #fff;
  min-height: 70px;
}
.filter__bar-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-bottom: 4px solid #f58220;
  min-height: 100%;
  width: calc(100% + 2px);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
@media only screen and (min-width: 1024px) {
  .filter__bar-inner {
    margin-left: -1px;
    margin-right: -1px;
  }
}
.filter__strip {
  position: absolute;
  left: 5px;
  top: 4px;
  bottom: 5px;
  width: 7px;
  background-image: radial-gradient(
    circle closest-side,
    #202020 2.3px,
    transparent 2.8px,
    transparent 5.1px
  );
  background-repeat: repeat-y;
  background-position: center top;
  background-size: 10px 9.5px;
  z-index: 2;
}
@media only screen and (min-width: 1024px) {
  .filter__strip {
    height: 55px;
  }
}
@media only screen and (min-width: 1024px) {
  .filter__item:last-child .filter__strip:not(.filter__strip--end) {
    display: none;
  }
}
.filter__strip--end {
  left: unset;
  right: 6px;
}
.filter__tags {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  max-width: 50%;
}
@media only screen and (min-width: 1170px) {
  .filter__tags {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
  }
}
.filter__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0 5px;
  font-size: 13px;
  color: #888;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
.filter__action-label {
  display: none;
  margin-right: 20px;
}
@media only screen and (min-width: 1024px) {
  .filter__action-label {
    display: block;
  }
}
.filter__sub-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 12px;
}
.filter__action-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.filter__action-item {
  overflow: hidden;
}
.filter__action-item + .filter__action-item {
  margin-left: 12px;
}
.filter__tag-button {
  text-transform: none;
  letter-spacing: 0;
  margin-right: 24px;
}
.filter__tag-button:last-of-type {
  margin-right: 0;
}
.filter__tag-button.js-open-filters {
  position: relative;
}
.filter__tag-button.js-open-filters:before {
  content: "";
  position: absolute;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #444;
  -webkit-transform: translateX(-50%) translateY(100%);
  transform: translateX(-50%) translateY(100%);
  bottom: -10px;
  opacity: 0;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
  transition-delay: 0ms;
}
.is-open .filter__tag-button.js-open-filters {
  position: relative;
}
.is-open .filter__tag-button.js-open-filters:before {
  opacity: 1;
  -webkit-transform: translateX(-50%) translateY(0);
  transform: translateX(-50%) translateY(0);
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
}
.filter__form {
  -webkit-transition-property: max-height;
  transition-property: max-height;
  -webkit-transition-duration: 675ms;
  transition-duration: 675ms;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
  max-height: 0;
  position: absolute;
  top: calc(100% + 40px);
  left: 0;
  right: 0;
  background: #444;
  width: 100%;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  pointer-events: none;
  z-index: 3;
  overflow: hidden;
}
.filter.is-open .filter__form {
  pointer-events: all;
}
.filter__form-inner {
  padding: 24px 20px;
  border-bottom: 4px solid #f58220;
}
.filter__title-icon {
  color: #888;
  font-size: 13px;
  margin-right: 8px;
}
.filters__title {
  margin-bottom: 18px;
}
.filter__form-item {
  border-bottom: 2px solid rgba(255, 255, 255, 0.05);
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.filter__form-item:last-child {
  border-bottom: none;
}
@media only screen and (min-width: 768px) {
  .filter__form-item {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
    border-left: 2px solid rgba(255, 255, 255, 0.05);
    padding-left: 20px;
  }
  .filter__form-item:first-of-type {
    border-left: none;
  }
}
.filter__form-footer {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.filter__form-footer > * + * {
  margin: 0 20px;
}
.filter__footer-button {
  min-width: 0;
}
.filter__footer-button.button--ghost {
  font-size: 15px;
  line-height: 22px;
}
.filter__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -1px;
}
@media only screen and (min-width: 1024px) {
  .filter__items {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
.filter__item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  max-width: 100%;
  padding: 0 1px;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .filter__item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
  }
}
@media only screen and (min-width: 1024px) {
  .filter__item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
  }
}
.filter__item:after {
  content: "";
  display: block;
  width: calc(100% + 13px);
  height: 2px;
  right: -12px;
  left: -12px;
  bottom: 0;
  background: #202020;
  position: absolute;
  z-index: 2;
}
@media only screen and (min-width: 1024px) {
  .filter__item:after {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .filter__item:nth-child(2n + 1):before {
    content: "";
    display: block;
    width: 2px;
    right: -1px;
    top: -1px;
    bottom: 0;
    background: #202020;
    position: absolute;
  }
}
@media only screen and (min-width: 1024px) {
  .filter__item:nth-child(2n + 1):before {
    display: none;
  }
}
@media only screen and (min-width: 1024px) {
  .filter__item + .filter__item:before {
    content: "";
    display: block;
    width: 2px;
    left: -1px;
    top: -1px;
    bottom: 0;
    background: #202020;
    position: absolute;
  }
}
.filter__item--large {
  max-width: 100%;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 0;
}
@media only screen and (min-width: 1024px) {
  .filter__item--large {
    max-width: 60%;
    padding: 0 1px;
  }
}
.filter__item--large:before {
  display: none !important;
}
@media only screen and (min-width: 1024px) {
  .filter__item--large:before {
    display: block !important;
  }
}
.filter__dropdown {
  height: 100%;
}
.filter__choices {
  padding: 13px 24px 13px 24px;
}
@media only screen and (min-width: 1024px) {
  .filter__choices {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.filter .choice-group__label {
  font-size: 11px;
  color: #888;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 3px;
}
.filter .choice-group__label-icon {
  font-size: 15px;
  margin-right: 4px;
  top: -2px;
  position: relative;
}
